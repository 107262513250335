import { useState } from 'react';

import { CustomIconButton } from 'components/buttons';
import SecretsModal from 'components/modals/SecretsModal';

function EditSecretButton({ secret }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <CustomIconButton onClick={() => setOpen(true)} type="edit" />
      <SecretsModal open={open} setOpen={setOpen} secret={secret} />
    </>
  );
}

export default EditSecretButton;
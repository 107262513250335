import { useState } from 'react';

import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';

import InstanceModal from 'components/modals/config/InstanceModal';

function AddInstanceButton({ instance }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button color="secondary" variant="contained" onClick={() => setOpen(true)} startIcon={<AddIcon />}>Add Nagios Instance</Button>
      <InstanceModal instance={instance} open={open} setOpen={setOpen} />
    </>
  );
}

export default AddInstanceButton;
import { useState, useRef } from 'react';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { useQueryParams, StringParam } from 'use-query-params';
import { useDebouncedCallback } from 'use-debounce';

import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import { Page, LoadingCircle } from 'components/layout';
import { CustomIconButton, DownloadPluginButton } from 'components/buttons';
import { useAuth } from 'context/auth';
import { usePlugins, useDeletePluginMutation } from 'hooks/organizations';
import { formatBytes, formatLocalTime } from 'utils/common';
import { API_URL } from 'config';
import { Helmet } from 'react-helmet-async';

function Plugins() {

  const ref = useRef();
  const queryClient = useQueryClient();

  const { selectedOrgId } = useAuth();
  const { isLoading: pluginsLoading, data: plugins } = usePlugins(selectedOrgId);
  const deleteMutate = useDeletePluginMutation(selectedOrgId);

  const [query, setQuery] = useQueryParams({
    search: StringParam
  });

  const [uploadText, setUploadText] = useState("Upload Plugins");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");
  const [search, setSearch] = useState(query.search ?? '');

  const debounced = useDebouncedCallback(
    (search) => {
      setQuery({ search: search });
    },
    600
  );

  const resetButton = () => {
    setIsLoading(false);
    setUploadText("Upload Plugins");
    ref.current.value = "";
  };

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }

    setIsLoading(true);
    setUploadText("Uploading Plugins ...");
    setMessage("");

    axios.postForm(`${API_URL}organizations/${selectedOrgId}/plugins`, {
      'plugin[]': e.target.files
    }, {
      withCredentials: true,
    })
      .then(res => {
        resetButton();
        queryClient.invalidateQueries({ queryKey: ['orgs', selectedOrgId, 'plugins'] });
        setMessageType("success");
        if (res.data.files > 1) {
          setMessage(`Uploaded ${res.data.files} plugins`);
        } else {
          setMessage("Uploaded plugin");
        }
        setTimeout(() => {
          setMessage("");
        }, 4000);
      })
      .catch(err => {
        setMessageType("error");
        if (err.response.status === 413) {
          setMessage("File size was above maximum limit of 5 MiB");
        } else {
          setMessage("Unknown error occurred.");
        }
        resetButton();
      });
  };

  const handleDeleteClick = (pluginId) => {
    deleteMutate.mutate(pluginId);
  };

  const clearFilters = () => {
    setQuery({
      search: undefined
    });
    setSearch('');
  };

  const getFilteredPlugins = () => {
    if (search && search !== '' && plugins) {
      return plugins.filter(p => {
        if (p.name.toLowerCase().indexOf(search.trim().toLowerCase()) > -1) {
          return p;
        }
        return null;
      });
    }
    return plugins;
  };

  const filteredPlugins = getFilteredPlugins();

  return (
    <Page>
      <Helmet>
        <title>Manage Plugins &middot; ReChecked Manager</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Manage Plugins</Typography>
          <Typography>Plugins that are uploaded can be used in check configurations and will be synced to agents that use those checks.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} alignItems="center">
              <LoadingButton loading={isLoading} loadingPosition="start" variant="contained" color="secondary" component="label" startIcon={<FileUploadIcon />}>
                {uploadText}
                <input type="file" ref={ref} onChange={handleFileChange} hidden multiple accept=".ps1,.sh,.py,.php,.pl,.bat,.vbs" />
              </LoadingButton>
              {message && (<Alert sx={{ py: 0 }} onClose={() => setMessage("")} severity={messageType}>{message}</Alert>)}
            </Stack>
            <Stack direction="row" spacing={2}>
              {search && (<Button onClick={clearFilters}>Clear</Button>)}
              <TextField size="small" label="Search" value={search} onChange={(e) => { setSearch(e.target.value); debounced(e.target.value); }} />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {pluginsLoading ? (
            <LoadingCircle />
          ) : (
            <TableContainer variant="outlined" component={Card}>
              <Table aria-label="plugins table">
                <TableHead>
                  <TableRow>
                    <TableCell>Plugin Name</TableCell>
                    <TableCell>File Size</TableCell>
                    <TableCell>Uploaded</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell colSpan={2}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {plugins && filteredPlugins.map((plugin) => (
                    <TableRow
                      key={plugin.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="plugin">
                        {plugin.name}
                      </TableCell>
                      <TableCell>{formatBytes(plugin.filesize, 'KiB')}</TableCell>
                      <TableCell>{formatLocalTime(plugin.uploadTime)}</TableCell>
                      <TableCell>{plugin.uploadUserName}</TableCell>
                      <TableCell padding="checkbox">
                        <DownloadPluginButton orgId={plugin.orgId} pluginId={plugin.id} name={plugin.name} />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <CustomIconButton onClick={() => handleDeleteClick(plugin.id)} type="delete" />
                      </TableCell>
                    </TableRow>
                  ))}
                  {plugins && plugins.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5}>No plugins exist for this account yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default Plugins;
import { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

function LoadingCircle({ delay }) {

  const [show, setShow] = useState(delay ? false : true);

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setShow(true);
      }, parseInt(delay));
    }
  }, [delay]);

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={4}>
      <Grid item>
        {show && <CircularProgress color="secondary" />}
      </Grid>
    </Grid>
  );
}

export default LoadingCircle;
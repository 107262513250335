import HowToRegIcon from '@mui/icons-material/HowToReg';

import TooltipIconButton from "../TooltipIconButton";
import { useSetDefaultOrgId } from 'hooks/account';
import { useAuth } from 'context/auth';

function SetDefaultOrgIconButton({ orgId }) {

  const { options, checkAuth } = useAuth();
  const setDefault = useSetDefaultOrgId();

  const handleSetDefault = (e) => {
    setDefault.mutate(orgId, {
      onSettled: () => {
        checkAuth();
      }
    });
  };

  return (
    <TooltipIconButton
      disabled={options.defaultOrgId === orgId}
      onClick={handleSetDefault}
      tooltip={options.defaultOrgId === orgId ? "This organization is set as the default on login" : "Set as default organization to use on login"}
      icon={<HowToRegIcon />}
      />
  );
}

export default SetDefaultOrgIconButton;
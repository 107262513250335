import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQueryParams, StringParam } from 'use-query-params';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Page from 'components/layout/Page';
import { PaymentForm } from 'components/forms/organizations';
import { API } from 'utils/network';
import { LoadingCircle } from 'components/layout';

function Payment() {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [query] = useQueryParams({
    sessionId: StringParam
  });

  // Redirect to finished page if successful
  useEffect(() => {
    if (query.sessionId) {
      API.get(`stripe/session/status`, { sessionId: query.sessionId })
        .then(resp => {
          setIsLoading(false);
          if (resp.status === 200 && resp.data?.status === "complete") {
            navigate("/account/organizations/complete");
          }
        });
    }
  }, [query.sessionId, navigate])

  // Wait for session load
  if (query.sessionId && isLoading) {
    return <LoadingCircle />;
  }

  return (
    <Page>
      <Helmet>
        <title>Finish Setup &middot; My Account &middot; ReChecked Manager</title>
      </Helmet>
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h2">Finish Setup</Typography>
          <Typography>
            You're almost done! Please finish your subscription payment to start managing your agents and configurations.
          </Typography>
          <Typography>
            For email, enter your <i>billing email</i>, this can be different for each organization.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PaymentForm />
        </Grid>
      </Grid>
    </Page>
  );
}
export default Payment;
import axios from 'axios';
import { API_URL } from 'config';

function buildApiURL(agent, view, path, args = {}) {
  const apiUrl = `${agent.protocol}//${view === 'hostname' ? agent.hostname : agent.address}:${agent.port}/status/`;
  const params = { token: agent.token, ...args };
  const strParams = `?${Object.keys(params).map(i => {
    if (Array.isArray(params[i])) {
      return "arg="+params[i].join('&arg=');
    }
    return `${i}=${params[i]}`;
  }).join('&')}`;
  return apiUrl+path+strParams;
}

const PostJSON = async (path, data) => {
  const rawResponse = await fetch(API_URL+path, {
    method: 'POST',
    credentials: "include",
    body: JSON.stringify(data)
  });
  return await rawResponse.json();
}

const GetJSON = async (path) => {
  const resp = await fetch(API_URL+path, {
    method: "get",
    credentials: "include"
  });
  return await resp.json();
}

const API = {
  opts: {
    withCredentials: true
  },
  get(path, data) {
    return axios.get(API_URL+path, { params: data, ...this.opts });
  },
  post(path, data) {
    return axios.post(API_URL+path, data, this.opts);
  },
  put(path, data) {
    return axios.put(API_URL+path, data, this.opts);
  },
  delete(path, data) {
    return axios.delete(API_URL+path, { params: data, ...this.opts });
  }
}

export { buildApiURL, API, PostJSON, GetJSON };
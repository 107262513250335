import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { useAuth } from 'context/auth';
import { API } from 'utils/network';
import { LoadingCircle } from 'components/layout';
import { PRODUCTION } from 'config';

var stripePromise;
if (PRODUCTION) {
  stripePromise = loadStripe("pk_live_51OA4oKBr7LJjWlu2TvfhwXD2pJsnjPwB1A9433CkBSmqnTiTntfLF70ffeBEmBH7dtyr0K9COjiKfTlBtx31dxxj00pSywPOMo");
} else {
  stripePromise = loadStripe("pk_test_51OA4oKBr7LJjWlu2NzKbfPLHxkIuPllXl3P1PxlS4UTpk5iGCBpPRJAGeL4Zk3eLcaJnbGIhNfZRvLrxZT7VNrX400vvRxVZKW");
}

function PaymentForm() {

  const { selectedOrgId } = useAuth();

  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    API.post("stripe/session/create", {
      orgId: selectedOrgId
    })
      .then((resp) => {
        setClientSecret(resp.data.clientSecret)
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {clientSecret ? (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{clientSecret}}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : <LoadingCircle />}
    </div>
  );
}

export default PaymentForm;
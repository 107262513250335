import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function ConfigLinkButton({ name }) {

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const url = `/configure/checks?search=${name}`;

  return (
    <IconButton size="small" onClick={() => openInNewTab(url)} color="secondary">
      <OpenInNewIcon fontSize="inherit" />
    </IconButton>
  );
}

export default ConfigLinkButton;
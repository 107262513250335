import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { useAuth } from 'context/auth';
import { useAddSecrets, useEditSecrets, useSecret } from 'hooks/organizations';

function SecretsModal({ secret, open, setOpen }) {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { selectedOrgId } = useAuth();
  const addMutation = useAddSecrets(selectedOrgId);
  const editMutation = useEditSecrets(selectedOrgId);
  const { data } = useSecret(selectedOrgId, secret, open === true && secret !== undefined);

  const { control, formState: { errors }, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      value: ''
    },
    values: {
      name: secret,
      value: data ? data.value : ''
    }
  });

  const handleClose = () => {
    setOpen(false);
    setError("");
    setTimeout(reset, 200);
  };

  const onSubmit = (data) => {
    setLoading(true);
    if (secret) {
      editMutation.mutate(data,
      {
        onSuccess: () => {
          handleClose();
        },
        onError: () => {
          setError("Could not save secret.");
        },
        onSettled: () => {
          setLoading(false);
        }
      });
    } else {
      addMutation.mutate(data,
      {
        onSuccess: () => {
          handleClose();
        },
        onError: () => {
          setError("Could not add secret.");
        },
        onSettled: () => {
          setLoading(false);
        }
      });
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{secret ? 'Edit' : 'Add'} Secret</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: 3 }}>
            Secrets must be letters A-Z, numbers 0-9 and underscores (_) only.
          </DialogContentText>
          {error && <Alert severity="error" sx={{ marginBottom: 4 }}>{error}</Alert>}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Name is required" }}
                render={({ field }) => <TextField
                  size="small" label="Name" fullWidth autoFocus required disabled={secret ? true : false}
                  {...field}
                  onChange={(e) => field.onChange(e.target.value ? e.target.value.toUpperCase().replace(/[^A-Z0-9_]/g, '') : '')}
                  helperText={errors.name?.message} error={errors.name !== undefined}
                />}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="value"
                control={control}
                render={({ field }) => <TextField
                  size="small" label="Value" fullWidth multiline rows={6}
                  {...field}
                  helperText={errors.value?.message} error={errors.value !== undefined}
                />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={loading} type="submit" color="secondary">
            {secret ? 'Save' : 'Add'} Secret
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default SecretsModal;
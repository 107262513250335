import { useState } from 'react';

import Button from '@mui/material/Button';

import { useAuth } from 'context/auth';
import { ChangePasswordModal } from 'components/modals/account';

function ChangePasswordButton() {

  const { themeSettings } = useAuth();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button variant="contained" color={themeSettings.mode === 'dark' ? 'secondary' : 'primary'} onClick={() => setOpen(true)}>Change Password</Button>
      <ChangePasswordModal open={open} setOpen={setOpen} />
    </>
  );
}

export default ChangePasswordButton;
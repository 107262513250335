import { useState } from 'react';

import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';

import SecretsModal from 'components/modals/SecretsModal';

function AddSecretButton() {

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button color="secondary" variant="contained" onClick={() => setOpen(true)} startIcon={<AddIcon />}>Add Secret</Button>
      <SecretsModal open={open} setOpen={setOpen} />
    </>
  );
}

export default AddSecretButton;
import { useState } from 'react';

import InstanceModal from 'components/modals/config/InstanceModal';
import { CustomIconButton } from 'components/buttons';

function EditInstanceButton({ instance }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <CustomIconButton onClick={() => setOpen(true)} type="edit" />
      <InstanceModal instance={instance} open={open} setOpen={setOpen} />
    </>
  );
}

export default EditInstanceButton;
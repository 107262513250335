import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import CircleIcon from '@mui/icons-material/Circle';
import ListAltIcon from '@mui/icons-material/ListAlt';

import { useAuth } from 'context/auth';
import { useAllGroup } from 'hooks/organizations';
import { TooltipIconButton } from 'components/buttons';

function AllAgentsGroup() {

  const { selectedOrgId } = useAuth();
  const { isLoading, data } = useAllGroup(selectedOrgId);

  return (
    <Grid item>
      <Card variant="outlined" sx={{ height: '100%' }}>
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography variant="h6">All Agents</Typography>
              <Stack direction="row" spacing={1}>
                <TooltipIconButton component={RouterLink} to={`/agents`} icon={<ListAltIcon fontSize="small" />} tooltip="View all agents" />
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2}>
              <CircleIcon color="success" />
              {isLoading ? <Skeleton sx={{ width: '100%' }} /> : (
                <Link component={RouterLink} to={`/agents?status=1`} underline="hover">
                  <Typography variant="body1">{data?.checkingIn ?? '??'} Checking In</Typography>
                </Link>
              )}
            </Stack>
            <Stack direction="row" spacing={2}>
              <CircleIcon color="disabled" />
              {isLoading ? <Skeleton sx={{ width: '100%' }} /> : (
                <Link component={RouterLink} to={`/agents?status=2`} underline="hover">
                  <Typography variant="body1">{data?.unknown ?? '??'} Unknown</Typography>
                </Link>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default AllAgentsGroup;
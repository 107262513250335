import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { useOrganizationRegenerateAPIKey, useOrganizationRegenerateCACert } from 'hooks/organizations';
import { ConfirmButton } from 'components/buttons';

function AdminTab({ orgId }) {

  const mutateAPIKey = useOrganizationRegenerateAPIKey(orgId);
  const mutateCACert = useOrganizationRegenerateCACert(orgId);

  const submitRegen = async (pw, mutate) => {
    try {
      await mutate.mutateAsync({
        confPassword: pw
      });
    } catch (err) {
      if (err.code === "ERR_BAD_REQUEST") {
        return [true, "Password was incorrect"];
      }
      return [true, "Could not verify password, please try again later"];
    }
    return [false, ""];
  };

  return (
    <Stack spacing={2}>
      <Typography>Admin-only settings for your Organization. Be careful with these settings, they will impact your entire setup and can cause all agents to no longer sync with your Organization.</Typography>
      <Stack spacing={2} direction="row">
        <ConfirmButton
          ButtonComponent={Button}
          variant="outlined"
          onConfirm={(pw) => submitRegen(pw, mutateAPIKey)}
          title="Regenerate API Key"
          description="Are you sure you want to regenerate your API key for this organization?"
          warning="Changing an organization's API key will make all syncing agents no longer able to sync and connect to the manager."
          reqPw
        >
          Regenerate API Key
        </ConfirmButton>
        <ConfirmButton
          ButtonComponent={Button}
          variant="outlined"
          onConfirm={(pw) => submitRegen(pw, mutateCACert)}
          title="Regenerate CA Certificate"
          description="Are you sure you want to regenerate your CA Certificate for this organization? Agents will request a new certificate on next sync."
          reqPw
        >
          Regenerate CA Certificate
        </ConfirmButton>
      </Stack>
    </Stack>
  );
}

export default AdminTab;
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { Helmet } from 'react-helmet-async';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import { Page, LoadingCircle } from 'components/layout';
import { useAuth } from 'context/auth';
import { CheckConfigProvider, useCheckConfig } from 'context/checks';
import { useGroups, useConfigs, useAgents, useInstances,
  useEditConfig, useAddConfig } from 'hooks/organizations';
import { ChecksTable } from 'components/layout/config';
import { AddHostCheckButton } from 'components/buttons/config';
import { AgentsSelect, InstancesSelect } from 'components/forms/config';

function ConfigsForm() {
  const { configId } = useParams();
  return (
    <Page>
      <Helmet>
        <title>Edit Config &middot; Check Configurations &middot; ReChecked Manager</title>
      </Helmet>
      <CheckConfigProvider>
        <Grid container maxWidth="lg" spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4">{configId ? 'Edit' : 'Create'} Config</Typography>
            <Typography>
              You can add hosts and services to all agents, a custom list of agents, or a certain group. Config parsing is additive. If multiple configs with the same host or host/service combination, the config will be parsed and updated in order as it generates checks.
            </Typography>
          </Grid>
          <CreateNewConfigForm configId={configId} />
        </Grid>
      </CheckConfigProvider>
    </Page>
  );
}

function CreateNewConfigForm({ configId }) {

  const [initialLoad, setInitialLoad] = useState(true);
  const [initialCheckLoad, setInitialCheckLoad] = useState(true);

  const navigate = useNavigate();
  const { checks, setChecks } = useCheckConfig();

  const { selectedOrgId } = useAuth();

  // ONLY RUN THESE IF WE ARE EDITING
  const { isLoading: isConfigLoading, data: configs } = useConfigs(selectedOrgId, configId ? true : false, true);
  const { isLoading: isAgentsLoading, data: agents } = useAgents(selectedOrgId, null, configId ? true : false);
  const { isLoading: isInstancesLoading, data: instances } = useInstances(selectedOrgId, configId ? true : false);
  const config = configs?.find(c => c.id === parseInt(configId));

  const { data: groups } = useGroups(selectedOrgId);

  const editMutation = useEditConfig(selectedOrgId);
  const addMutation = useAddConfig(selectedOrgId);

  const { control, formState: { errors }, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      name: '',
      applyTo: 'all',
      groupId: '',
      agents: [],
      instances: []
    }
  });

  // Load the form with initail data (unfortunately if we use values in useForm it will overwrite as new data comes in)
  useEffect(() => {
    if (config && instances && agents && initialLoad) {
      reset({
        name: config.name,
        applyTo: config.allAgents ? 'all' : config.agentIds?.length > 0 ? 'list' : 'group',
        groupId: config.groupId > 0 ? config.groupId : '',
        agents: config.agentIds?.length > 0 ? agents.filter(a => config.agentIds.includes(a.id)) : [],
        instances: config.instanceIds?.length > 0 ? instances.filter(i => config.instanceIds.includes(i.id)) : []
      });
      setInitialLoad(false);
    }
  }, [config, reset, configs, instances, agents, initialLoad])

  // Load checks into the form (again need initial check load because config reload/change)
  useEffect(() => {
    if (config && config.checks && initialCheckLoad) {
      setChecks(JSON.parse(config.checks) ?? []);
      setInitialCheckLoad(false);
    }
  }, [config, setChecks, initialCheckLoad]);

  const onSubmit = (data) => {

    // Format data to send to the backend (need plugin ids and services count, plugin ids must be unique)
    var services = 0;
    var plugins = [];
    checks.forEach(c => {
      services += c.services.length;
      if (c.pluginOpt && c.pluginOpt !== "manual" && plugins.indexOf(c.pluginOpt) === -1) {
        plugins.push(c.pluginOpt);
      }
      c.services.forEach(s => {
        if (s.pluginOpt && s.pluginOpt !== "manual" && plugins.indexOf(s.pluginOpt) === -1) {
          plugins.push(s.pluginOpt);
        }
      });
    });

    var checkData = {
      name: data.name,
      checks: JSON.stringify(checks),
      applyTo: data.applyTo,
      instances: data.instances.map(i => i.id),
      hosts: checks.length,
      services: services,
      plugins: plugins
    };

    // Set agent ids for custom amount of agents
    if (data.applyTo === "list") {
      checkData.agentIds = data.agents.map(a => a.id);
    } else if (data.applyTo === "group") {
      checkData.groupId = data.groupId;
    }

    if (configId) {
      editMutation.mutate({
          configId,
          checkData
        }, {
        onSuccess: () => {
          navigate("/configure/checks");
        },
        onError: () => {

        }
      });
    } else {
      addMutation.mutate(checkData, {
        onSuccess: () => {
          navigate("/configure/checks");
        },
        onError: () => {

        }
      });
    }
  };

  const applyTo = watch("applyTo");

  // Loading
  if (configId && (isConfigLoading || isInstancesLoading || isAgentsLoading)) {
    return <Grid item xs={12}><LoadingCircle /></Grid>;
  }

  return (
    <>
      <Grid item xs={12} xl={8}>
        <form onSubmit={handleSubmit(onSubmit)} id="fullCheck">
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Config name is required" }}
                render={({ field }) => (
                  <TextField
                    size="small" label="Config Name"
                    fullWidth autoFocus required
                    {...field}
                    helperText={errors.name?.message} error={errors.name !== undefined}
                  />)}
                />
              <Controller
                name="applyTo"
                control={control}
                rules={{ required: "Must select agents to apply a configuration to" }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <TextField
                      {...field}
                      size="small"
                      label="Apply Config To"
                      select required
                    >
                      <MenuItem value="all">All Agents</MenuItem>
                      <MenuItem value="list">Select Agents</MenuItem>
                      <MenuItem value="group">Agent Group</MenuItem>
                    </TextField>
                  </FormControl>
                )}
              />
              {applyTo === 'list' && (
                <Controller
                  name="agents"
                  control={control}
                  rules={{ required: "Must select at least one agent to apply configuration to" }}
                  render={({ field: { onChange, value } }) => <AgentsSelect label="Selected Agents" required onChange={onChange} value={value} />}
                />
              )}
              {applyTo === 'group' && (
                <Controller
                  name="groupId"
                  control={control}
                  rules={{ required: "Must select a group to apply configuration to" }}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <TextField
                        {...field}
                        size="small"
                        label="Group Name"
                        select required
                      >
                        {groups && groups.map(group => (
                          <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  )}
                />
              )}
            </Stack>
            <Stack spacing={2}>
              <Stack>
                <Typography variant="h5">Nagios Instances</Typography>
                <Typography variant="body2">Select the Nagios NRDP instances that you'd like to send these passive checks to.</Typography>
              </Stack>
              <Controller
                name="instances"
                control={control}
                rules={{ required: "Must select a Nagios instance to send checks to" }}
                render={({ field: { onChange, value } }) => <InstancesSelect label="Instances" required onChange={onChange} value={value}
                 helperText={errors.instances?.message}
                 error={errors.instances !== undefined} />}
              />
            </Stack>
          </Stack>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Stack>
            <Typography variant="h5">Host and Service Checks</Typography>
            <Typography variant="body2">Set up the hosts and services that are going to be applied to all the agents and over NRDP to the selected Nagios instances.</Typography>
          </Stack>
          <ChecksTable type="hosts" />
          <AddHostCheckButton />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Button component={Link} to="/configure/checks" variant="outlined">Cancel</Button>
          <Button type="submit" variant="contained" color="secondary" form="fullCheck">Save Config</Button>
        </Stack>
      </Grid>
    </>
  );
}

export default ConfigsForm;
import { useState } from 'react';

import { CustomIconButton } from 'components/buttons';
import CheckModal from 'components/modals/config/CheckModal';

function EditServiceCheckButton({ hostname, servicename }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <CustomIconButton type="edit" onClick={() => setOpen(true)} title="Edit" />
      <CheckModal type="service" edit={hostname} servicename={servicename} open={open} setOpen={setOpen} />
    </>
  );
}

export default EditServiceCheckButton;
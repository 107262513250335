import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';

import { useAuth } from 'context/auth';

function Page(props) {

  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const margin = props.margin ?? (mobileSize ? 2 : 5);
  const { selectedOrgId } = useAuth();

  if (mobileSize) {
    return (
      <Box sx={{ m: margin }}>
        {props.children}
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 8 + margin, ml: selectedOrgId === 0 ? 0 : 30 }}>
      <Box sx={{ m: margin }}>
        {props.children}
      </Box>
    </Box>
  );
}

export default Page;
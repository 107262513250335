import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';

import { useAuth } from 'context/auth';
import { useAddInstance, useEditInstance } from 'hooks/organizations';
import { Typography } from '@mui/material';

function InstanceModal({ instance, open, setOpen }) {

  const [error, setError] = useState("");

  const { selectedOrgId } = useAuth();
  const instanceAddMutate = useAddInstance(selectedOrgId);
  const instanceEditMutate = useEditInstance(selectedOrgId);

  const { control, formState: { errors }, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      url: '',
      token: '',
      engineType: 'xi',
      hostTemplate: '',
      serviceTemplate: ''
    },
    values: instance
  });

  const handleClose = () => {
    setOpen(false);
    setError("");
    setTimeout(reset, 200);
  };

  const onSubmit = (data) => {
    setError(false);
    if (instance) {
      instanceEditMutate.mutate({
        instanceId: instance.id,
        data
      }, {
        onSuccess: () => {
          handleClose();
        },
        onError: () => {
          setError("Could not update Nagios instance.");
        }
      })
    } else {
      instanceAddMutate.mutate(data, {
        onSuccess: () => {
          handleClose();
        },
        onError: () => {
          setError("Could not create Nagios instance.");
        }
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{instance ? 'Edit' : 'Create'} Instance</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: 3 }}>
            Please give a name and provide the Nagios instance's NRDP details below.
          </DialogContentText>
          {error && <Alert severity="error" sx={{ marginBottom: 4 }}>{error}</Alert>}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Instance name is required" }}
                render={({ field }) => <TextField
                  size="small" label="Instance Name" fullWidth autoFocus required
                  {...field}
                  helperText={errors.name?.message} error={errors.name !== undefined}
                />}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="engineType"
                control={control}
                rules={{ required: "Monitoring engine type is required" }}
                render={({ field }) => <TextField
                  size="small" label="Monitoring Engine" fullWidth required select
                  {...field}
                  helperText={errors.name?.message} error={errors.name !== undefined}
                >
                  <MenuItem value="xi">Nagios XI</MenuItem>
                  <MenuItem value="core">Nagios Core</MenuItem>
                </TextField>}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="url"
                control={control}
                rules={{ required: "NRDP URL is required" }}
                render={({ field }) => <TextField
                  size="small" label="NRDP URL" fullWidth required
                  {...field}
                  helperText={errors.url?.message} error={errors.url !== undefined}
                />}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="token"
                control={control}
                rules={{ required: "NRDP token is required" }}
                render={({ field }) => <TextField
                  size="small" label="NRDP Token" fullWidth required
                  {...field}
                  helperText={errors.token?.message} error={errors.token !== undefined}
                />}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ pb: 1 }}>Core Config File Settings</Divider>
              <Typography>Edit host/service templates used when building Nagios Core Configs. If empty, it will use the rcmanager default templates. If you're automatically applying unconfigured passive checks in Nagios XI you can ignore this.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="hostTemplate"
                control={control}
                render={({ field }) => <TextField
                  size="small" label="Host Template" fullWidth
                  placeholder="rcmanager_passive_host"
                  {...field}
                  helperText={errors.hostTemplate?.message} error={errors.hostTemplate !== undefined}
                />}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="serviceTemplate"
                control={control}
                render={({ field }) => <TextField
                  size="small" label="Service Template" fullWidth
                  placeholder="rcmanager_passive_service"
                  {...field}
                  helperText={errors.serviceTemplate?.message} error={errors.serviceTemplate !== undefined}
                />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color="secondary">{instance ? 'Save' : 'Create'} Instance</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default InstanceModal;
import { useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import UsersTable from './UsersTable';
import BillingTab from './BillingTab';
import AdminTab from './AdminTab';
import { useAuth } from 'context/auth';

function BusinessTabs({ org }) {

  const { hasPerm } = useAuth();

  const [tab, setTab] = useState('users');

  const handleChange = (e, val) => {
    setTab(val);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="">
            <Tab label="Users" value="users" />
            {hasPerm('Admin') && <Tab label="Billing" value="billing" />}
            {hasPerm('Admin') && <Tab label="Admin" value="admin" />}
          </TabList>
        </Box>
        <TabPanel sx={{ px: 0, pb: 0 }} value="users">
          <UsersTable org={org} />
        </TabPanel>
        {hasPerm('Admin') && (
          <>
            <TabPanel sx={{ px: 0, pb: 0 }} value="billing">
              <BillingTab orgId={org.id} />
            </TabPanel>
            <TabPanel sx={{ px: 0, pb: 0 }} value="admin">
              <AdminTab orgId={org.id} />
            </TabPanel>
          </>
        )}
      </TabContext>
    </Box>
  );
}

export default BusinessTabs;
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import { useOrganizationSubscription, useOrganizationSubscriptionCancellation } from 'hooks/organizations';
import { formatMoneyValue, formatLocalDate } from 'utils/common';
import LoadingCircle from '../LoadingCircle';
import { ConfirmButton } from 'components/buttons';

function BillingTab({ orgId }) {

  const { isLoading, data } = useOrganizationSubscription(orgId);
  const cancel = useOrganizationSubscriptionCancellation(orgId);

  const cancelSub = async (pw) => {
    try {
      await cancel.mutateAsync({
        confPassword: pw
      });
    } catch (err) {
      if (err.code === "ERR_BAD_REQUEST") {
        return [true, "Password was incorrect"];
      }
      return [true, "Could not verify password, please try again later"];
    }
    return [false, ""];
  };

  if (isLoading) {
    return <LoadingCircle />;
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <Stack spacing={2}>
          <Typography variant="h5">Subscription Details</Typography>
          {data?.canceled ? (
            <Stack spacing={1}>
              <Typography>End Date: {formatLocalDate(data?.periodEnd)}</Typography>
              <Typography>Your subscription has been cancelled. Your account will function normally until the billing period's end date. Please contact us at <Link color="secondary" href="mailto:connect@rechecked.io">connect@rechecked.io</Link> if you'd like to re-subscribe.</Typography>
            </Stack>
          ) : (
            <Stack spacing={1}>
              <Typography>Last Invoice Amount: ${formatMoneyValue(data?.lastInvoiceTotal/100)}</Typography>
              <Typography>Current Billing Period: {formatLocalDate(data?.periodStart)} - {formatLocalDate(data?.periodEnd)}</Typography>
              <Typography>Start Date: {formatLocalDate(data?.startDate)}</Typography>
              <Typography>Payment Method: {data?.paymentMethod}</Typography>
            </Stack>
          )}
        </Stack>
      </Grid>
      {!data?.canceled && (
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="h5">Payment Settings</Typography>
              <Typography>If you would like to change payment method, make changes to billing information or to your subscription, please contact us at <Link color="secondary" href="mailto:connect@rechecked.io">connect@rechecked.io</Link>.</Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h5">Cancel Subscription</Typography>
              <Typography>Your organization will still function normally until the end of the subscription period if you cancel.</Typography>
              <ConfirmButton
                ButtonComponent={Button}
                color="error"
                variant="outlined"
                sx={{ maxWidth: 300 }}
                onConfirm={(pw) => cancelSub(pw)}
                title="Cancel Subscription"
                description={"Are you sure you'd like to cancel your subscription? Your account will continue to function normally until " + formatLocalDate(data?.periodEnd) + "."}
                reqPw
                >
                Cancel Subscription
              </ConfirmButton>
            </Stack>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}

export default BillingTab;
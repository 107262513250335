import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CircleIcon from '@mui/icons-material/Circle';

function StatusSelect({ value, onChange }) {
  return (
    <FormControl fullWidth>
      <InputLabel size="small" id="status-select-label">Status Filter</InputLabel>
      <Select
        size="small"
        labelId="status-select-label"
        id="status-select"
        label="Group Filter"
        value={value ?? ''}
        onChange={(e) => onChange && onChange(e.target.value)}
      >
        <MenuItem value=""><em>None</em></MenuItem>
        <MenuItem value={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <CircleIcon fontSize="small" color="success" />
            <Typography>Checking In</Typography>
          </Stack>
        </MenuItem>
        <MenuItem value={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <CircleIcon fontSize="small" color="disabled" />
            <Typography>Unknown</Typography>
          </Stack>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export { StatusSelect };
import { useForm, Controller } from 'react-hook-form';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';

import { ENDPOINTS } from 'config';
import { useCheckConfig } from 'context/checks';
import EndpointSelect from 'components/forms/config/EndpointSelect';
import UnitsSelect from 'components/forms/config/UnitsSelect';
import PluginSelect from 'components/forms/config/PluginSelect';

function CheckModal({ type, hostname, servicename, edit, open, setOpen }) {

  const { checks, setChecks } = useCheckConfig();

  let check = null;
  if (edit) {
    check = checks.find(c => c.hostname === edit);
    if (servicename) {
      check = check.services.find(s => s.servicename === servicename);
    }
  }

  const { control, formState: { errors }, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      hostname: hostname ?? '',
      servicename: servicename ?? '',
      interval: '5m',
      endpoint: 'cpu/percent',
      warning: '',
      critical: '',
      disk: '',
      interface: '',
      pluginOpt: '',
      plugin: '',
      args: '',
      name: '',
      against: '',
      expected: '',
      units: ''
    },
    values: check
  });

  const handleClose = () => {
    setOpen(false);
    setTimeout(reset, 200);
  };

  const onSubmit = (data) => {

    // Special case for "args" atm args is only a string but in the future
    // it should be an array of arguments passed, so turn it into an array before saving for now
    data.args = Array.isArray(data.args) ? data.args : [data.args];

    if (type === 'host') {
      if (edit) {
        // Update host by overriding it with the new one
        setChecks(prevState => {
          const index = prevState.findIndex(c => c.hostname === edit);
          let newChecks = prevState;
          data.services = newChecks[index].services; // Apply old services before re-adding check
          data.services.forEach(s => s.hostname = data.hostname);
          newChecks[index] = data;
          return [...newChecks];
        });
      } else {
        data.services = data.services ?? [];
        setChecks(prevState => [...prevState, data]);
      }
    } else {
      if (edit) {
        // Update service by overriding old one
        setChecks(prevState => {
          const index = prevState.findIndex(c => c.hostname === edit);
          let newChecks = prevState;
          const subIndex = prevState[index].services.findIndex(s => s.servicename === servicename);
          newChecks[index].services[subIndex] = data; // Apply new service over old service
          return [...newChecks];
        });
      } else {
        setChecks(prevState => {
          const index = prevState.findIndex(c => c.hostname === hostname);
          let newChecks = prevState;
          newChecks[index].services.push(data);
          return [...newChecks];
        });
      }
    }
    handleClose();
  };

  const validateHostname = (value) => {
    if (edit) {
      return true;
    }
    return checks.some(c => c.hostname === value) === false || "Hostname is already in use and must be unique";
  }

  const endpoint = watch("endpoint");
  const pluginOpt = watch("pluginOpt");

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{edit ? 'Edit' : 'Add'} {type === 'host' ? 'Host' : 'Service'} Check</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                {type === 'host' ? 
                  `Set up a host check and you can add as many services to the host afterwards as you'd like.` :
                  `For service checks, to use the host data, you can use $HOST_NAME or $HOST_ADDRESS variables.`
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="hostname"
                control={control}
                rules={{ required: "Hostname is required", validate: type === 'host' && validateHostname }}
                render={({ field }) => <TextField size="small" label="Hostname" required fullWidth
                  {...field} disabled={type === 'service'}
                  helperText={errors.hostname?.message ?? type === 'host' ? 'You can use $LOCAL_HOSTNAME to substitute the hostname of the rcagent system' : ''} error={errors.hostname !== undefined}
                />}
              />
            </Grid>
            {type === "host" && (
              <Grid item xs={12}>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => <TextField size="small" label="Address" fullWidth
                    {...field} disabled={type === 'service'}
                    helperText={errors.address?.message ?? type === 'host' ? 'The address for the system (can be different from hostname) which can be used later as $HOST_ADDRESS in a services plugin args' : ''} error={errors.address !== undefined}
                  />}
                />
              </Grid>
            )}
            {type === "service" && (
              <Grid item xs={12}>
                <Controller
                  name="servicename"
                  control={control}
                  rules={{ required: "Service name is required" }}
                  render={({ field }) => <TextField size="small" label="Servicename" required fullWidth
                    {...field}
                    helperText={errors.servicename?.message} error={errors.servicename !== undefined}
                  />}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider textAlign="left">Check Settings</Divider>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                name="endpoint"
                control={control}
                render={({ field }) => <EndpointSelect {...field} required fullWidth />}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                name="interval"
                control={control}
                rules={{ required: "Interval is required" }}
                render={({ field }) => <TextField size="small" label="Check Interval" required fullWidth
                  {...field}
                  helperText={errors.interval?.message} error={errors.interval !== undefined}
                />}
              />
            </Grid>
            {(endpoint === "disk" || endpoint === "disk/inodes") && (
              <Grid item xs={12}>
                <Controller
                  name="disk"
                  control={control}
                  rules={{ required: "A disk path is required" }}
                  render={({ field }) => <TextField size="small" label="Disk Path" required fullWidth
                    {...field}
                    helperText={errors.disk?.message ?? 'Example: C: for windows or / on linux'} error={errors.disk !== undefined}
                  />}
                />
              </Grid>
            )}
            {endpoint === "network" && (
              <>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: "An interface is required" }}
                    render={({ field }) => <TextField size="small" label="Interface" required fullWidth
                      {...field}
                      helperText={errors.name?.message ?? 'Example: Ethernet for windows or eth0 / ens33 for linux'} error={errors.name !== undefined}
                    />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="against"
                    control={control}
                    rules={{ required: "An interface is required" }}
                    render={({ field }) => <TextField select size="small" label="Against" fullWidth
                      {...field}
                      helperText={errors.against?.message ?? 'By default (nothing selected) the network check is against the total traffic in/out'} error={errors.against !== undefined}
                    >
                      <MenuItem value="">&nbsp;</MenuItem>
                      <MenuItem value="total">Total</MenuItem>
                      <MenuItem value="in">Inbound</MenuItem>
                      <MenuItem value="out">Outbound</MenuItem>
                    </TextField>}
                  />
                </Grid>
              </>
            )}
            {endpoint === "plugins" && (
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Controller
                    name="pluginOpt"
                    control={control}
                    render={({ field }) => <PluginSelect fullWidth required size="small" {...field} onChange={(e, c) => {
                        field.onChange(e.target.value);
                        setValue("plugin", c.props.name);
                      }}
                      helperText="Select a plugin to use (the plugin is downloaded to the agent if it doesn't alreay exist on it) or to use a plugin already on the agent select 'Manually Defined' and give a plugin name" />}
                  />
                  {pluginOpt === 'manual' && <Controller
                    name="plugin"
                    control={control}
                    rules={{ required: "Plugin name is required" }}
                    render={({ field }) => <TextField size="small" label="Plugin Name" required fullWidth
                      {...field}
                      helperText={errors.plugin?.message || "Name of plugin that is already on the agent system"} error={errors.plugin !== undefined}
                    />}
                  />}
                  <Controller
                    name="args"
                    control={control}
                    render={({ field }) => <TextField size="small" label="Plugin Arguments" fullWidth {...field} />}
                  />
                </Stack>
              </Grid>
            )}
            {endpoint === "processes" && (
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => <TextField size="small" label="Process Name" fullWidth {...field} helperText="Optional exact name (example: rcagent.exe) to only count specific processes" />}
                />
              </Grid>
            )}
            {endpoint === "services" && (
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                  <Controller
                    name="against"
                    control={control}
                    render={({ field }) => <TextField {...field} required size="small" label="Service Name" fullWidth
                      helperText={errors.against?.message ?? 'Must match the exact service name'} error={errors.against !== undefined} />}
                  />
                  <Controller
                    name="expected"
                    control={control}
                    render={({ field }) => <TextField {...field} required size="small" label="Serice Status" fullWidth
                      helperText={errors.expected?.message ?? 'Status is normally "running" or "stopped"'} error={errors.expected !== undefined} />}
                  />
                </Stack>
                <Typography variant="body2"></Typography>
              </Grid>
            )}
            {endpoint !== "system/version" && endpoint !== "plugins" && endpoint !== "services" && (
              <Grid item xs={12}>
                <Divider sx={{ mb: 2 }} textAlign="left">Alert Options</Divider>
                <Stack direction="row" spacing={2}>
                  <Controller
                    name="warning"
                    control={control}
                    render={({ field }) => <TextField {...field} size="small" label="Warning" fullWidth />}
                  />
                  <Controller
                    name="critical"
                    control={control}
                    render={({ field }) => <TextField {...field} size="small" label="Critical" fullWidth />}
                  />
                  {ENDPOINTS.find(e => e.name === endpoint).units && (
                    <Controller
                      name="units"
                      control={control}
                      render={({ field }) => <UnitsSelect {...field} />}
                    />
                  )}
                </Stack>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color="secondary">{edit ? 'Save' : 'Add'} Check</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CheckModal;
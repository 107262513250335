import { useMutation } from '@tanstack/react-query';
import { API } from 'utils/network';

export function useLogin() {
  return useMutation({
    mutationFn: async (data) => {
      const resp = await API.post("login", data);
      return resp.data;
    }
  });
}

export function useRegister() {
  return useMutation({
    mutationFn: async (data) => {
      const resp = await API.post("register", data);
      return resp.data;
    }
  });
}

export function useResetPassword() {
  return useMutation({
    mutationFn: async (data) => {
      const resp = await API.post("reset", data);
      return resp.data;
    }
  });
}

export function useConfirmResetPassword() {
  return useMutation({
    mutationFn: async (data) => {
      const resp = await API.post("reset/confirm", data);
      return resp.data;
    }
  });
}

export function useVerifyEmail() {
  return useMutation({
    mutationFn: async (data) => {
      const resp = await API.post("verify", data);
      return resp.data;
    }
  });
}

export function use2FAVerify() {
  return useMutation({
    mutationFn: async (data) => {
      const resp = await API.post("otp/verify", data);
      return resp.data;
    }
  });
}
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import CircleIcon from '@mui/icons-material/Circle';

function TableStatusIcon({ status }) {
  return (
    <Stack alignItems="center" justifyContent="center">
      <Tooltip placement="top" title={status === 1 ? "Checking In" : "Unknown"}>
        <CircleIcon color={status === 1 ? 'success' : 'disabled'} />
      </Tooltip>
    </Stack>
  );
}

export default TableStatusIcon;
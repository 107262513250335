import { useState } from 'react';

import Button from '@mui/material/Button';

import { Setup2FAModal } from 'components/modals/account';
import { useAuth } from 'context/auth';

function Manage2FAButton() {

  const { otpEnabled } = useAuth();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>{otpEnabled ? 'Disable' : 'Enable'} 2FA</Button>
      <Setup2FAModal open={open} setOpen={setOpen} />
    </>
  );
}

export default Manage2FAButton;
import { useState } from 'react';
import { useForm, Controller } from "react-hook-form";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { UserPermsInfo } from 'components/layout/organizations';
import { useInviteOrganizationUsers } from 'hooks/organizations';
import { userLevels } from 'config';

function InviteButton({ orgName, orgId, disabled }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)} startIcon={<PersonAddIcon />} disabled={disabled} fullWidth variant="outlined" color="secondary">Invite User</Button>
      <InviteModal open={open} setOpen={setOpen} orgName={orgName} orgId={orgId} />
    </>
  );
}

function InviteModal({ open, setOpen, orgName, orgId }) {

  const [error, setError] = useState("");

  const addMutate = useInviteOrganizationUsers(orgId);

  const { control, formState: { errors }, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      email: "",
      userlevel: 4
    },
  });

  const handleClose = () => {
    setOpen(false);
    setError("");
    setTimeout(reset, 200);
  };

  const onSubmit = (data) => {
    setError("");
    addMutate.mutate(data, {
      onSuccess: () => {
        handleClose();
      },
      onError: (err) => {
        if (err.response.data?.message && err.response.status !== 500) {
          setError(err.response.data.message);
        } else {
          setError("Could not invite specified address");
        }
      }
    });
  };

  const userlevel = watch("userlevel");

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Invite User to {orgName}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ pb: 3 }}>Send an invite to an email (new users will register during the invite process) and select the permissions you'd like them to have.</DialogContentText>
          {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}
          <Stack spacing={2}>
            <Controller
              name="email"
              control={control}
              rules={{ required: "Valid email address is required" }}
              render={({ field }) => <TextField {...field} size="small" label="Email Address" variant="outlined" id="email"
                autoFocus required helperText={errors.email?.message} error={errors.email !== undefined} fullWidth />}
            />
            <Divider textAlign="left">Security Settings</Divider>
            <Controller
              name="userlevel"
              control={control}
              rules={{ required: "User level is required" }}
              render={({ field }) => (
                <TextField select {...field} size="small" label="User Level" variant="outlined" id="userlevel" required
                  helperText={errors.userlevel?.message} error={errors.userlevel !== undefined} fullWidth>
                  {Object.entries(userLevels).map(lvl => {
                    if (lvl[0] === 'Owner') { return null; }
                    return (
                      <MenuItem key={lvl[1]} value={parseInt(lvl[1])}>{lvl[0]}</MenuItem>
                    );
                  })}
                </TextField>
              )}
            />
          </Stack>
          <UserPermsInfo userlevel={userlevel} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color="secondary" onClick={handleSubmit}>Invite</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default InviteButton;
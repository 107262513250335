import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { Page } from 'components/layout';
import { useAuth } from 'context/auth';
import { isEmpty } from 'utils/common';

function Support() {

  const { getOrgInfo } = useAuth();
  const orgInfo = getOrgInfo();

  return (
    <Page>
      <Helmet>
        <title>Support &middot; ReChecked Manager</title>
      </Helmet>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">Support</Typography>
        </Grid>
        {isEmpty(orgInfo) ? (
          <Grid item xs={12}>
            <Typography>In order to get started, you will need to create an organizaiton. If you're having trouble, you can speak to us directly by emailing <Link color="secondary" href="mailto:connect@rechecked.io">connect@rechecked.io</Link>.</Typography>
          </Grid>
        ) : (
          <SupportMain orgInfo={orgInfo} />
        )}
      </Grid>
    </Page>
  );
}

function SupportMain({ orgInfo }) {

  const { hasPerm } = useAuth();

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Stack spacing={2} sx={{ mb: 4 }}>
          <Button variant="outlined" color="secondary" endIcon={<OpenInNewIcon />} target="_blank" rel="noopener noreferrer" href="https://rechecked.io/documentation/">
            Documentation
          </Button>
          {orgInfo && orgInfo.type !== "personal" && !orgInfo.supportActive && (
            <Button variant="outlined" endIcon={<OpenInNewIcon />} target="_blank" rel="noopener noreferrer" href="https://rechecked.io/support-plans/">
              View Support Plans
            </Button>
          )}
          {orgInfo && orgInfo.type !== "personal" && (
            <Card variant="outlined">
              <CardContent>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <SupportAgentIcon fontSize="large" />
                    <Typography variant="h5">Premium Support {orgInfo.supportActive && ("- Your Support Plan")}</Typography>
                  </Stack>
                  {orgInfo.supportActive ? (
                    <>
                      <Typography>Your account comes with unlimited support with our support techs.</Typography>
                      <Typography>Support Email: <Link color="secondary" href="mailto:support@rechecked.io">support@rechecked.io</Link></Typography>
                      <Typography>
                        You can contact us at any time by email and will recieve responses within 24 business hours. When reaching out, please give us as much information, screenshots, or details in the email.
                      </Typography>
                    </>
                  ) : (
                    <Typography>Your account does not have a premium support plan. If you'd like one on one ticket support with our support techs, please reach out to <Link color="secondary" href="mailto:connect@rechecked.io">connect@rechecked.io</Link> to add a support subscription.</Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>
          )}
          <Card variant="outlined">
            <CardContent>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <HelpOutlineIcon fontSize="large" />
                  <Typography variant="h5">Community Support</Typography>
                </Stack>
                <Typography>
                  If you are looking for general information and basic support for how to use the agent, making sure you're following best practices, or any other questions, our community can help. You can post your issues directly on the <Link color="secondary" href="https://github.com/rechecked/rcagent" taget="_blank" rel="noopener noreferrer">rcagent GitHub</Link> page.
                </Typography>
                <Typography>
                  Please make sure you provide any relevant data in your issues so that others can help you.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack spacing={2}>
          <Typography variant="h5">Having trouble getting started?</Typography>
          <Typography>
            If you're new to using the manager, here's a quick getting started guide. These steps will explain how to get your agents syncing configs and sending check results to your Nagios instances via NRDP:
          </Typography>
          <List sx={{ listStyleType: 'decimal', pl: 4, '& .MuiListItem-root': {
    display: 'list-item',
    } }}>
            <ListItem>
              Start by adding at least one agent, click the "Add Agents" button for more info
            </ListItem>
            <ListItem>
              Wait until you see the added agent(s) checking in on your <Link color="secondary" component={RouterLink} to="/agents">Agents</Link> page
            </ListItem>
            {hasPerm('Manager') && (
              <ListItem>
                If you are going to use custom plugins, upload any custom plugins on the <Link color="secondary" component={RouterLink} to="/agents">Manage Plugins</Link> page
              </ListItem>
            )}
            {hasPerm('Manager') && (
              <ListItem>
                Create a new Nagios Instance (NRDP) from the <Link color="secondary" component={RouterLink} to="/configure/instances">Manage Nagios Instances</Link> page, this will work for both Nagios XI<sup>1</sup> and Nagios Core<sup>2</sup> systems.
              </ListItem>
            )}
            <ListItem>
              Create checks for the agent in the <Link color="secondary" component={RouterLink} to="/configure/checks">Check Configurations</Link> page. If you added multiple agents, you can create groups to logically separate the agents to make management easier. Use the agent(s), Nagios instance, and any plugins or checks you want.
            </ListItem>
            <ListItem>
              Once you have saved the configuration, wait for the agents to sync (about a minute) and verify on the Nagios system that the checks are coming in.
            </ListItem>
          </List>
          <Stack spacing={0}>
            <Typography><sup>1</sup> For best results with Nagios XI, you should enable automatically setting up new incoming passive checks.</Typography>
            <Typography><sup>2</sup> For Nagios Core, you'll need to manually create configurations for the hosts and services.</Typography>
          </Stack>
        </Stack>
      </Grid>
    </>
  );
}

export default Support;
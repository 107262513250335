import { Fragment, useState } from 'react';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmModal from 'components/modals/ConfirmModal';
import ConfirmModalPassword from 'components/modals/ConfirmModalPassword';

function ConfirmButton({ label, disabled, buttonLabel, title, description, onConfirm, ButtonComponent, reqPw, warning, ...props }) {

  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      {ButtonComponent ?
        <ButtonComponent disabled={disabled ? disabled : false} onClick={() => setOpen(true)} {...props} /> :
        <Button disabled={disabled ? disabled : false} color="secondary" onClick={() => setOpen(true)} startIcon={<DeleteIcon />}>{label}</Button>
      }
      {reqPw ?
      <ConfirmModalPassword open={open} setOpen={setOpen}
        title={title}
        text={description}
        okText={buttonLabel ? buttonLabel : "Confirm"}
        onConfirm={onConfirm}
        warning={warning}
      />
      :
      <ConfirmModal open={open} setOpen={setOpen}
        title={title}
        text={description}
        okText={buttonLabel ? buttonLabel : "Confirm"}
        onConfirm={onConfirm}
      />}
    </Fragment>
  );
}

export default ConfirmButton;
import { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

import { LoadingCircle } from 'components/layout';
import { useAuth } from 'context/auth';
import { useAgentConfigs } from 'hooks/organizations';
import { toConfigName } from 'utils/configs';
import { ConfigLinkButton } from 'components/buttons/config';

function ConfigsViewer({ agentId }) {

  const theme = useTheme();
  
  const [tab, setTab] = useState('checks');

  const { selectedOrgId, hasPerm, themeSettings } = useAuth();
  const { isLoading, data } = useAgentConfigs(selectedOrgId, agentId);

  return (
    <>
      <Stack spacing={1} sx={{ pb: 2 }}>
        <Typography variant="body2">All configurations shown are configs that are being synced from the manager to the agent. This view is to help you determine what is being applied to a particular agent if you have it in multiple groups or have multiple configs applied.</Typography>
        <Typography variant="body2">Configurations are stored in the <b><code>manager</code></b> directory in the <b><code>conf.d</code></b> config directory.</Typography>
      </Stack>
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={(e, tabVal) => setTab(tabVal)}
          aria-label="agent configuration tabs"
        >
          <Tab label="Checks" value="checks" />
          <Tab label="Senders" value="senders" />
        </Tabs>
        <Divider />
        {isLoading ? <Box sx={{ p: 3 }}><LoadingCircle /></Box> : (
          <>
            <TabPanel sx={{ p: 0, pt: 2 }} value="checks">
            {data?.configs && Object.keys(data.configs).length > 0 ? Object.keys(data.configs).map(key => {
              return (
                <Box key={key}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography>{toConfigName(key)}.cfg</Typography>
                    {hasPerm('User') && <ConfigLinkButton name={key} />}
                  </Stack>
                  <pre style={{ backgroundColor: themeSettings.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100], padding: 20 }}>{data.configs[key]}</pre>
                </Box>
              );
            }) : (
              <Card variant="outlined" sx={{ p: 2 }}>
                <Typography>No checks are currently configured. Add this agent to a check config or group that has a check config applied to it.</Typography>
              </Card>
            )}
            </TabPanel>
            <TabPanel sx={{ p: 0, pt: 2 }} value="senders">
              {data?.senders === '' ? (
                <Card variant="outlined" sx={{ p: 2 }}>
                  <Typography>No senders have been configured for this agent. This is probably due to no configurations being applied.</Typography>
                </Card>
              ): (
                <>
                  <Typography>senders.cfg</Typography>
                  <pre style={{ backgroundColor: themeSettings.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100], padding: 20 }}>{data?.senders}</pre>
                </>
              )}
            </TabPanel>
          </>
        )}
      </TabContext>
    </>
  );
}

export default ConfigsViewer;
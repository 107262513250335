import { Helmet } from 'react-helmet-async';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Page, LoadingCircle } from 'components/layout';
import { AddSecretButton } from 'components/buttons';
import { EditSecretButton, DeleteSecretButton } from 'components/buttons';
import { formatLocalTime } from 'utils/common';
import { useAuth } from 'context/auth';
import { useSecrets } from 'hooks/organizations';

function Secrets() {

  const { selectedOrgId } = useAuth();
  const { isLoading, data: secrets } = useSecrets(selectedOrgId);

  return (
    <Page>
      <Helmet>
        <title>Manage Secrets &middot; ReChecked Manager</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Manage Secrets</Typography>
          <Typography>Secrets are encrypted data that you don't want shared when editing configurations, you can use them by using $ before the name (<code>$VARIABLE</code>) in check configs.<br /><i>Note: Secrets are stored encrypted on the manager. All agents that sync with the manager will receive a secrets.json file, so secrets can be used in local configs as well.</i></Typography>
        </Grid>
        <Grid item xs={12}>
          <AddSecretButton />
        </Grid>
        <Grid item xs={12} lg={6}>
          {isLoading ? (
            <LoadingCircle />
          ) : (
            <TableContainer variant="outlined" component={Card}>
              <Table aria-label="secrets table">
                <TableHead>
                  <TableRow>
                    <TableCell>Secrets</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell colSpan={2}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {secrets && secrets.map((secret) => (
                    <TableRow
                      key={secret.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="secret">
                        {secret.name}
                      </TableCell>
                      <TableCell>
                        {formatLocalTime(secret.updated)}
                      </TableCell>
                      <TableCell padding="checkbox">
                        <EditSecretButton secret={secret.name} />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <DeleteSecretButton secret={secret.name} />
                      </TableCell>
                    </TableRow>
                  ))}
                  {secrets && secrets.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={2}>No secrets have been created yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default Secrets;
import { useState, createContext, useContext, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { API } from 'utils/network';
import { use2FAVerify } from 'hooks/auth';
import { useOrganizations, useOrganizationsPerms } from 'hooks/account';
import { userLevels } from '../config.js';
import { useUserMetaData } from 'hooks/organizations.js';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider(props) {

  const queryClient = useQueryClient();
  const verifyMutate = use2FAVerify();

  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState({ loggedIn: false, otpVerified: false });
  const [options, setOptions] = useState({});
  const [selectedOrgId, setSelectedOrgId] = useState(parseInt(localStorage.getItem("selectedOrgId")));
  const [userId, setUserId] = useState(0);
  const [otpEnabled, setOtpEnabled] = useState(false);
  const [themeSettings, setThemeSettings] = useState(JSON.parse(localStorage.getItem("themeSettings")) ?? { mode: 'light' });

  const toggleSelectedOrgId = (id) => {
    setSelectedOrgId(id);
    localStorage.setItem("selectedOrgId", id);
  };

  const isLoggedIn = () => {
    if (auth.loggedIn === true) {
      if (otpEnabled && auth.otpVerified === false) {
        return false;
      } else {
        return true;
      }
    } 
  };

  const login = async (email, password) => {

    // Do login here
    try {
      const resp = await API.post("login", {
        email: email,
        password: password
      });
      if (resp.status === 200) {
        setAuth((prevState) => {
          return ({
            ...prevState,
            loggedIn: true
          });
        });
        if (resp.data.options) {
          setOptions(resp.data.options);
          toggleSelectedOrgId(resp.data.options.defaultOrgId);
          setOtpEnabled(resp.data.options.otpEnabled);
        }
        setUserId(resp.data.userId);

        // Prefetching before we are done "loading" auth so permissions are available during render
        await queryClient.prefetchQuery({
          queryKey: ['orgs', 'perms'],
          queryFn: async () => {
            const resp = await API.get("organizations/perms");
            return resp.data;
          }
        });

        // If we have an org (skip if we don't) pre-load the user meta
        if (selectedOrgId > 0) {
          await queryClient.prefetchQuery({
            queryKey: ['orgs', selectedOrgId, 'usermetadata'],
            queryFn: async () => {
              const resp = await API.get(`organizations/${selectedOrgId}/usermetadata`);
              return resp.data;
            }
          });
        }

        return ["", true];
      }
    } catch (err) {
      if (err.response.data.message) {
        return [err.response.data.message, false]
      }
    }

    return ["", false];
  };

  const logout = async () => {

    const resp = await API.post("logout");
    if (resp.status === 200) {
      setAuth({ loggedIn: false, otpVerified: false });
      setOptions({});
      toggleSelectedOrgId(0);
      setOtpEnabled(false);
      setUserId(0);
      return true;
    }

    return false;
  };

  const checkAuth = async () => {
    // TODO: Send request to verify if our cookie is valid
    const resp = await API.get("checkin");
    if (resp.status === 200 && resp.data.userId > 0) {
      setAuth((prevState) => {
          return ({
            ...prevState,
            loggedIn: true,
            otpVerified: resp.data.otpVerified
          });
        });
      if (resp.data.options) {
        setOptions(resp.data.options);
        if (selectedOrgId === 0) {
          toggleSelectedOrgId(resp.data.options.defaultOrgId);
        }
        setOtpEnabled(resp.data.options.otpEnabled);
      }
      setUserId(resp.data.userId);

      // Prefetching before we are done "loading" auth so permissions are available during render
      await queryClient.prefetchQuery({
        queryKey: ['orgs', 'perms'],
        queryFn: async () => {
          const resp = await API.get("organizations/perms");
          return resp.data;
        }
      });

      // If we have an org (skip if we don't) pre-load the user meta
      if (selectedOrgId > 0) {
        await queryClient.prefetchQuery({
          queryKey: ['orgs', selectedOrgId, 'usermetadata'],
          queryFn: async () => {
            const resp = await API.get(`organizations/${selectedOrgId}/usermetadata`);
            return resp.data;
          }
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    checkAuth();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem('themeSettings', JSON.stringify(themeSettings));
  }, [themeSettings]);

  const verify2fa = async (authCode, onError) => {
    return verifyMutate.mutate({ otpToken: authCode }, {
      onSuccess: () => {
        setAuth((prevState) => {
          return ({
            ...prevState,
            otpVerified: true
          });
        });
        return "";
      },
      onError: (err) => {
        onError(err);
      }
    });
  };

  const { data: orgs } = useOrganizations(auth.loggedIn);
  const getOrgInfo = () => {
    return orgs.find(o => o.id === selectedOrgId) ?? [];
  }

  const { data: perms } = useOrganizationsPerms(auth.loggedIn);
  const hasPerm = (perm) => {
    if (perms) {
      return perms[selectedOrgId] >= userLevels[perm];
    }
    return false;
  };

  const { data: meta } = useUserMetaData(selectedOrgId, auth.loggedIn);
  const userMetaData = (opt) => {
    const data = meta.find(o => o.name === opt);
    return data ? JSON.parse(data.value) : data;
  };

  return (
    <AuthContext.Provider value={{
      login, logout, loading, auth, isLoggedIn, options, verify2fa,
      selectedOrgId, toggleSelectedOrgId, userId, otpEnabled, setOtpEnabled,
      getOrgInfo, checkAuth, hasPerm, userMetaData, themeSettings, setThemeSettings
    }}>
      {props.children}
    </AuthContext.Provider>
  );
}

import Box from '@mui/material/Box';

import { userLevels } from 'config';

function UserPermsInfo({ userlevel }) {
  return (
    <Box sx={{ mt: 2, mb: -2, fontSize: 12 }}>
      {userlevel === userLevels["User (Read Only)"] && (
        <>
          <div>Read only users can only view data and cannot edit or change settings.</div>
          <ul>
            <li>Access to view agents and groups</li>
            <li>No API key access</li>
          </ul>
        </>
      )}
      {userlevel === userLevels["User"] && (
        <>
          <div>Users are standard accounts that allow for management of agents, configs, and groups.</div>
          <ul>
            <li>Access to create, edit, and remove groups</li>
            <li>Access create, edit, and remove agents</li>
            <li>Access to create, edit, and remove configurations</li>
            <li>API key access (to add agents)</li>
          </ul>
        </>
      )}
      {userlevel === userLevels["Manager"] && (
        <>
          <div>Managers can do everything users do and also allows management of secrets, plugins, instances. They can view some organization data like users.</div>
          <ul>
            <li>Approve agents requesting access if auto-approve is not turned on</li>
            <li>Access to manage Nagios instances, plugins, and secrets</li>
            <li>Access to view users in organization</li>
          </ul>
        </>
      )}
      {userlevel === userLevels["Admin"] && (
        <>
          <div>Admins have full access to organizations and the organization settings and billing.</div>
          <ul>
            <li>Access to manage organization settings</li>
            <li>All access except for organizaiton deletion</li>
          </ul>
        </>
      )}
    </Box>
  );
}

export default UserPermsInfo;
import { useState } from 'react';
import { Helmet } from "react-helmet-async";

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { LoadingCircle, Page } from 'components/layout';
import { CustomIconButton } from "components/buttons";
import { usePendingAgents } from "hooks/organizations";
import { useAuth } from "context/auth";
import { formatLocalTime, ucFirst } from "utils/common";
import { useDeletePendingAgent, useActionPendingAgent } from "hooks/organizations";

function AgentsPending() {

  const [showRejected, setShowRejected] = useState(false);

  const { selectedOrgId } = useAuth();
  const mutateDelete = useDeletePendingAgent(selectedOrgId);
  const mutateAction = useActionPendingAgent(selectedOrgId);
  const { isLoading, data } = usePendingAgents(selectedOrgId);

  // Rejected agents won't show up in the main pending agents table and will not make the alert
  // appear on the dashboard and agents page - basically ignores the alert
  const filtered = showRejected ? data ?? [] : data?.filter(a => a.requestStatus === 0) ?? [];

  const handleApproveClick = (agentId) => {
    mutateAction.mutate({ agentId, action: "approve" });
  };

  const handleRejectClick = (agentId) => {
    mutateAction.mutate({ agentId, action: "reject" });
  };

  const handleDeleteClick = (agentId) => {
    mutateDelete.mutate(agentId);
  };

  const handleToggleRequests = (e) => {
    setShowRejected(e.target.checked);
  };

  if (isLoading) {
    return <LoadingCircle />;
  }

  return (
    <Page>
      <Helmet>
        <title>Agents Pending &middot; ReChecked Manager</title>
      </Helmet>
      <Grid container justifyContent="left" spacing={5}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Stack>
              <Typography variant="h4">Agents Pending</Typography>
              <Typography>When auto-approval is turned off, agents that request to be registered with this organization will appear below for manualy approval by managers and admins.</Typography>
            </Stack>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="secondary" checked={showRejected} onChange={handleToggleRequests} />}
                label="Show rejected requests"
              />
            </FormGroup>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <TableContainer variant="outlined" component={Card}>
          {filtered.length === 0 ? <Typography sx={{ m: 2 }}>No pending agent requests.</Typography> : (
            <Table aria-label="rcagent pending requests list table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    Actions
                  </TableCell>
                  <TableCell>
                    Agent Information
                  </TableCell>
                  <TableCell>
                    Operating System
                  </TableCell>
                  <TableCell>
                    Requested At
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {filtered.map(a => (
                <TableRow key={a.id}>
                  <TableCell padding="checkbox" sx={{ pl: 2, pr: 1, textAlign: 'center' }} colSpan={a.requestStatus === 0 ? 1 : 2}>
                    <Tooltip title="Approve" placement="top">
                      <IconButton color="success" onClick={() => handleApproveClick(a.id)}>
                        <CheckIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  {a.requestStatus === 0 && (
                    <TableCell padding="checkbox">
                      <Tooltip title="Reject" placement="top">
                        <IconButton color="error" onClick={() => handleRejectClick(a.id)}>
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {a.requestStatus > 0 && <Chip label="Rejected" size="small" color="error" />}
                      <Stack>
                        {a.hostname} ({a.address}) 
                        <Typography variant="body2" sx={{ fontSize: 10 }}>Machine ID: {a.machineId}</Typography>
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {ucFirst(a.os)}
                  </TableCell>
                  <TableCell>
                    {formatLocalTime(a.lastCheckin)}
                  </TableCell>
                  <TableCell padding="checkbox" sx={{ pr: 2 }}>
                    <CustomIconButton onClick={() => handleDeleteClick(a.id)} type="delete" />
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
            </Table>
          )}
          </TableContainer>
        </Grid>
      </Grid>
    </Page>
  );
}

export default AgentsPending;
import { useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useAuth } from 'context/auth';
import { useOrganizationUsers, useRemoveOrganizationUser } from 'hooks/organizations';
import { LoadingCircle } from 'components/layout';
import { ConfirmButton, CustomIconButton, InviteButton, EditUserButton } from 'components/buttons';
import { formatLocalTime, getUserLevel } from 'utils/common';
import { userLevels } from 'config';

function UsersTable({ org }) {

  const [search, setSearch] = useState('');

  const { userId, hasPerm } = useAuth();
  const { isLoading, data: users } = useOrganizationUsers(org.id);
  const remove = useRemoveOrganizationUser(org.id);

  const hasDeletePerms = (user) => {
    if (user.id === userId || user.userlevel === userLevels.Owner) {
      return false;
    }
    return hasPerm('Admin');
  };

  const hasEditPerms = (user) => {
    if (user.id === userId || user.userlevel === userLevels.Owner) {
      return false;
    }
    return hasPerm('Admin');
  };

  const getUserStatus = (user) => {
    if (user.id === userId) {
      return 'Active now';
    }
    if (user.registered === false) {
      return 'Invite sent';
    }
    if (user.lastLogin.Valid) {
      return formatLocalTime(user.lastLogin.Time);
    }
    return '-';
  };

  const removeUser = (userId) => {
    remove.mutate(userId);
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        {hasPerm('Admin') && <InviteButton disabled={org.users >= org.maxUsers} orgName={org.name} orgId={org.id} />}
        <TextField fullWidth size="small" label="Search users" value={search} onChange={(e) => setSearch(e.target.value)} />
        <Button variant="outlined" onClick={() => setSearch("")}>Clear</Button>
      </Stack>
      <TableContainer variant="outlined" component={Card}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>User Level</TableCell>
              <TableCell>Status / Last Login</TableCell>
              <TableCell colSpan={2}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={4}><LoadingCircle /></TableCell>
              </TableRow>
            ) : users && users.filter(o => o.email.toLowerCase().indexOf(search.toLowerCase()) > -1).map(user => (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{getUserLevel(user.userlevel)}</TableCell>
                <TableCell>{getUserStatus(user)}</TableCell>
                <TableCell padding="checkbox">
                  {hasEditPerms(user) && (<EditUserButton user={user} orgId={org.id} />)}
                </TableCell>
                <TableCell padding="checkbox">
                  {hasDeletePerms(user) && (
                    <ConfirmButton
                      onConfirm={() => removeUser(user.id)}
                      ButtonComponent={CustomIconButton}
                      title="Remove User"
                      description="Are you sure you want to remove this user from this organization?"
                      />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export default UsersTable;
import Grid from '@mui/material/Grid';

function FullScreen(props) {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid item>
        {props.children}
      </Grid>
    </Grid>
  );
}

export default FullScreen;
import { Helmet } from 'react-helmet-async';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Page, LoadingCircle } from 'components/layout';
import { useAuth } from 'context/auth';
import { useGroups } from 'hooks/organizations';
import { AddGroupButton } from 'components/buttons';
import { AgentGroup } from 'components/layout/groups';

function AgentGroups() {

  const { selectedOrgId, hasPerm } = useAuth();
  const { isLoading, data: groups } = useGroups(selectedOrgId);

  return (
    <Page>
      <Helmet>
        <title>Agent Groups &middot; ReChecked Manager</title>
      </Helmet>
      <Grid container justifyContent="left" spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={5}>
              <Typography variant="h4">Agent Groups</Typography>
            </Stack>
          </Stack>
          <Typography>Set up distributed passive checks by creating groups of agents that you can apply specific configurations to.</Typography>
        </Grid>
        {hasPerm('Manager') && (
          <Grid item xs={12} sx={{ mb: 4 }}>
            <AddGroupButton />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container justifyContent="left" spacing={5}>
            {isLoading ? (
              <Grid item xs={12}>
                <LoadingCircle />
              </Grid>
            ) : groups && groups.length > 0 ? groups.map(group => (
              <Grid key={group.id} item xs={12} md={6} xl={3}>
                <AgentGroup group={group} />
              </Grid>
            )) : (
              <Grid item xs={12}>
                <Typography>No groups created.</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}

export default AgentGroups;
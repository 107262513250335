import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Container from '@mui/material/Container';

import ResetPasswordForm from 'components/forms/ResetPasswordForm';

function ResetPasswordConfirm() {

  const { token } = useParams();
  
  return (
    <Container maxWidth="xs" sx={{ p: 4 }}>
      <Helmet>
        <title>Reset Password &middot; ReChecked Manager</title>
      </Helmet>
      <ResetPasswordForm token={token} />
    </Container>
  );
}

export default ResetPasswordConfirm;
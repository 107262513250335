import { useState } from 'react';
import { useForm, Controller } from "react-hook-form";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';

import { CustomIconButton } from 'components/buttons';
import { userLevels } from 'config';
import { useEditOrganizationUser } from 'hooks/organizations';
import { UserPermsInfo } from 'components/layout/organizations';

function EditUserButton({ user, orgId }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <CustomIconButton type="edit" onClick={() => setOpen(true)} />
      <EditUserModal user={user} open={open} setOpen={setOpen} orgId={orgId} />
    </>
  );
}

function EditUserModal({ user, open, setOpen, orgId }) {

  const [error, setError] = useState("");
  
  const edit = useEditOrganizationUser(orgId);
  const { control, formState: { errors }, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      userlevel: 4
    },
    values: {
      userlevel: user.userlevel
    }
  });

  const handleClose = () => {
    setOpen(false);
    setError("");
    setTimeout(reset, 200);
  };

  const onSubmit = (data) => {
    edit.mutate({
      userId: user.id,
      data: data
    }, {
      onSuccess: () => {
        handleClose();
      },
      onError: (err) => {
        if (err.response.data?.message && err.response.status !== 500) {
          setError(err.response.data.message);
        } else {
          setError("Could not edit user");
        }
      }
    });
  };

  const userlevel = watch("userlevel");

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ pb: 3 }}>
            Name: {user.name === "" ? "n/a" : user.name}<br />
            Email: <b>{user.email}</b>
          </DialogContentText>
          {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}
          <Stack spacing={2}>
            <Divider textAlign="left">Security Settings</Divider>
            <Controller
              name="userlevel"
              control={control}
              rules={{ required: "User level is required" }}
              render={({ field }) => (
                <TextField select {...field} size="small" label="User Level (Permissions)" variant="outlined" id="userlevel" required
                  helperText={errors.userlevel?.message} error={errors.userlevel !== undefined} fullWidth>
                  {Object.entries(userLevels).map(lvl => {
                    if (lvl[0] === 'Owner') { return null; }
                    return (
                      <MenuItem key={lvl[1]} value={parseInt(lvl[1])}>{lvl[0]}</MenuItem>
                    );
                  })}
                </TextField>
              )}
            />
          </Stack>
          <UserPermsInfo userlevel={userlevel} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color="secondary" onClick={handleSubmit}>Save Changes</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditUserButton;
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';

import { useAuth } from 'context/auth';
import { useGroups } from 'hooks/organizations';

function GroupSelect({ value, onChange }) {

  const { selectedOrgId } = useAuth();
  const { isLoading, data: groups } = useGroups(selectedOrgId);

  if (isLoading) {
    return <Skeleton height={40} variant="rounded" />;
  }

  return (
    <FormControl fullWidth>
      <InputLabel size="small" id="group-select-label">Group Filter</InputLabel>
      <Select
        size="small"
        labelId="group-select-label"
        id="group-select"
        label="Group Filter"
        value={value ?? ''}
        onChange={(e) => onChange && onChange(e.target.value)}
        disabled={groups.length === 0}
      >
        <MenuItem value=""><em>None</em></MenuItem>
        {groups && groups.map(group => (
          <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { GroupSelect };
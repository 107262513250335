import { useState } from 'react';

import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';

import CheckModal from 'components/modals/config/CheckModal';

function AddHostCheckButton() {

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button fullWidth color="secondary" onClick={() => setOpen(true)} startIcon={<AddIcon />}>Add Host</Button>
      <CheckModal type="host" open={open} setOpen={setOpen} />
    </>
  );
}

export default AddHostCheckButton;
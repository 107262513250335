import moment from 'moment';

import Tooltip from '@mui/material/Tooltip';

import SyncIcon from '@mui/icons-material/Sync';


function AgentSyncIcon({ agent }) {

  const updated = moment(agent.lastConfigUpdate);
  const change = moment(agent.lastConfigChange);

  if (updated.isBefore(change)) {
    return (
      <Tooltip title="Waiting for config sync" placement="top">
        <SyncIcon fontSize="small" color="action" />
      </Tooltip>
    );
  }
}

export default AgentSyncIcon;
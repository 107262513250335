import { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useForm, Controller } from "react-hook-form";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import { useVerifyEmail } from 'hooks/auth';
import { LoadingCircle } from 'components/layout';
import { ucFirst } from 'utils/common';

function VerifyEmail() {

  const { token } = useParams();
  const verify = useVerifyEmail();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      token: ""
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    verify.mutate(data, {
      onSuccess: () => {
        setSuccess(true);
      },
      onError: (err) => {
        if (err.response.status === 422) {
          setError(ucFirst(err.response.data.message));
        } else {
          setError("Could not submit password reset");
        }
      },
      onSettled: () => {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (token !== "" && token !== undefined) {
      onSubmit({ token: token });
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="xs" sx={{ p: 4 }}>
      <Helmet>
        <title>Verify Email &middot; ReChecked Manager</title>
      </Helmet>
      <Stack spacing={3}>
        <Typography variant="h4">Verify Email</Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {loading ? (
          <LoadingCircle />
        ) : (
          <>
            {success ? (
              <Typography>Email has been verified! You can now log in and use the manager.</Typography>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <Typography>In order to verify your email address, enter the code sent to your email.</Typography>
                  <Controller
                    name="token"
                    control={control}
                    rules={{ required: "A token must be entered" }}
                    render={({ field }) => <TextField {...field} label="Validation Token" variant="outlined" id="token"
                      autoFocus helperText={errors.token?.message} error={errors.token !== undefined} />}
                  />
                  <Button type="submit" color="secondary" variant="contained" size="large">Verify Email</Button>
                </Stack>
              </form>
            )}
            <Button type="link" component={RouterLink} to="/login">Back to Login</Button>
          </>
        )}
      </Stack>
    </Container>
  );
}

export default VerifyEmail;
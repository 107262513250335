import { useQuery, useMutation } from '@tanstack/react-query';
import { API } from 'utils/network';

const fetchAccount = async () => {
  const resp = await API.get("account");
  return resp.data;
};

const fetchOrganizations = async () => {
  const resp = await API.get("organizations");
  return resp.data;
};

const fetchOrganizationsPerms = async () => {
  const resp = await API.get("organizations/perms");
  return resp.data;
}

export function useAccount() {
  return useQuery({
    queryKey: ['account'],
    queryFn: () => fetchAccount()
  });
}

export function useEditAccount() {
  return useMutation({
    mutationFn: async (vars) => {
      const resp = await API.put("account", vars);
      return resp.data;
    },
  });
}

export function useOrganizations(enabled = true) {
  return useQuery({
    queryKey: ['orgs'],
    queryFn: () => fetchOrganizations(),
    initialData: [],
    enabled: enabled
  });
}

export function useOrganizationsPerms(enabled = true) {
  return useQuery({
    queryKey: ['orgs', 'perms'],
    queryFn: () => fetchOrganizationsPerms(),
    initialData: [],
    enabled: enabled
  });
}

export function useAddOrganization() {
  return useMutation({
    mutationFn: async (vars) => {
      const resp = await API.post("organizations/add", vars);
      return resp.data;
    }
  });
}

export function useChangePassword() {
  return useMutation({
    mutationFn: async (vars) => {
      const resp = await API.post("account/password", vars);
      return resp.data;
    }
  });
}

// 2FA Setup / Enable / Disable

const fetch2FAInfo = async () => {
  const resp = await API.get("account/otp");
  return resp.data;
};

export function use2FAInfo({ enabled }) {
  return useQuery({
    queryKey: ['2fa'],
    queryFn: () => fetch2FAInfo(),
    refetchOnWindowFocus: false,
    enabled: enabled ?? true
  })
}

export function useEnable2FA() {
  return useMutation({
    mutationFn: async (vars) => {
      const resp = await API.post("account/otp/enable", vars);
      return resp.data;
    }
  });
}

export function useDisable2FA() {
  return useMutation({
    mutationFn: async (vars) => {
      const resp = await API.post("account/otp/disable", vars);
      return resp.data;
    }
  });
}

// Settings

export function useSetDefaultOrgId() {
  return useMutation({
    mutationFn: (orgId) => {
      return API.put(`account/defaultOrg/${orgId}`);
    }
  });
}
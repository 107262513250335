import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function ConfirmModal({ title, text, open, setOpen, okText, cancelText, onConfirm }) {

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{cancelText ? cancelText : "Cancel"}</Button>
        <Button onClick={handleConfirm} color="error" autoFocus>
          {okText ? okText : "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
import { forwardRef } from 'react';

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ENDPOINTS } from 'config';

const EndpointSelect = forwardRef(function EndpointSelect(props, ref) {
  return (
    <TextField select size="small" label="Endpoint" {...props} ref={ref}>
    {ENDPOINTS.map(e => (
      <MenuItem key={e.name} value={e.name}>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Typography>{e.name}</Typography>
          <Typography variant="body2">{e.only ? e.only + ' only' : null}</Typography>
        </Stack>
      </MenuItem>
    ))}
    </TextField>
  );
});

export default EndpointSelect;
import { Helmet } from 'react-helmet-async';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';

import DownloadIcon from '@mui/icons-material/Download';

import { useAuth } from 'context/auth';
import { Page, LoadingCircle } from 'components/layout';
import { AddInstanceButton, EditInstanceButton, DeleteInstanceButton } from 'components/buttons/config';
import { useInstances } from 'hooks/organizations';
import { formatLocalTime } from 'utils/common';
import { API_URL } from 'config';

function Instances() {

  const { selectedOrgId } = useAuth();
  const { isLoading, data: instances } = useInstances(selectedOrgId);

  return (
    <Page>
      <Helmet>
        <title>Manage Nagios Instances &middot; ReChecked Manager</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Manage Nagios Instances</Typography>
          <Typography>Nagios instances are NRDP configurations to use in configs for sending passive checks to.</Typography>
        </Grid>
        <Grid item xs={12}>
          <AddInstanceButton />
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <LoadingCircle />
          ) : (
            <TableContainer variant="outlined" component={Card}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Instance Name</TableCell>
                    <TableCell>NRDP URL</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell>Nagios Core Configs</TableCell>
                    <TableCell colSpan={2}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instances && instances.map((instance) => (
                    <TableRow
                      key={instance.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="instance">
                        {instance.name}
                      </TableCell>
                      <TableCell>
                        {instance.url}
                      </TableCell>
                      <TableCell>
                        {formatLocalTime(instance.updated)}
                      </TableCell>
                      <TableCell>
                        <Button startIcon={<DownloadIcon />} size="small"
                          onClick={() => window.location.href = API_URL+`organizations/${selectedOrgId}/instances/${instance.id}/configs/download`}
                        >
                          Download Files
                        </Button>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <EditInstanceButton instance={instance} />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <DeleteInstanceButton instanceId={instance.id} />
                      </TableCell>
                    </TableRow>
                  ))}
                  {instances && instances.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={4}>No instances exist for this account yet.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default Instances;
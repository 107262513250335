
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { ChecksTable } from 'components/layout/config';
import { AddServiceCheckButton } from 'components/buttons/config';

function ServiceChecksDrawer({ hostname, edit, open, setOpen }) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box sx={{ width: '100vw', maxWidth: 720, mt: 8, p: 4 }}>
        <Stack sx={{ height: 'calc(100vh - 130px)' }} justifyContent="space-between">
          <Stack spacing={2} sx={{ mb: 2 }}>
            <Stack spacing={1}>
              <Typography variant="h4">Service Checks</Typography>
              <Typography variant="h5">{hostname}</Typography>
            </Stack>
            <ChecksTable type="services" host={hostname} />
            <AddServiceCheckButton host={hostname} />
          </Stack>
          <Stack spacing={2}>
            <Button variant="outlined" onClick={handleClose}>Close</Button>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default ServiceChecksDrawer;
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { useQueryParams, StringParam } from 'use-query-params';
import { Helmet } from 'react-helmet-async';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';

import { useRegister } from 'hooks/auth';
import { DEVELOPMENT } from 'config';
import { ucFirst } from 'utils/common';

function Register() {

  const [query] = useQueryParams({
    code: StringParam
  });

  const register = useRegister();
  const { control, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      confPassword: "",
      terms: false
    },
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const onSubmit = (data) => {
    register.mutate(data, {
      onSuccess: () => {
        // TODO: Add message to the screen to validate (email needs validation)
        setSuccess(true);
      },
      onError: (err) => {
        if (err.response.status === 422) {
          setError(ucFirst(err.response.data.message));
        } else {
          setError('Could not create account');
        }
      }
    })
  };

  const helmet = (
  <Helmet>
    <title>Regiter &middot; ReChecked Manager</title>
  </Helmet>
  );

  // Remove when in production once released
  if (DEVELOPMENT && query.code !== "DevRegister5995") {
    return (
      <Container maxWidth="xs" sx={{ p: 4, textAlign: 'center' }}>
        {helmet}
        <Stack spacing={4}>
          <Typography>This environment is for developers only, you cannot register a new account.</Typography>
          <Button type="link" component={RouterLink} to="/login">Back to Login</Button>
        </Stack>
      </Container>
    );
  }

  if (success) {
    return (
      <Container maxWidth="xs" sx={{ p: 4 }}>
        {helmet}
        <Stack spacing={3}>
          <Typography variant="h4">Create Account</Typography>
          <Typography>Your account has been created!</Typography>
          <Typography>
            We've sent the email you used to register an email verification link. Please check your email for the link to verify your email and finish account setup.
          </Typography>
          <Button type="link" component={RouterLink} to="/login">Back to Login</Button>
        </Stack>
      </Container>
    );
  }

  return (
    <Container maxWidth="xs" sx={{ p: 4 }}>
      {helmet}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Typography variant="h4">Create Account</Typography>
          <Typography>Create a new ReChecked account. When finished, you can log into your account and create an organization.</Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <Controller
            name="name"
            control={control}
            rules={{ required: "Name is required" }}
            render={({ field }) => <TextField {...field} label="Full Name" variant="outlined" id="name"
              autoFocus helperText={errors.name?.message} error={errors.name !== undefined} />}
          />
          <Controller
            name="email"
            control={control}
            rules={{ required: "Valid email address is required" }}
            render={({ field }) => <TextField {...field} label="Email Address" variant="outlined" id="email"
              helperText={errors.email?.message} error={errors.email !== undefined} />}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: "Password is required (requirements: must be 8+ characters and have at least 1 number and special character)" }}
            render={({ field }) => <TextField {...field} label="Password" variant="outlined" id="password"
              type="password" helperText={errors.password?.message} error={errors.password !== undefined} />}
          />
          <Controller
            name="confPassword"
            control={control}
            rules={{ required: "Confirm password is required" }}
            render={({ field }) => <TextField {...field} label="Confirm Password" variant="outlined" id="confPassword"
              type="password" helperText={errors.confPassword?.message} error={errors.confPassword !== undefined} />}
          />
          <Controller
            name="terms"
            control={control}
            rules={{ required: "You must accept the Terms of Use" }}
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={(
                  <Typography variant="body1">I agree to the <Link href="https://rechecked.io/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</Link></Typography>
                )} />
                {errors.terms?.message && (<Typography variant="body2" color="error">{errors.terms.message}</Typography>)}
              </FormGroup>
            )}
          />
          <Button type="submit" size="large" color="secondary" variant="contained">Create</Button>
          <Button type="link" component={RouterLink} to="/login">Back</Button>
        </Stack>
      </form>
    </Container>
  );
}

export default Register;
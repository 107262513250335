import { useState } from 'react';
import copy from 'copy-to-clipboard';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import KeyIcon from '@mui/icons-material/Key';

import { useOrganizationAPIKey } from 'hooks/organizations';

function APIKeyViewer({ orgId }) {

  const [text, setText] = useState('Copy');
  const [enabled, setEnabled] = useState(false);
  const { isLoading, data } = useOrganizationAPIKey(enabled, orgId);

  const handleGetAPIKey = () => {
    setEnabled(true);
  };

  const handleCopy = () => {
    copy(data?.apikey);
    setText('Copied!');
    setTimeout(() => setText('Copy'), 600);
  };

  return (
    <Box>
      {!enabled || isLoading ? <Button fullWidth variant="outlined" color="secondary" sx={{ height: 40 }} startIcon={<KeyIcon />} onClick={handleGetAPIKey}>Show API Key</Button> :
      (
        <Stack direction="row" spacing={2}>
          <TextField
            label="API Key"
            size="small"
            value={data?.apikey}
            fullWidth
            readOnly
          />
          <Button onClick={handleCopy} sx={{ width: 90 }} variant="outlined" color="secondary">{text}</Button>
        </Stack>
      )}
    </Box>
  );
}

export { APIKeyViewer };
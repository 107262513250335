import { useState } from 'react';

import { useAuth } from 'context/auth';
import { useDeleteConfig } from 'hooks/organizations';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CustomIconButton } from 'components/buttons';

function DeleteConfigButton({ configId }) {

  const { selectedOrgId } = useAuth();
  const mutateDelete = useDeleteConfig(selectedOrgId);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleDelete = () => {
    mutateDelete.mutate(configId);
  };

  return (
    <>
      <CustomIconButton onClick={() => setConfirmOpen(true)} type="delete" />
      <ConfirmModal
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title="Confirm Delete Config"
        text="Are you sure you want to delete this config? Any agents running these checks will remove them on the next update/sync."
        okText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => handleDelete()}
      />
    </>
  );
}

export default DeleteConfigButton;
import { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import LoadingButton from '@mui/lab/LoadingButton';

import { useAuth } from 'context/auth';
import { useOrganizations, useAddOrganization } from 'hooks/account';
import { PRODUCTION } from 'config';
import { formatMoneyValue } from 'utils/common';

const SUBSCRIPTIONS = {
  2: {
    id: 2,
    monthlyCost: 79
  },
  3: {
    id: 3,
    monthlyCost: 149
  },
  4: {
    id: 4,
    monthlyCost: 399
  }
};

const SUPPORT = {
  unlimited: {
    id: 1,
    monthlyCost: 299
  },
  paygo: {
    id: 2,
    singleCost: 1995
  }
};

function AddOrgForm({ hideHeader }) {

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { selectedOrgId, toggleSelectedOrgId } = useAuth();
  const { data: organizations } = useOrganizations();
  const addMutation = useAddOrganization();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [type, setType] = useState('');
  const [typeError, setTypeError] = useState('');

  const [subscription, setSubscription] = useState(2);
  const [support, setSupport] = useState('');
  const [total, setTotal] = useState(SUBSCRIPTIONS[2].monthlyCost);
  const [totalSingleCost, setTotalSingleCost] = useState(0);
  const [yearly, setYearly] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate
    let errors = false;
    if (name === "") {
      setNameError("You must set an organization name.");
      errors = true;
    }
    if (type === "") {
      setTypeError("You must select an organization type.");
      errors = true;
    }
    if (errors) {
      return;
    }

    // Send request
    setLoading(true);
    addMutation.mutate({
      name: name,
      type: type,
      subId: subscription,
      supportId: support ? SUPPORT[support].id : 0,
      setDefault: selectedOrgId === 0 ? true : false,
      period: yearly ? 'yearly' : 'monthly'
    }, {
      onSuccess: (data) => {
        if (data.orgId !== 0) {
          toggleSelectedOrgId(data.orgId);
          queryClient.invalidateQueries(['orgs']);
        }
        if (data.type !== "personal") {
          navigate("/account/organizations/payment");
        } else {
          navigate("/dashboard");
        }
      },
      onError: (data) => {
        // TODO: Do something when error happens
      },
      onSettled: () => {
        setLoading(false);
      }
    });

  };

  const handleSubChange = (e) => {
    setSubscription(e.target.value);
  };

  useEffect(() => {
    const calculateTotal = () => {
      let totalMonthly = 0;
      let totalSingleCost = 0;

      totalMonthly += SUBSCRIPTIONS[subscription].monthlyCost;
      if (support !== "") {
        totalMonthly += SUPPORT[support].monthlyCost ?? 0;
        totalSingleCost += SUPPORT[support].singleCost ?? 0;
      }

      // Apply yearly discount (10%)
      if (yearly) {
        totalMonthly = (totalMonthly * 12) * 0.9;
        totalSingleCost = totalSingleCost * 0.9;
      }

      setTotal(parseFloat(totalMonthly.toFixed(2)));
      setTotalSingleCost(parseFloat(totalSingleCost.toFixed(2)));
    }

    calculateTotal();
  }, [subscription, support, yearly]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container maxWidth="md" spacing={4}>
        {!hideHeader && (
          <Grid item xs={12}>
            <Typography variant="h2">Add Organization</Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <TextField
            id="organization-name"
            label="Organization Name"
            variant="outlined"
            autoFocus
            fullWidth
            required
            error={nameError !== ""}
            helperText={nameError}
            value={name}
            onChange={(e) => { setName(e.target.value); setNameError(""); }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="organization-type"
            select
            fullWidth
            required
            error={typeError !== ""}
            helperText={typeError}
            label="Organization Type"
            value={type}
            onChange={(e) => { setType(e.target.value); setTypeError(""); }}
          >
            {organizations && organizations.filter(o => o.type === "personal").length === 0 && (
              <MenuItem value="personal">
                Personal
              </MenuItem>
            )}
            <MenuItem value="business">
              Business
            </MenuItem>
          </TextField>
        </Grid>
        {type === 'personal' && (
          <Grid item xs={12}>
            <Alert severity="info">
              Personal organizations are always free but are limited to 10 agents. You can only have one personal organization and you cannot invite other users to it.
            </Alert>
          </Grid>
        )}
        {type === 'business' && (
          <Grid item xs={12}>
            <Stack spacing={3}>
              <TextField
                id="organization-subscription"
                select
                fullWidth
                required
                label="Organization Subscription"
                value={subscription}
                onChange={(e) => handleSubChange(e)}
              >
                <MenuItem value={2}>
                  STANDARD - Up to 10 users, 200 managed agents - <b>$79/month</b>
                </MenuItem>
                <MenuItem value={3}>
                  PROFESSIONAL - Up to 20 users, 400 managed agents - <b>$149/month</b>
                </MenuItem>
                <MenuItem value={4}>
                  ENTERPRISE - Unlimited users, unlimited managed agents - <b>$399/month</b>
                </MenuItem>
              </TextField>
              <Stack spacing={2}>
                <Typography variant="h6">Support Options</Typography>
                <Typography variant="body2">
                  Need support? We provide 1-on-1 email based support plans that covers rcmanager and any rcagents that your organization uses. We guarantee a response within 24 business hours. See more information about what is and isn't covered.
                </Typography>
                <RadioGroup
                  name="support-plan-select"
                  value={support}
                  onChange={(e) => setSupport(e.target.value)}
                >
                  <FormControlLabel control={<Radio />} value="" label="No support plan" />
                  <FormControlLabel control={<Radio />} value="unlimited" label="Unlimited ticket support for $299/month" />
                  <FormControlLabel control={<Radio />} value="paygo" label="Pay as you go - 10 support tickets for one time cost of $1995" />
                </RadioGroup>
              </Stack>
              <Box>
                <Stack spacing={2}>
                  <Typography variant="h6">Total Cost</Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography sx={{ fontSize: 30 }}>
                      {totalSingleCost > 0 ? '$ ' + formatMoneyValue(totalSingleCost) + ' + ' : ''}
                      $ {formatMoneyValue(total)}
                    </Typography>
                    <Typography sx={{ fontSize: 20 }}>
                       per {yearly ? 'year' : 'month'}
                    </Typography>
                  </Stack>
                  {totalSingleCost > 0 && (<Typography variant="body2">$ {formatMoneyValue(totalSingleCost + total)} due today</Typography>)}
                </Stack>
              </Box>
              <Box>
                <FormGroup>
                  <FormControlLabel control={<Switch checked={yearly} onChange={(e) => setYearly(e.target.checked)} />} label="Pay annually and save 10%" />
                </FormGroup>
              </Box>
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <LoadingButton loading={loading} disabled={type === 'business' && PRODUCTION} type="submit" size="large" color="secondary" variant="contained">
            {type === 'business' ? 'Create & Pay Now' : 'Create'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default AddOrgForm;
import { CustomIconButton } from 'components/buttons';
import { useDownloadPlugin } from 'hooks/organizations';

function DownloadPluginButton({ orgId, pluginId, name }) {

  const { refetch } = useDownloadPlugin(orgId, pluginId, name);

  const handleDownload = () => {
    refetch();
  };

  return (
    <CustomIconButton onClick={() => handleDownload()} type="download" />
  );
}

export default DownloadPluginButton;
import { useState } from 'react';
import copy from 'copy-to-clipboard';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import ShieldIcon from '@mui/icons-material/Shield';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';

import { useOrganizationCACert } from 'hooks/organizations';

function ShowCACertButton({ orgId }) {

  const [text, setText] = useState('Copy');
  const [open, setOpen] = useState(false);

  const { refetch, data: caCert } = useOrganizationCACert(orgId);

  // TODO: Verify password before showing the CA certificate
  const handleCACert = () => {
    refetch();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopy = () => {
    copy(caCert);
    setText('Copied!');
    setTimeout(() => setText('Copy'), 600);
  };

  const handleDownload = () => {
    const el = document.createElement("a");
    const file = new Blob([caCert], { type: "text/plain;charset=utf-8" });
    el.href = URL.createObjectURL(file);
    el.download = "rcmanager-org-ca.crt";
    el.click();
    el.remove();
  };

  return (
    <>
      <Button sx={{ height: 40 }} onClick={handleCACert} startIcon={<ShieldIcon />} color="secondary" fullWidth variant="outlined">Show CA Certificate</Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Organization CA Certificate</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>This CA certificate is used to sign certificates for agents in your organization when they connect and register to your organizaiton. You can add this CA to your trusted CA certificates to ensure connections are secure.</Typography>
            <Stack direction="row" spacing={2}>
              <Button onClick={handleDownload} variant="outlined" startIcon={<DownloadIcon />}>Download</Button>
              <Button onClick={handleCopy} color="secondary" variant="outlined" startIcon={<ContentCopyIcon />}>{text}</Button>
            </Stack>
            <FormControl fullWidth>
              <OutlinedInput
                multiline
                rows={12}
                sx={{ fontFamily: 'Monospace' }}
                value={caCert}
                readOnly
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { ShowCACertButton };
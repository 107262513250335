import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DevicesIcon from '@mui/icons-material/Devices';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import Footer from './Footer';

function PublicContent(props) {
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'));
  const tooShort = useMediaQuery('(max-height:800px)');
  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: isScreenMd ? '100vh' : 'auto' }}>
      <Grid item xs={12} md={6} sx={{ display: 'flex', minHeight: isScreenMd ? '100vh' : 'auto', bgcolor: '#222', color: theme.palette.primary.contrastText, p: 4 }}>
        <Stack sx={{ flex: 1 }} justifyContent="space-between" spacing={4}>
          <Stack spacing={8} sx={{ p: isScreenMd ? tooShort ? 6 : 12 : 0 }}>
            <Typography variant={isScreenMd ? tooShort ? 'h2' : 'h1' : 'h4'}>ReChecked Manager</Typography>
            {isScreenMd && (
              <>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <VerifiedUserIcon fontSize="large" />
                    </ListItemAvatar>
                    <ListItemText primary="Secure your monitoring agent connections" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <DevicesIcon fontSize="large" />
                    </ListItemAvatar>
                    <ListItemText primary="View, manage, and configure all your monitoring agents from a single location" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <PeopleAltIcon fontSize="large" />
                    </ListItemAvatar>
                    <ListItemText primary="Organizational access allows you to invite users to manage your agents" />
                  </ListItem>
                </List>
                <Box>
                  <Button size="large" color="inherit" variant="outlined" href="https://rechecked.io/rechecked-manager">More Information</Button>
                </Box>
              </>
            )}
          </Stack>
          {isScreenMd && (
            <Box sx={{ color: theme.palette.grey[600] }}>
              <Footer />
            </Box>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        {props.children}
      </Grid>
    </Grid>
  );
}

export default PublicContent;
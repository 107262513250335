import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import BusinessIcon from '@mui/icons-material/Business';
import PortraitIcon from '@mui/icons-material/Portrait';

import { useAuth } from 'context/auth';
import { useOrganizations } from 'hooks/account';

function CurrentOrgSelect() {

  const [open, setOpen] = useState(false);
  const [orgName, setOrgName] = useState("...");

  const navigate = useNavigate();

  const { selectedOrgId, toggleSelectedOrgId } = useAuth();
  const { isLoading, data: organizations } = useOrganizations();

  const handleClose = (newSelectedOrgId) => {
    setOpen(false);

    if (newSelectedOrgId) {
      toggleSelectedOrgId(parseInt(newSelectedOrgId));
      navigate("");
    }
  };

  const handleChangeOrgClick = () => {
    setOpen(true);
  };

  // Org name since we don't pass the name of the org in the first call
  // TODO: Add org name into first call so we can skip this
  useEffect(() => {
    const org = organizations.filter(o => o.id === selectedOrgId);
    if (org.length === 1) {
      setOrgName(org[0].name);
    }
  }, [organizations, selectedOrgId]);

  return (
    <Box>
      {isLoading ? <Skeleton variant="square" height={88} /> : (
        <>
          <List component="div" role="group">
            <ListItem
              button
              aria-haspopup="true"
              aria-controls="org-select-menu"
              aria-label="organization select menu"
              onClick={handleChangeOrgClick}
            >
              <ListItemText primary="Organization" primaryTypographyProps={{ variant: "body2" }} secondary={orgName} />
            </ListItem>
          </List>
          <SelectOrgDialog open={open} onClose={handleClose} value={selectedOrgId} />
        </>
      )}
    </Box>
  );
}

function SelectOrgDialog({ open, onClose, value: valueProp }) {

  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef(null);

  const { data: organizations } = useOrganizations();
  
  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog open={open} maxWidth="xs" onClose={handleCancel}
      TransitionProps={{ onEntering: handleEntering }}
    >
      <DialogTitle>Change To Organization</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {organizations && organizations.length > 0 && organizations.map((org) => (
            <FormControlLabel
              value={org.id}
              key={org.id}
              control={<Radio />}
              label={(
                <Stack direction="row" spacing={1}>
                  {org.type === 'business' ? <BusinessIcon /> : <PortraitIcon />}
                  <Box>{org.name}</Box>
                </Stack>
              )}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleOk}>Change</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CurrentOrgSelect;
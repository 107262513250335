import { useState } from 'react';
import copy from 'copy-to-clipboard';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useAuth } from 'context/auth';

function CodeBox({ canCopy, children }) {

  const { themeSettings } = useAuth();

  const [copied, setCopied] = useState(false);

  const copyContent = () => {
    copy(children);
    setCopied(true);
    setTimeout(() => setCopied(false), 600);
  };

  return (
    <Box sx={{ bgcolor: themeSettings.mode === 'dark' ? 'grey.900' : 'grey.100', borderRadius: 2, position: "relative" }}>
      {canCopy && (
        <Tooltip title={copied ? 'Copied!' : 'Copy'} placement="top">
          <IconButton aria-label="delete" onClick={copyContent} size="small" sx={{ position: "absolute", top: 10, right: 10 }}>
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
      <Box sx={{ p: 2, fontFamily: "monospace", whiteSpace: "pre", overflow: "auto" }}>
        {children}
      </Box>
    </Box>
  );
}

export default CodeBox;
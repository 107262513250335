import { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useAuth } from 'context/auth';
import { useAgents } from 'hooks/organizations';

function AgentsSelect({ required, label, value, onChange }) {

  const [currValue, setCurrValue] = useState(value);

  const { selectedOrgId } = useAuth();
  const { data: agents } = useAgents(selectedOrgId);

  useEffect(() => {
    if (Array.isArray(value)) {
      setCurrValue(value);
    }
  }, [value]);

  return (
    <Autocomplete
      multiple
      size="small"
      options={agents ? agents : []}
      getOptionLabel={(option) => option.name !== "" ? option.name : option.hostname}
      filterSelectedOptions
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      onChange={(_, values) => onChange(values)}
      value={currValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label + ' *'}
          placeholder="Search agents"
          onChange={onChange}
        />
      )}
    />
  );
}

export default AgentsSelect;
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'utils/network';
import { isEmpty } from 'utils/common';

const fetchOrganizationAPIKey = async (orgId) => {
  const resp = await API.get(`organizations/${orgId}/apikey`);
  return resp.data;
};

const fetchOrganizationCACert = async (orgId) => {
  const resp = await API.get(`organizations/${orgId}/ca`);
  return resp.data;
};

const fetchOrganizationUsers = async (orgId) => {
  const response = await API.get(`organizations/${orgId}/users`);
  return response.data;
};

const fetchAgents = async (orgId, query) => {
  const response = await API.get(`organizations/${orgId}/agents`, query);
  return response.data;
};

const fetchPendingAgents = async (orgId) => {
  const response = await API.get(`organizations/${orgId}/agents/pending`);
  return response.data;
};

const fetchAgentsPendingAlert = async (orgId) => {
  const response = await API.get(`organizations/${orgId}/agents/pending/alert`);
  return response.data;
}

const fetchAgentConfigs = async (orgId, agentId) => {
  const response = await API.get(`organizations/${orgId}/agents/${agentId}/configs`);
  return response.data;
};

const fetchPlugins = async (orgId) => {
  const resp = await API.get(`organizations/${orgId}/plugins`);
  return resp.data;
}

const fetchPluginURL = async (orgId, pluginId) => {
  const response = await API.get(`organizations/${orgId}/plugins/${pluginId}/download`);
  return response.data;
};

export function useOrganizationAPIKey(enabled, orgId) {
  return useQuery({
    queryKey: ['orgs', orgId, 'apikey'],
    queryFn: () => fetchOrganizationAPIKey(orgId),
    refetchOnWindowFocus: false,
    enabled: enabled
  })
}

export function useOrganizationCACert(orgId) {
  return useQuery({
    queryKey: ['orgs', orgId, 'caCert'],
    queryFn: () => fetchOrganizationCACert(orgId),
    refetchOnWindowFocus: false,
    enabled: false
  });
}

export function useAgents(orgId, query, enabled = true) {
  return useQuery({
    queryKey: ['orgs', orgId, 'agents', query],
    queryFn: () => fetchAgents(orgId, query),
    enabled: enabled
  });
}

export function usePendingAgents(orgId, enabled = true) {
  return useQuery({
    queryKey: ['orgs', orgId, 'agentsPending'],
    queryFn: () => fetchPendingAgents(orgId),
    enabled: enabled
  });
}

export function usePendingAgentsAlert(orgId) {
  return useQuery({
    queryKey: ['orgs', orgId, 'agentsPendingAlert'],
    queryFn: () => fetchAgentsPendingAlert(orgId)
  });
}

export function useAgentConfigs(orgId, agentId) {
  return useQuery({
    queryKey: ['orgs', orgId, 'agents', 'configs', agentId],
    queryFn: () => fetchAgentConfigs(orgId, agentId)
  });
}

export function useDeleteAgent(orgId, query) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (agentId) => {
      return API.delete(`organizations/${orgId}/agents/${agentId}`);
    },
    onMutate: async (agentId) => {
      await queryClient.cancelQueries({ queryKey: ['orgs', orgId, 'agents', query] });
      const previousData = queryClient.getQueryData(['orgs', orgId, 'agents', query]);
      queryClient.setQueryData(['orgs', orgId, 'agents', query], previousData.filter(a => a.id !== agentId));
      return { previousData };
    },
    onError: (err, agentId, context) => {
      queryClient.setQueryData(['orgs', orgId, 'agents', query], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'agents', query] });
    }
  });
};

export function useDeletePendingAgent(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (agentId) => {
      return API.delete(`organizations/${orgId}/agents/${agentId}`);
    },
    onMutate: async (agentId) => {
      await queryClient.cancelQueries({ queryKey: ['orgs', orgId, 'agentsPending'] });
      const previousData = queryClient.getQueryData(['orgs', orgId, 'agentsPending']);
      queryClient.setQueryData(['orgs', orgId, 'agentsPending'], previousData.filter(a => a.id !== agentId));
      return { previousData };
    },
    onError: (err, agentId, context) => {
      queryClient.setQueryData(['orgs', orgId, 'agentsPending'], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'agentsPending'] });
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'agentsPendingAlert'] });
    }
  });
}

export function useActionPendingAgent(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ agentId, action }) => {
      return API.put(`organizations/${orgId}/agents/pending/${agentId}/${action}`);
    },
    onMutate: async ({ agentId, action }) => {
      await queryClient.cancelQueries({ queryKey: ['orgs', orgId, 'agentsPending'] });
      const previousData = queryClient.getQueryData(['orgs', orgId, 'agentsPending']);

      let data = [];
      if (action === "approve") {
        data = previousData.filter(a => a.id !== agentId);
      } else {
        data = previousData.map(a => {
          if (a.id === agentId) {
            a.requestStatus = 1;
          }
          return a;
        });
      }

      queryClient.setQueryData(['orgs', orgId, 'agentsPending'], data);
      return { previousData };
    },
    onError: (err, agentId, context) => {
      queryClient.setQueryData(['orgs', orgId, 'agentsPending'], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'agentsPending'] });
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'agentsPendingAlert'] });
    }
  });
}

export function useEditAgent(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ agentId, data }) => {
      return API.put(`organizations/${orgId}/agents/${agentId}`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'agents'] });
    }
  });
}

// PLUGINS

export function usePlugins(orgId) {
  return useQuery({
    queryKey: ['orgs', orgId, 'plugins'],
    queryFn: () => fetchPlugins(orgId)
  });
}

export function useDownloadPlugin(orgId, pluginId, name) {
  return useQuery({
    queryKey: ['orgs', orgId, pluginId],
    queryFn: () => fetchPluginURL(orgId, pluginId),
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (data) => {
      window.location.href = data.url;
    }
  })
}

export function useDeletePluginMutation(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (pluginId) => {
      return API.delete(`organizations/${orgId}/plugins/${pluginId}`);
    },
    onMutate: async (pluginId) => {
      await queryClient.cancelQueries({ queryKey: ['orgs', orgId, 'plugins'] });
      const previousData = queryClient.getQueryData(['orgs', orgId, 'plugins']);
      queryClient.setQueryData(['orgs', orgId, 'plugins'], previousData.filter(o => o.id !== pluginId));
      return { previousData };
    },
    onError: (err, pluginId, context) => {
      queryClient.setQueryData(['orgs', orgId, 'plugins'], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'plugins'] });
    }
  });
}

// RECENT AGENTS

const fetchRecentAgents = async (orgId) => {
  const response = await API.get(`organizations/${orgId}/agents/recent`);
  return response.data;
};

export function useRecentAgents(orgId) {
  return useQuery({
    queryKey: ['orgs', orgId, 'agents', 'recent'],
    queryFn: () => fetchRecentAgents(orgId)
  });
}

export function useClearRecentAgents(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return API.delete(`organizations/${orgId}/agents/recent`);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['orgs', orgId, 'agents', 'recent'] });
      const previousData = queryClient.getQueryData(['orgs', orgId, 'agents', 'recent']);
      queryClient.setQueryData(['orgs', orgId, 'agents', 'recent'], []);
      return { previousData };
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(['orgs', orgId, 'agents', 'recent'], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'agents', 'recent'] });
    }
  });
}

export function useClearRecentAgent(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (agentId) => {
      return API.delete(`organizations/${orgId}/agents/recent/${agentId}`);
    },
    onMutate: async (agentId) => {
      await queryClient.cancelQueries({ queryKey: ['orgs', orgId, 'agents', 'recent'] });
      const previousData = queryClient.getQueryData(['orgs', orgId, 'agents', 'recent']);
      queryClient.setQueryData(['orgs', orgId, 'agents', 'recent'], previousData.filter(o => o.agentId !== agentId));
      return { previousData };
    },
    onError: (err, agentId, context) => {
      queryClient.setQueryData(['orgs', orgId, 'agents', 'recent'], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'agents', 'recent'] });
    }
  });
}

// GROUPS

const fetchGroups = async (orgId) => {
  const resp = await API.get(`organizations/${orgId}/groups`);
  return resp.data;
};

const fetchGroup = async (orgId, groupId) => {
  const resp = await API.get(`organizations/${orgId}/groups/${groupId}`);
  return resp.data;
}

const fetchAllGroup = async (orgId) => {
  const resp = await API.get(`organizations/${orgId}/agents/status`);
  return resp.data;
};

export function useGroups(orgId) {
  return useQuery({
    queryKey: ['orgs', orgId, 'groups'],
    queryFn: () => fetchGroups(orgId)
  });
}

export function useGroup(orgId, groupId, open) {
  return useQuery({
    queryKey: ['orgs', orgId, 'groups', groupId],
    queryFn: () => fetchGroup(orgId, groupId),
    enabled: open === true && Number.isInteger(groupId)
  });
}

export function useAllGroup(orgId) {
  return useQuery({
    queryKey: ['orgs', orgId, 'allgroup'],
    queryFn: () => fetchAllGroup(orgId)
  })
}

export function useAddGroup(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return API.post(`organizations/${orgId}/groups`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'groups'] });
    }
  });
}

export function useEditGroup(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ groupId, data }) => {
      return API.put(`organizations/${orgId}/groups/${groupId}`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'groups'] });
    }
  });
}

export function useDeleteGroup(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (groupId) => {
      return API.delete(`organizations/${orgId}/groups/${groupId}`);
    },
    onMutate: async (groupId) => {
      await queryClient.cancelQueries({ queryKey: ['orgs', orgId, 'groups'] });
      const previousData = queryClient.getQueryData(['orgs', orgId, 'groups']);
      queryClient.setQueryData(['orgs', orgId, 'groups'], previousData.filter(o => o.id !== groupId));
      return { previousData };
    },
    onError: (err, pluginId, context) => {
      queryClient.setQueryData(['orgs', orgId, 'groups'], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'groups'] });
    }
  });
}

// USERS

export function useOrganizationUsers(orgId) {
  return useQuery({
    queryKey: ['orgs', orgId, 'users'],
    queryFn: () => fetchOrganizationUsers(orgId)
  });
}

export function useInviteOrganizationUsers(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return API.post(`organizations/${orgId}/invite`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'users'] });
    }
  });
}

export function useEditOrganizationUser(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId, data }) => {
      return API.put(`organizations/${orgId}/users/${userId}`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'users'] });
    }
  });
}

export function useRemoveOrganizationUser(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userId) => {
      return API.delete(`organizations/${orgId}/users/${userId}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'users'] });
    }
  });
}

// BILLING

const fetchOrganizationSubscription = async (orgId) => {
  const response = await API.get(`organizations/${orgId}/subscription`);
  return response.data;
};

export function useOrganizationSubscription(orgId) {
  return useQuery({
    queryKey: ['orgs', orgId, 'subscription'],
    queryFn: () => fetchOrganizationSubscription(orgId)
  });
}

export function useOrganizationSubscriptionCancellation(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return API.put(`organizations/${orgId}/subscription/cancel`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'subscription'] });
    }
  });
}

// ADMIN

export function useOrganizationRegenerateAPIKey(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return API.put(`organizations/${orgId}/regenerate/apikey`, data);
    },
    onSettled: () => {
      queryClient.removeQueries({ queryKey: ['orgs', orgId, 'apikey'], exact: true });
    }
  });
}

export function useOrganizationRegenerateCACert(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return API.put(`organizations/${orgId}/regenerate/cacert`, data);
    },
    onSettled: () => {
      queryClient.removeQueries({ queryKey: ['orgs', orgId, 'caCert'], exact: true });
    }
  });
}

// SETTINGS

const fetchOrganizationSettings = async (orgId) => {
  const response = await API.get(`organizations/${orgId}/settings`);
  return response.data;
};

export function useOrganizationSettings(orgId) {
  return useQuery({
    queryKey: ['orgs', orgId, 'settings'],
    queryFn: () => fetchOrganizationSettings(orgId)
  });
}

export function useUpdateOrganizationSettings(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return API.put(`organizations/${orgId}/settings`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'settings'] });
    }
  });
}

// INSTANCES

const fetchOrganizationInstances = async (orgId) => {
  const response = await API.get(`organizations/${orgId}/instances`);
  return response.data;
};

export function useInstances(orgId, enabled = true) {
  return useQuery({
    queryKey: ['orgs', orgId, 'instances'],
    queryFn: () => fetchOrganizationInstances(orgId),
    enabled: enabled
  });
}

export function useAddInstance(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return API.post(`organizations/${orgId}/instances`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'instances'] });
    }
  });
}

export function useEditInstance(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ instanceId, data }) => {
      return API.put(`organizations/${orgId}/instances/${instanceId}`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'instances'] });
    }
  });
}

export function useDeleteInstance(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (instanceId) => {
      return API.delete(`organizations/${orgId}/instances/${instanceId}`);
    },
    onMutate: async (instanceId) => {
      await queryClient.cancelQueries({ queryKey: ['orgs', orgId, 'instances'] });
      const previousData = queryClient.getQueryData(['orgs', orgId, 'instances']);
      queryClient.setQueryData(['orgs', orgId, 'instances'], previousData.filter(i => i.id !== instanceId));
      return { previousData };
    },
    onError: (err, instanceId, context) => {
      queryClient.setQueryData(['orgs', orgId, 'instances'], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'instances'] });
    }
  });
}

// SECRETS

const fetchOrganizationSecrets = async (orgId) => {
  const response = await API.get(`organizations/${orgId}/secrets`);
  return response.data;
};

const fetchSecret = async (orgId, secretId) => {
  const response = await API.get(`organizations/${orgId}/secrets/${secretId}`);
  return response.data;
};

export function useSecrets(orgId, enabled = true) {
  return useQuery({
    queryKey: ['orgs', orgId, 'secrets'],
    queryFn: () => fetchOrganizationSecrets(orgId),
    enabled: enabled
  });
}

export function useSecret(orgId, secretId, enabled) {
  return useQuery({
    queryKey: ['orgs', orgId, 'secrets', secretId],
    queryFn: () => fetchSecret(orgId, secretId),
    enabled: enabled
  });
}

export function useAddSecrets(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return API.post(`organizations/${orgId}/secrets`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'secrets'] });
    }
  });
}

export function useEditSecrets(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return API.put(`organizations/${orgId}/secrets/${data.name}`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'secrets'] });
    }
  });
}

export function useDeleteSecrets(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (name) => {
      return API.delete(`organizations/${orgId}/secrets/${name}`);
    },
    onMutate: async (name) => {
      await queryClient.cancelQueries({ queryKey: ['orgs', orgId, 'secrets'] });
      const previousData = queryClient.getQueryData(['orgs', orgId, 'secrets']);
      queryClient.setQueryData(['orgs', orgId, 'secrets'], previousData.filter(i => i.name !== name));
      return { previousData };
    },
    onError: (err, name, context) => {
      queryClient.setQueryData(['orgs', orgId, 'secrets'], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'secrets'] });
    }
  });
}

// CHECK CONFIGS

const fetchOrganizationConfigs = async (orgId) => {
  const response = await API.get(`organizations/${orgId}/configs`);
  return response.data;
};

export function useConfigs(orgId, enabled = true, staleInfinity = false) {
  return useQuery({
    queryKey: ['orgs', orgId, 'configs'],
    queryFn: () => fetchOrganizationConfigs(orgId),
    enabled: enabled,
    staleTime: staleInfinity ? Infinity : 0
  });
}

export function useAddConfig(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return API.post(`organizations/${orgId}/configs`, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'configs'] });
    }
  });
}

export function useEditConfig(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ configId, checkData }) => {
      return API.put(`organizations/${orgId}/configs/${configId}`, checkData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'configs'] });
    }
  });
}

export function useDeleteConfig(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (configId) => {
      return API.delete(`organizations/${orgId}/configs/${configId}`);
    },
    onMutate: async (configId) => {
      await queryClient.cancelQueries({ queryKey: ['orgs', orgId, 'configs'] });
      const previousData = queryClient.getQueryData(['orgs', orgId, 'configs']);
      queryClient.setQueryData(['orgs', orgId, 'configs'], previousData.filter(c => c.id !== configId));
      return { previousData };
    },
    onError: (err, configId, context) => {
      queryClient.setQueryData(['orgs', orgId, 'configs'], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'configs'] });
    }
  });
}

// DASHBOARD

const fetchUserMetaData = async (orgId) => {
  const response = await API.get(`organizations/${orgId}/usermetadata`);
  return response.data;
};

export function useUserMetaData(orgId, enabled = true) {
  return useQuery({
    queryKey: ['orgs', orgId, 'usermetadata'],
    queryFn: () => fetchUserMetaData(orgId),
    initialData: [],
    enabled: enabled && orgId > 0
  });
}

export function useSetUserOption(orgId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      data.value = JSON.stringify(data.value);
      return API.post(`organizations/${orgId}/usermetadata`, data);
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: ['orgs', orgId, 'usermetadata'] });
      const previousData = queryClient.getQueryData(['orgs', orgId, 'usermetadata']);
      let newData = previousData.filter(d => d.name !== data.name);
      newData.push(data);
      queryClient.setQueryData(['orgs', orgId, 'usermetadata'], newData);
      return { previousData };
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(['orgs', orgId, 'usermetadata'], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgs', orgId, 'usermetadata'] });
    }
  });
}

// ADMIN

const fetchAuditLogs = async (orgId, query) => {
  if (query.period === 0 && (isEmpty(query.start) || isEmpty(query.end))) {
    return [];
  }

  // Format for json
  if (query.start) {
    query.start = query.start.toJSON();
  }
  if (query.end) {
    query.end = query.end.toJSON();
  }

  const response = await API.get(`organizations/${orgId}/auditlog`, query);
  return response.data;
};

export function useAuditLogs(orgId, query) {
  return useQuery({
    queryKey: ['orgs', orgId, 'auditlogs', query],
    queryFn: () => fetchAuditLogs(orgId, query),
    cacheTime: 0
  });
}

import { forwardRef } from 'react';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const UnitsSelect = forwardRef(function UnitsSelect(props, ref) {
  return (
    <TextField
      select
      size="small"
      label="Units"
      {...props}
      ref={ref}
      fullWidth
    >
      <MenuItem key="" value="">&nbsp;</MenuItem>
      <MenuItem key="kB" value="kB">kB</MenuItem>
      <MenuItem key="KiB" value="KiB">KiB</MenuItem>
      <MenuItem key="MB" value="MB">MB</MenuItem>
      <MenuItem key="MiB" value="MiB">MiB</MenuItem>
      <MenuItem key="GB" value="GB">GB</MenuItem>
      <MenuItem key="GiB" value="GiB">GiB</MenuItem>
      <MenuItem key="TB" value="TB">TB</MenuItem>
      <MenuItem key="TiB" value="TiB">TiB</MenuItem>
    </TextField>
  );
});

export default UnitsSelect;
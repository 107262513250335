import { useEffect } from 'react';
import { useSearchParams, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';

import { Page } from 'components/layout';
import AgentViewer from 'components/dashboard/AgentViewer';
import ConfigsViewer from 'components/dashboard/ConfigsViewer';
import { useAuth } from 'context/auth';
import { useAgents } from 'hooks/organizations';
import { API } from 'utils/network';

import { AgentViewProvider, useAgentView } from 'context/viewer';
import { ExampleProvider } from 'context/example';

function Agent() {

  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { selectedOrgId } = useAuth();
  const { agentId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, data: agents } = useAgents(selectedOrgId);
  const agent = agents?.find(a => a.id === parseInt(agentId));

  // Let the backend know that we clicked on this specific agent
  useEffect(() => {
    if (agent !== undefined) {
      API.post(`organizations/${selectedOrgId}/agents/${agent.id}/accessed`);
    }
  }, [agent]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabSelect = (e, tabVal) => {
    let params = { section: tabVal };
    if (searchParams.get('tab')) {
      params.tab = searchParams.get('tab');
    }
    setSearchParams(params);
  }

  if (isLoading) {
    return;
  }

  if (agent === undefined) {
    return (
      <Page>
        <Stack spacing={2}>
          <Typography variant="h4">Not Found</Typography>
          <Typography>Agent requested was not found.</Typography>
          <Link component={RouterLink} to="/agents">Return to Agents</Link>
        </Stack>
      </Page>
    );
  }

  return (
    <Page margin={0}>
      <Helmet>
        {agent.hostname ? (
          <title>{agent.hostname} &middot; Agent &middot; ReChecked Manager</title>
        ) : (
          <title>Agent &middot; ReChecked Manager</title>
        )}
      </Helmet>
      <AgentViewProvider>
        <TabContext value={searchParams.get('section') ?? 'viewer'}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography title={agent.name !== "" ? agent.hostname : undefined} sx={{ px: 2, py: mobileSize ? 2 : 0, fontWeight: "bold" }}>
                  {agent.name ? agent.name : agent.hostname}
                </Typography>
                {mobileSize && (
                  <Box sx={{ mx: 2 }}>
                    <ConnectionTypeSelector agentId={agentId} noText />
                  </Box>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Tabs
                value={searchParams.get('section') ?? 'viewer'}
                onChange={handleTabSelect}
                aria-label="agent component tabs"
                centered
              >
                <Tab label="Viewer" value="viewer" />
                <Tab label="Configs" value="configs" />
              </Tabs>
            </Grid>
            {!mobileSize && (
              <Grid item xs={12} md={4}>
                <Stack sx={{ mx: 2 }} spacing={1} direction="row" justifyContent="flex-end" alignItems="center">
                  <ConnectionTypeSelector agentId={agentId} />
                </Stack>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
              <TabPanel value="viewer">
                <ExampleProvider>
                  <AgentViewer agent={agent} />
                </ExampleProvider>
              </TabPanel>
              <TabPanel value="configs">
                <ConfigsViewer agentId={agent.id} />
              </TabPanel>
            </Grid>
          </Grid>
        </TabContext>
      </AgentViewProvider>
    </Page>
  );
}

function ConnectionTypeSelector({ agentId, noText }) {
  const { view, setViewSetting } = useAgentView();

  const changeViewType = (e) => {
    setViewSetting(agentId, e.target.checked ? 'address' : 'hostname');
  };

  return (
    <>
      {!noText && <Typography variant="body2">Hostname</Typography>}
      <Switch size="small" checked={view === 'address' ? true : false} onChange={changeViewType} />
      {!noText && <Typography variant="body2">Address</Typography>}
    </>
  );
}

export default Agent;
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';

import { useAuth } from 'context/auth';
import { ucFirst } from 'utils/common';

function Login() {

  const { auth, otpEnabled, login, logout, verify2fa } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmitLogin = async (e) => {
    setError(false);
    setLoading(true);
    e.preventDefault();
    const [error, loggedIn] = await login(email, password);
    if (!loggedIn) {
      setError(ucFirst(error));
    }
    setLoading(false);
  };

  const onSubmit2FA = async (e) => {
    setError(false);
    setLoading(true);
    e.preventDefault();
    await verify2fa(authCode, (err) => {
      setError("Invalid authentication code");
    });
    setLoading(false);
  };

  return (
    <Container maxWidth="xs" sx={{ p: 4 }}>
      <Helmet>
        <title>Login &middot; ReChecked Manager</title>
      </Helmet>
      {auth.loggedIn && auth.otpVerified === false && otpEnabled ? (
        <form key="2fa" onSubmit={onSubmit2FA} autoComplete="off">
          <Stack spacing={3}>
            <Typography variant="h4">Two Factor Authentication</Typography>
            <Typography>Open your authentication device and enter the authentication code.</Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <TextField label="Authentication Code" variant="outlined"
              id="authcode" value={authCode} autoFocus
              onChange={e => { setAuthCode(e.target.value); }}
            />
            <LoadingButton loading={loading} type="submit" size="large" color="secondary" variant="contained">Authenticate</LoadingButton>
            <Button onClick={() => { setError(''); logout(); }}>Back to Login</Button>
          </Stack>
        </form>
      ) : (
        <form key="login" onSubmit={onSubmitLogin}>
          <Stack spacing={3}>
            <Typography variant="h4">Account Login</Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <TextField label="Email Address" variant="outlined"
              id="email" autoFocus
              value={email}
              onChange={e => { setEmail(e.target.value); }}
            />
            <TextField label="Password" variant="outlined"
              id="password" type="password"
              value={password}
              onChange={e => { setPassword(e.target.value); }}
            />
            <Link component={RouterLink} to="/reset-password" underline="hover">Forgot Password?</Link>
            <LoadingButton loading={loading} type="submit" size="large" color="secondary" variant="contained">Login</LoadingButton>
            <Button type="link" component={RouterLink} to="/register">Create Account</Button>
          </Stack>
        </form>
      )}
    </Container>
  );
}

export default Login;
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import ClearIcon from '@mui/icons-material/Clear';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import { Page } from 'components/layout';
import { AllAgentsGroup, AgentGroupList } from 'components/layout/groups';
import AddOrgForm from 'components/forms/organizations/AddOrgForm';
import RecentAgents from 'components/RecentAgents';
import PendingAgentsAlert from 'components/alerts/PendingAgentsAlert';
import { useSetUserOption } from 'hooks/organizations';
import { useAuth } from 'context/auth';

function Dashboard() {

  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { selectedOrgId } = useAuth();

  return (
    <Page>
      <Helmet>
        <title>Dashboard &middot; ReChecked Manager</title>
      </Helmet>
      {selectedOrgId !== 0 ? <HomeDashboard mobile={mobileSize} /> : <NoOrganizationWizard mobile={mobileSize} />}
    </Page>
  );
}

function HomeDashboard({ mobile }) {
  
  const { hasPerm } = useAuth();
  const navigate = useNavigate();

  return (
    <Grid container justifyContent="left" alignItems="stretch" spacing={mobile ? 2 : 5}>
      <Grid item xs={12} md={8} lg={9}>
        <Grid container justifyContent="left" alignItems="stretch" spacing={mobile ? 2 : 5}>
          {hasPerm('User') && (
            <>
              <WelcomeDashlet />
              {hasPerm('Manager') && <PendingAgentsAlert withGrid />}
              <Grid item xs={12} lg={6}>
                <Card variant="outlined" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: 1 }}>
                    <Typography variant="h6" component="div">Manage Agents</Typography>
                    <Typography variant="body2">Add and remove the agents stored in ReChecked Manager.</Typography>
                  </CardContent>
                  <CardActions>
                    <Button color="secondary" onClick={() => navigate('/agents')}>View Agents</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Card variant="outlined" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: 1 }}>
                    <Typography variant="h6" component="div">Manage Checks</Typography>
                    <Typography variant="body2">Update your configurations applied to your ReChecked Agents.</Typography>
                  </CardContent>
                  <CardActions>
                    <Button color="secondary" onClick={() => navigate('/configure/checks')}>View Check Configs</Button>
                  </CardActions>
                </Card>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <RecentAgents />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <AllAgentsGroup />
        <Divider sx={{ my: mobile ? 2 : 4 }} />
        <AgentGroupList />
      </Grid>
    </Grid>
  );
}

function NoOrganizationWizard({ mobile }) {
  return (
    <Grid container justifyContent="center" alignItems="stretch" spacing={mobile ? 2 : 5}>
      <Grid item xs={12}>
        <Stack spacing={4}>
          <Typography variant="h2">Set up an Organization</Typography>
          <Stack spacing={2}>
            <Typography>Welcome to ReChecked! To start using the manager, you'll need to be a part of an Organization. Organizations are used as a way to group your agents, typically the business organization is a company. You can also invite other users to your business type organizations, and can manage multiple organizations from a single account.</Typography>
            <Typography>Let's get started creating your first Organization.</Typography>
          </Stack>
          <AddOrgForm hideHeader={true} />
        </Stack>
      </Grid>
    </Grid>
  );
}

function WelcomeDashlet() {

  const { getOrgInfo, userMetaData, selectedOrgId, hasPerm } = useAuth();
  const optMutate = useSetUserOption(selectedOrgId);

  const closeWelcome = () => {
    optMutate.mutate({
      name: "hideWelcome",
      value: 1
    });
  };

  const hideDashlet = userMetaData("hideWelcome") ?? 0;
  if (hideDashlet === 1) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Card variant="outlined" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: 1 }}>
          <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h6" component="div">Welcome to ReChecked Manager</Typography>
              <Typography variant="body2">
                Here are a few things to get you started using ReChecked Manager. You can close this help card at any time to remove it from your dashboard.
              </Typography>
            </Box>
            <IconButton onClick={() => closeWelcome()}>
              <ClearIcon />
            </IconButton>
          </Stack>
          <Box>
            <ul style={{ marginBottom: 0 }}>
              <li>
                <Stack direction="row" spacing={1} alignItems="bottom">
                  <OndemandVideoIcon fontSize="small" />
                  <Link color="secondary" href="https://rechecked.io/getting-started-with-rechecked-manager/"><b>Watch video on how to get started using ReChecked Manager</b></Link>
                </Stack>
              </li>
              <li><Link color="secondary" href="https://rechecked.io/how-to-install-your-ca-certificates/"><b>Add the CA Certificate for your organization</b> as a trusted certificate to local computer and to your Nagios machines.</Link></li>
              {hasPerm('Manager') && <li>Add at least one <Link color="secondary" component={RouterLink} to="/configure/instances">Nagios Instance (NRDP)</Link> to send your configured passive checks to.</li>}
              {hasPerm('Manager') && <li>Upload any custom plugins you would like on the <Link color="secondary" component={RouterLink} to="/configure/checks">Manage Plugins</Link> page and configure them on your agents.</li>}
              {getOrgInfo().type === "business" && <li>Invite other team members to your organization in the upper right go to My Account &gt; Organizations &gt; Invite User.</li>}
              <li>Add one or more agents to ReChecked Manager by following the instruction in the Add Agents button in the upper right corner.</li>
              <li>Create <Link color="secondary" component={RouterLink} to="/groups">Agent Groups</Link> to manage your agents configurations and locations easily.</li>
              <li>Create a new <Link color="secondary" component={RouterLink} to="/configure/checks">Check Configuration</Link> to sync with your agents and apply specific checks to send to your Nagios Instance.</li>
            </ul>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Dashboard;
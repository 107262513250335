import { useState } from 'react'
import { useForm, Controller } from "react-hook-form";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import { LoadingCircle } from 'components/layout';
import { useAuth } from 'context/auth';
import { useAgents, useGroup, useAddGroup, useEditGroup } from 'hooks/organizations';
import AgentsSelect from 'components/forms/config/AgentsSelect';

function GroupModal({ groupId, open, setOpen }) {

  const [error, setError] = useState("");

  const { selectedOrgId } = useAuth();
  const { isAgentsLoading, data: agents } = useAgents(selectedOrgId);
  const { isLoading, data: groupInfo } = useGroup(selectedOrgId, groupId, open);
  const groupAddMutate = useAddGroup(selectedOrgId);
  const groupEditMutate = useEditGroup(selectedOrgId);

  var groupData = null;
  if (!isLoading && groupInfo && !isAgentsLoading && agents) {
    groupData = {
      name: groupInfo.name,
      agents: groupInfo.agentIds && agents ? agents.filter(a => groupInfo.agentIds.indexOf(a.id) !== -1) : []
    };
  }

  const { control, formState: { errors }, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      agents: []
    },
    values: groupData
  });

  const handleClose = () => {
    setOpen(false);
    setError("");
    setTimeout(reset, 200);
  };

  const onSubmit = (data) => {
    setError(false);
    const agentIds = data.agents.map(a => a.id);
    if (groupId) {
      groupEditMutate.mutate({
        groupId,
        data: {
          name: data.name,
          agentIds: agentIds
        }
      }, {
        onSuccess: () => {
          handleClose();
        },
        onError: () => {
          setError("Could not update group.");
        }
      })
    } else {
      groupAddMutate.mutate({
        name: data.name,
        agentIds: agentIds
      }, {
        onSuccess: () => {
          handleClose();
        },
        onError: () => {
          setError("Could not create group.");
        }
      });
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{groupId ? 'Edit' : 'Create'} Group</DialogTitle>
        {isLoading && groupId ? (
          <DialogContent>
            <LoadingCircle />
          </DialogContent>
        ): (
          <DialogContent>
            <DialogContentText sx={{ marginBottom: 3 }}>
              {groupId ? 'Make changes to a' : 'Set up a new'} group and select agents to associate with it.
            </DialogContentText>
            {error && <Alert severity="error" sx={{ marginBottom: 4 }}>{error}</Alert>}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Group name is required" }}
                  render={({ field }) => <TextField
                    size="small" label="Group Name" fullWidth autoFocus required
                    {...field}
                    helperText={errors.name?.message} error={errors.name !== undefined}
                  />}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="agents"
                  control={control}
                  render={({ field: { onChange, value } }) => <AgentsSelect
                    label="Group Agents"
                    onChange={onChange} value={value} />}
                />
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color="secondary">{groupId ? 'Save' : 'Create'} Group</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default GroupModal;
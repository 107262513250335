import { useSearchParams } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

function Footer({ padding, mobile }) {
  
  // Don't display on the api viewer page so we have more space for the viewer
  const [searchParams] = useSearchParams();
  if (searchParams.get('tab') === 'api') {
    return;
  }

  return (
    <Container maxWidth={false} sx={{ flexShrink: 0 }}>
      <Stack direction={mobile ? "column" : "row"} spacing={mobile ? 1 : 2} justifyContent="space-between" alignItems="center" sx={{ py: padding }}>
        <Stack direction="row" divider={<Divider orientation="vertical" />} spacing={3}>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} <Link color="inherit" underline="hover" href="https://rechecked.io" target="_blank" rel="noopener noreferrer">ReChecked LLC</Link>
          </Typography>
        </Stack>
        <Stack direction="row" divider={<Divider orientation="vertical" />} spacing={3}>
          <Typography variant="body2">
            <Link color="inherit" underline="hover" href="https://rechecked.io/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
          </Typography>
          <Typography variant="body2">
            <Link color="inherit" underline="hover" href="https://rechecked.io/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</Link>
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
}

export default Footer;
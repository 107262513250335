import { useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

import { useAuth } from 'context/auth';
import { useDeleteGroup } from 'hooks/organizations';
import ConfirmModal from 'components/modals/ConfirmModal';
import GroupModal from 'components/modals/GroupModal';

function GroupSettingsButton({ groupId }) {

  const { selectedOrgId } = useAuth();
  const mutateDelete = useDeleteGroup(selectedOrgId);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirmDelete = () => {
    setAnchorEl(null);
    setConfirmOpen(true);
  };

  const handleDelete = () => {
    mutateDelete.mutate(groupId);
  };

  const editGroupClick = () => {
    setAnchorEl(null);
    setEditOpen(true);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'dashboard-settings-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
      >
        <MoreVertOutlinedIcon fontSize="small" />
      </IconButton>
      <Menu
        id="dashboard-settings-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={editGroupClick}>Edit Group</MenuItem>
        <MenuItem onClick={confirmDelete}>Delete</MenuItem>
      </Menu>
      <ConfirmModal
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title="Confirm Delete Group"
        text="Are you sure you want to delete this group? Any configurations that are added to an agent through the group will no longer be applied to the agent."
        okText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => handleDelete()}
      />
      <GroupModal groupId={groupId} open={editOpen} setOpen={setEditOpen} />
    </>
  );
}

export default GroupSettingsButton;
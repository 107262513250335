import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';

import { Page, LoadingCircle } from 'components/layout';
import { useAuth } from 'context/auth';
import { useOrganizationSettings, useUpdateOrganizationSettings } from 'hooks/organizations';

function Settings() {

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("success");

  const { selectedOrgId } = useAuth();
  const { isLoading, data: settings } = useOrganizationSettings(selectedOrgId);
  const updateMutate = useUpdateOrganizationSettings(selectedOrgId);

  const { control, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      maxCheckinLimit: 5,
      autoActivateAgents: true
    },
    values: settings
  });

  const onSubmit = (data) => {
    if (isLoading) { return false; }
    data.maxCheckinLimit = parseInt(data.maxCheckinLimit);
    updateMutate.mutate(data, {
      onSuccess: () => {
        setMessageType("success");
        setMessage("Changes to settings have been saved.");
      },
      onFailure: () => {
        setMessageType("error");
        setMessage("Could not save settings.");
      }
    })
  };

  return (
    <Page>
      <Helmet>
        <title>Settings &middot; ReChecked Manager</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container maxWidth="md" spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Settings</Typography>
            <Typography>Configure your organization's agent-related settings. Only owners/admins can see and change organization settings.</Typography>
            {message && (<Alert severity={messageType} sx={{ mt: 4 }} onClose={() => setMessage("")}>{message}</Alert>)}
          </Grid>
          {isLoading ? <Grid item xs={12}><LoadingCircle delay={200} /></Grid> : (
            <>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Controller
                      name="maxCheckinLimit"
                      control={control}
                      rules={{ required: "Checkin time limit is required", valueAsNumber: true, min: 1 }}
                      render={({ field }) => <TextField
                          label="Check In Time Limit (minutes)"
                          size="small"
                          sx={{ maxWidth: 250 }}
                          InputLabelProps={{ shrink: true }}
                          type="number"
                          {...field}
                          required
                          helperText={errors.maxCheckinLimit?.message} error={errors.maxCheckinLimit !== undefined}
                        />}
                      />
                    <Typography variant="body2">The check in time limit. If an agent doesn't checkin within this time it's considered in 'unknown' state which normally means it's not running or cannot access the manager.</Typography>
                  </Stack>
                  <Typography variant="h5">Sync Options</Typography>
                  <Stack spacing={1}>
                    <Controller
                      name="autoActivateAgents"
                      control={control}
                      render={({ field }) => (
                        <FormGroup>
                          <FormControlLabel 
                            control={<Checkbox {...field} checked={field.value} color="secondary" />}
                            label="Automatically add new agents that request to be added to your organization when using your API key"
                            />
                        </FormGroup>
                      )}
                    />
                    <Typography variant="body2">
                      If this option is disabled, a Manager or Admin must accept new agent registrations manually. <Link component={RouterLink} to="/agents/pending" color="secondary">See pending requests</Link>.
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <LoadingButton type="submit" variant="contained" color="secondary">Save Settings</LoadingButton>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </Page>
  );
}

export default Settings;
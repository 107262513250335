import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';

import { useResetPassword } from 'hooks/auth';
import { ucFirst } from 'utils/common';

function ResetPasswordRequestForm() {

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const reset = useResetPassword();
  const { control, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      email: ""
    },
  });

  const onSubmit = (data) => {
    reset.mutate(data, {
      onSuccess: () => {
        setSuccess(true);
      },
      onError: (err) => {
        if (err.response.status === 422) {
          setError(ucFirst(err.response.data.message));
        } else {
          setError("Could not submit password reset");
        }
      }
    });
  };

  if (success) {
    return (
      <Stack spacing={3}>
        <Typography variant="h4">Reset Password</Typography>
        <Typography>Password reset request sent.</Typography>
        <Typography variant="body1">If an account is associated with the email, you will recieve an email with a link to reset the password.</Typography>
        <Button type="link" component={RouterLink} to="/login">Back</Button>
      </Stack>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Typography variant="h4">Reset Password</Typography>
        <Typography variant="body1">Provide account's email address to send a verification email to reset your password.</Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <Controller
          name="email"
          control={control}
          rules={{ required: "Valid email address is required" }}
          render={({ field }) => <TextField {...field} label="Email Address" variant="outlined" id="email"
            autoFocus helperText={errors.email?.message} error={errors.email !== undefined} />}
        />
        <LoadingButton
          loading={reset.isLoading}
          variant="contained"
          size="large"
          color="secondary"
          type="submit"
        >
          Request Reset
        </LoadingButton>
        <Button type="link" component={RouterLink} to="/login">Back</Button>
      </Stack>
    </form>
  );
}

export default ResetPasswordRequestForm;
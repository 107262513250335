import { createContext, useContext, useState, useEffect } from 'react';;

const AgentViewContext = createContext();

export function useAgentView() {
  return useContext(AgentViewContext);
}

export function AgentViewProvider(props) {

  const [agent, setAgent] = useState([]);
  const [viewConnectionSettings, setViewConnectSettings] = useState(JSON.parse(localStorage.getItem("viewConnectionSettings") ?? "{}"));
  const [view, setView] = useState('hostname');

  const setViewSetting = (agentId, v) => {
    setView(v);
    setViewConnectSettings(prevState => {
      prevState[agentId] = v;
      localStorage.setItem("viewConnectionSettings", JSON.stringify(prevState));
      return prevState;
    });
  };

  useEffect(() => {
    setView(viewConnectionSettings[agent.id] ?? 'hostname');
  }, [agent, viewConnectionSettings]);

  return (
    <AgentViewContext.Provider value={{ view, setViewSetting, agent, setAgent }}>
      {props.children}
    </AgentViewContext.Provider>
  );
}

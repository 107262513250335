import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

function ApplyToChips({ config }) {

  if (config.allAgents) {
    return <Chip label="All Agents" size="small" />;
  }

  if (config.groupName) {
    return <Chip label={config.groupName} size="small" icon={<WorkspacesIcon />} />;
  }

  if (config.agents) {
    return (
      <Stack direction="row" spacing={1}>
        {config.agents.map(a => <Chip key={a} label={a} size="small" icon={<FiberManualRecordIcon />} />)}
      </Stack>
    );
  }
}

export default ApplyToChips;
import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useChangePassword } from 'hooks/account';

function ChangePasswordModal({ open, setOpen }) {

  const [error, setError] = useState('');
  const [openSnack, setOpenSnack] = useState(false);

  const mutatePassword = useChangePassword();

  const { control, formState: { errors }, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      password: '',
      newPassword: '',
      confNewPassword: ''
    }
  });

  const handleClose = () => {
    setOpen(false);
    setError('');
    setTimeout(reset, 200);
  };

  const onSubmit = (data) => {
    mutatePassword.mutate(data, {
      onSuccess: () => {
        handleClose();
        setOpenSnack(true);
      },
      onError: (err) => {
        if (err.response.status === 401) {
          setError("Current password provided is not correct.");
        } else {
          setError("Could not change password, please contact support.");
        }
      }
    })
  };

  return (
    <>
      <Dialog maxWidth="xs" open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ pb: 3 }}>New passwords must be 8+ characters and have at least 1 number and special character.</DialogContentText>
            {error && <Alert severity="error" sx={{ marginBottom: 4 }}>{error}</Alert>}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: "Current password is required" }}
                  render={({ field }) => <TextField type="password"
                    size="small" label="Current Password" fullWidth autoFocus required
                    {...field}
                    helperText={errors.password?.message} error={errors.password !== undefined}
                  />}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="newPassword"
                  control={control}
                  rules={{
                    required: "New password is required",
                    minLength: { value: 8, message: "Passwords must be at least 8 characters" },
                    maxLength: { value: 24, message: "Passwords cannot be over 24 characters" },
                    validate: (val) => val !== watch("password") || "Must use a new password"
                  }}
                  render={({ field }) => <TextField type="password"
                    size="small" label="New Password" fullWidth required
                    {...field}
                    helperText={errors.newPassword?.message} error={errors.newPassword !== undefined}
                  />}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="confNewPassword"
                  control={control}
                  rules={{
                    required: "New password confirmation is required",
                    validate: (val) => val === watch("newPassword") || "New passwords must match"
                  }}
                  render={({ field }) => <TextField type="password"
                    size="small" label="Confirm New Password" fullWidth required
                    {...field}
                    helperText={errors.confNewPassword?.message} error={errors.confNewPassword !== undefined}
                  />}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" color="secondary">Change Password</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar open={openSnack} onClose={() => setOpenSnack(false)} autoHideDuration={6000} key="changepw">
        <Alert onClose={() => setOpenSnack(false)} severity="success" sx={{ width: '100%' }}>
          Password has been changed
        </Alert>
      </Snackbar>
    </>
  );
}

export default ChangePasswordModal;
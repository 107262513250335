import { Helmet } from 'react-helmet-async';

import Page from 'components/layout/Page';

import AddOrgForm from 'components/forms/organizations/AddOrgForm';

function Add() {
  return (
    <Page>
      <Helmet>
        <title>Add Organization &middot; My Account &middot; ReChecked Manager</title>
      </Helmet>
      <AddOrgForm />
    </Page>
  );
}

export default Add;
let url = 'https://localhost:8080/';
let prodFlag = false;
let devFlag = false;
if (process.env.NODE_ENV === 'production') {
  url = `https://${window.location.hostname}/api/`;
  
  // Prod environment
  if (window.location.hostname === 'manage.rechecked.io') {
    prodFlag = true;
  }
  
  // Dev environemnt
  if (window.location.hostname === 'manage-dev.rechecked.io') {
    devFlag = true;
  }
}

export const API_URL = `${url}`;
export const PRODUCTION = prodFlag;
export const DEVELOPMENT = devFlag;

export const refreshRates = {
  OVERVIEW: 5000,
  DISK: 15000,
  NETWORK: 3000,
  PROCESSES: 3000,
  SERVICES: 15000,
  USERS: 30000,
  PLUGINS: 30000
};

export const userLevels = {
  'User (Read Only)': 1,
  'User': 4,
  'Manager': 8,
  'Admin': 12,
  'Owner': 99
};

export const LOG_TYPES = {
  ACTION: 1,
	CONFIG: 2,
	AGENTS: 3,
	BILLING: 4,
	ADMIN: 5
};

export const logTypes = {
  [LOG_TYPES.ACTION]: "Action",
  [LOG_TYPES.CONFIG]: "Config",
  [LOG_TYPES.AGENTS]: "Agents",
  [LOG_TYPES.BILLING]: "Billing",
  [LOG_TYPES.ADMIN]: "Admin"
};

export const ENDPOINTS = [{
    name: "cpu/percent"
  }, {
    name: "disk",
    units: true
  }, {
    name: "disk/inodes",
    units: true,
    only: "linux"
  }, {
    name: "load",
    only: "linux"
  }, {
    name: "memory/virtual",
    units: true
  }, {
    name: "memory/swap",
    units: true
  }, {
    name: "network",
    units: true,
    delta: true
  }, {
    name: "plugins"
  }, {
    name: "processes"
  }, {
    name: "services"
  }, {
    name: "system/users"
  }, {
    name: "system/version"
  }];
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { useAuth } from '../../context/auth';

import Search from '../Search';
import Footer from './Footer';
import CurrentOrgSelect from 'components/forms/organizations/CurrentOrgSelect';
import { AddAgentsButton } from 'components/buttons';

function Content(props) {

  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (mobileSize) {
    return (
      <MobileLayout>
        {props.children}
      </MobileLayout>
    )
  }

  return (
    <FullLayout>
      {props.children}
    </FullLayout>
  );
}

function FullLayout(props) {

  const { selectedOrgId } = useAuth();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ flex: '1 0 auto' }}>
        <Navbar />
        {selectedOrgId !== 0 && <NavDrawer />}
        {props.children}
      </Box>
      <Box sx={{ ml: selectedOrgId === 0 ? 0 : 30 }}>
        <Footer padding={2} />
      </Box>
    </Box>
  );
}

function MobileLayout(props) {

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ flex: '1 0 auto' }}>
        <Navbar mobile onOpenDrawer={() => setOpenDrawer(true)} />
        <NavDrawer mobile open={openDrawer} onClose={() => setOpenDrawer(false)} />
        {props.children}
      </Box>
      <Footer mobile padding={2} />
    </Box>
  )
}

function Navbar({ mobile, onOpenDrawer }) {

  const { logout, selectedOrgId, hasPerm, themeSettings, setThemeSettings } = useAuth();
  const navigate = useNavigate();

  const [showSearch, setShowSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };

  return (
    <Box>
      <AppBar
        position={mobile ? "static" : "fixed"}
        elevation={0}
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          borderBottom: themeSettings.mode === 'dark' ? 1 : 0,
          borderBottomColor: (theme) => theme.palette.grey[800]
        }}
      >
        <Toolbar>
          {mobile && (
            <Stack direction="row" spacing={1}>
              <IconButton onClick={onOpenDrawer} size="large" color="inherit">
                <MenuIcon fontSize="inherit" />
              </IconButton>
              {selectedOrgId !== 0 && hasPerm(['User']) ? <AddAgentsButton mobile /> : <Box sx={{ width: 28 }} />}
            </Stack>
          )}
          <Stack direction="row" alignItems="center" justifyContent={mobile ? "center" : "start"} spacing={6} sx={{ flexGrow: 1 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Link to="/">
                <img src="/logo192.png" style={{ width: 30, verticalAlign: "middle" }} alt="rechecked logo" />
              </Link>
              {!mobile && <Typography component={Link} to="/" variant="h6" sx={{
                color: 'inherit',
                textDecoration: 'none',
              }}>
                ReChecked Manager
              </Typography>}
            </Stack>
            {!mobile && (
              <Stack direction="row" spacing={2} sx={{ display: { xs: 'none', md: 'flex' } }}>
                {selectedOrgId !== 0 && <Button variant="text" color="inherit" onClick={() => navigate('/dashboard')}>Dashboard</Button>}
                <Button variant="text" color="inherit" onClick={() => navigate('/support')}>Support</Button>
              </Stack>
            )}
            
          </Stack>
          <Stack direction="row" alignItems="center" spacing={mobile ? 1 : 2}>
            {selectedOrgId !== 0 && !mobile && (
              <Stack direction="row" spacing={2}>
                {hasPerm(['User']) && <AddAgentsButton />}
                <Search />
              </Stack>
            )}
            {mobile && <IconButton color="inherit" size="large" onClick={() => setShowSearch(!showSearch)}><SearchIcon fontSize="inherit" /></IconButton>}
            <IconButton
              size="large"
              aria-label="user account menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleOpen}
            >
              <AccountCircleIcon fontSize="inherit" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{ dense: true }}
            >
              <MenuItem onClick={() => { setAnchorEl(null); navigate('/account'); }}>
                <ListItemIcon>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>
                My Account
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => setThemeSettings(prevState => prevState.mode === 'dark' ? { mode: 'light' } : { mode: 'dark' })}>
                <ListItemIcon>
                {themeSettings.mode === 'dark' ? <Brightness7Icon fontSize="small" /> : <Brightness4Icon fontSize="small" />}
                </ListItemIcon>
                {themeSettings.mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
              </MenuItem>
              <MenuItem onClick={() => { setAnchorEl(null); navigate('/account/organizations/new'); }}>
                <ListItemIcon>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                Add Organization
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </AppBar>
      {mobile && showSearch && (
        <>
          <Search onSearch={() => setShowSearch(false)} />
          <Divider />
        </>
      )}
    </Box>
  );
}

function NavDrawer({ mobile, open, onClose }) {

  const { selectedOrgId, hasPerm } = useAuth();

  return (
    <Drawer
      variant={mobile ? "temporary" : "permanent"}
      open={mobile ? open : true}
      onClose={onClose}
      sx={mobile ? {} : {
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
      }}
    >
      <Box sx={{ mt: mobile ? 0 : 8 }}>
        {mobile && (
          <>
            <List onClick={onClose} component="nav" dense subheader={<ListSubheader>Navigation</ListSubheader>}>
              {selectedOrgId !== 0 && (
                <ListItemButton component={Link} to="/dashboard">
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              )}
              <ListItemButton component={Link} to="/support">
                <ListItemText primary="Support" />
              </ListItemButton>
            </List>
            <Divider />
          </>
        )}
        <CurrentOrgSelect />
        <Divider />
        <List onClick={onClose} component="nav" dense subheader={<ListSubheader>Manage</ListSubheader>}>
          <ListItemButton component={Link} to="/agents">
            <ListItemText primary="Agents" />
          </ListItemButton>
          <ListItemButton component={Link} to="/groups">
            <ListItemText primary="Agent Groups" />
          </ListItemButton>
        </List>
        {hasPerm("User") && (
          <List onClick={onClose} component="nav" dense subheader={<ListSubheader>Configure</ListSubheader>}>
            <ListItemButton component={Link} to="/configure/checks">
              <ListItemText primary="Check Configurations" />
            </ListItemButton>
            {hasPerm("Manager") && (
              <>
                <ListItemButton component={Link} to="/configure/instances">
                  <ListItemText primary="Manage Nagios Instances" />
                </ListItemButton>
                <ListItemButton component={Link} to="/configure/plugins">
                  <ListItemText primary="Manage Plugins" />
                </ListItemButton>
                <ListItemButton component={Link} to="/configure/secrets">
                  <ListItemText primary="Manage Secrets" />
                </ListItemButton>
              </>
            )}
          </List>
        )}
        {hasPerm("Admin") && (
          <List onClick={onClose} component="nav" dense subheader={<ListSubheader>Admin</ListSubheader>}>
            <ListItemButton component={Link} to="/admin/settings">
              <ListItemText primary="Settings" />
            </ListItemButton>
            <ListItemButton component={Link} to="/admin/auditlog">
              <ListItemText primary="Audit Log" />
            </ListItemButton>
          </List>
        )}
      </Box>
    </Drawer>
  );
}

export default Content;

import { useState } from 'react';
import { useQueryParams, NumberParam, StringParam, withDefault } from 'use-query-params';
import { useDebouncedCallback } from 'use-debounce';
import dayjs from 'dayjs';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import MuiPagination from '@mui/material/Pagination';
import {
  DataGrid,
  GridToolbarExport,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Page, LoadingCircle } from 'components/layout';
import { useAuth } from 'context/auth';
import { useAuditLogs } from 'hooks/organizations';
import { formatLocalTime, formatLogType } from 'utils/common';
import { LOG_TYPES, logTypes } from 'config';

const columns = [
  {
    field: 'logTime',
    headerName: 'Log Time',
    width: 200,
    valueFormatter: (params) => formatLocalTime(params.value)
  },
  {
    field: 'log',
    headerName: 'Log Message',
    minWidth: 400,
    flex: 1
  },
  {
    field: 'logType',
    headerName: 'Log Type',
    minWidth: 200,
    flex: 0.20,
    valueFormatter: (params) => formatLogType(params.value)
  },
  {
    field: 'email',
    headerName: 'User',
    minWidth: 200,
    flex: 0.20,
    valueFormatter: (params) => {
      return params.value !== '' ? params.value : '-';
    }
  }
];

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return (
    <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', px: 2 }}>
      <GridToolbarExport csvOptions={{ fileName: "rechecked-manager-audit-log" }} />
      <GridPagination ActionsComponent={Pagination} {...props} />
    </Stack>
  );
}

function AuditLog() {

  const [query, setQuery] = useQueryParams({
    period: withDefault(NumberParam, 30),
    type: NumberParam,
    search: StringParam
  });

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [search, setSearch] = useState(query.search ?? ''); 

  const debounced = useDebouncedCallback(
    (search) => {
      setQuery({ search: search });
    },
    600
  );

  const { selectedOrgId } = useAuth();
  const { isLoading, data: logs } = useAuditLogs(selectedOrgId, {
    period: query.period,
    start: start,
    end: end
  });

  const onPeriodChange = (e) => {
    setStart(null);
    setEnd(null);
    setQuery({ period: e.target.value });
  };

  const clearFilters = () => {
    setQuery({ type: null, search: null });
    setSearch('');
  };

  const getFilteredLogs= () => {
    if (logs) {
      let newLogs = logs;
      if (search && search !== '') {
        newLogs = newLogs.filter(l => {
          if (l.log.toLowerCase().indexOf(search.trim().toLowerCase()) > -1) {
            return l;
          }
          return null;
        });
      }
      if (query.type > 0) {
        newLogs = newLogs.filter(l => l.logType === query.type);
      }
      return newLogs;
    }
    return logs;
  };

  const filteredLogs = getFilteredLogs();

  return (
    <Page>
      <Stack spacing={2}>
        <Typography variant="h4">Audit Log</Typography>
        <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
          <Stack spacing={2} direction="row">
            <TextField
              value={query.period}
              onChange={onPeriodChange}
              label="Time Period" size="small" select sx={{ width: 180 }}
            >
              <MenuItem value={30}>30 Days</MenuItem>
              <MenuItem value={60}>60 Days</MenuItem>
              <MenuItem value={90}>90 Days</MenuItem>
              <MenuItem value={0}>Custom Range</MenuItem>
            </TextField>
            {query.period === 0 && (
              <Stack spacing={1} direction="row">
                <DatePicker
                  value={start}
                  onChange={(v) => setStart(v)}
                  label="Start Date"
                  slotProps={{ textField: { size: 'small' } }}
                  disableFuture
                  />
                <DatePicker
                  value={end}
                  onChange={(v) => setEnd(v)}
                  label="End Date"
                  slotProps={{ textField: { size: 'small' } }}
                  disableFuture
                  minDate={dayjs(start).add(1, 'day')}
                  />
              </Stack>
            )}
          </Stack>
          <Stack spacing={2} direction="row">
            {(query.search || query.type) && (<Button onClick={clearFilters}>Clear</Button>)}
            <TextField select label="Filter by Log Type" size="small" sx={{ width: 200 }} value={query.type ?? ''} onChange={(e) => setQuery({ type: e.target.value })}>
              {Object.keys(LOG_TYPES).map(k => (
                <MenuItem key={k} value={LOG_TYPES[k]}>{logTypes[LOG_TYPES[k]]}</MenuItem>
              ))}
            </TextField>
            <TextField label="Search" value={search} size="small" onChange={e => { setSearch(e.target.value); debounced(e.target.value) }} />
          </Stack>
        </Stack>
        {isLoading ? <LoadingCircle /> : <LogsTable logs={filteredLogs} />}
      </Stack>
    </Page>
  );
}

function LogsTable({ logs }) {

  if (!logs) {
    return null;
  } else if (logs.length === 0) {
    return <Card variant="outlined" sx={{ p: 2 }}>No logs matching your filters.</Card>
  }

  return (
    <Box>
      <DataGrid
        rows={logs}
        columns={columns}
        density="compact"
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15
            }
          }
        }}
        slots={{
          pagination: CustomPagination,
        }}
        pageSizeOptions={[15, 30, 60]}
      />
    </Box>
  );
}

export default AuditLog;
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CssBaseline from '@mui/material/CssBaseline';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { AuthProvider, useAuth } from 'context/auth';
import { AgentsProvider } from 'context/agents';

import { Content, PublicContent, LoadingCircle, FullScreen }
  from 'components/layout';

import Login from 'pages/auth/Login';
import Register from 'pages/auth/Register';
import ResetPassword from 'pages/auth/ResetPassword';
import ResetPasswordConfirm from 'pages/auth/ResetPasswordConfirm';
import VerifyEmail from 'pages/auth/VerifyEmail';
import Dashboard from 'pages/Dashboard';
import Agents from 'pages/Agents';
import AgentsPending from 'pages/AgentsPending';
import Agent from 'pages/Agent';
import AgentGroups from 'pages/AgentGroups';
import Support from 'pages/Support';

import CheckConfigsHome from 'pages/configure/checks/CheckConfigsHome';
import CheckConfigForm from 'pages/configure/checks/CheckConfigForm';
import Instances from 'pages/configure/Instances';
import Plugins from 'pages/configure/Plugins';
import Secrets from 'pages/configure/Secrets';

import AccountHome from 'pages/account/Home';
import Add from 'pages/account/organizations/Add';
import Payment from 'pages/account/organizations/Payment';
import Complete from 'pages/account/organizations/Complete';

import Settings from 'pages/admin/Settings';
import AuditLog from 'pages/admin/AuditLog';

const themeComponents = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true
    },
    variants: [
      {
        props: { size: 'large', variant: 'contained' },
        style: {
          padding: 12
        }
      }
    ]
  },
};

const theme = createTheme({
  palette: {
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#8284EE',
      main: '#6365F1',
      dark: '#4044ee',
      contrastText: '#fff',
    },
  },
  components: themeComponents,
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: '#484848',
      main: '#F9F9F9',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#8284EE',
      main: '#6365F1',
      dark: '#4044ee',
      contrastText: '#fff',
    },
  },
  components: themeComponents,
});

const queryClient = new QueryClient();

function App() {
  return (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <AuthProvider>
              <AppContent />
            </AuthProvider>
          </QueryParamProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
}

function AppContent() {

  const { isLoggedIn, loading, themeSettings } = useAuth();

  return (
    <ThemeProvider theme={themeSettings.mode === "dark" ? darkTheme : theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ReChecked Manager</title>
      </Helmet>
      <AgentsProvider>
        <CssBaseline enableColorScheme />
        {loading ? <FullScreen><LoadingCircle delay={200} /></FullScreen> : isLoggedIn() ? (
          <Content>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/account/*" element={<AccountRouter />} />
              <Route path="*" element={<MainRouter />} />
            </Routes>
          </Content>
        ) : (
          <PublicContent>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset-password/" element={<ResetPassword />} />
              <Route path="/reset-password/confirm" element={<ResetPasswordConfirm />} />
              <Route path="/reset-password/confirm/:token" element={<ResetPasswordConfirm />} />
              <Route path="/verify" element={<VerifyEmail />} />
              <Route path="/verify/:token" element={<VerifyEmail />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </PublicContent>
        )}
      </AgentsProvider>
    </ThemeProvider>
  );
}

function AccountRouter() {
  return (
    <Routes>
      <Route path="/" element={<AccountHome />} />
      <Route path="/organizations/new" element={<Add />} />
      <Route path="/organizations/payment" element={<Payment />} />
      <Route path="/organizations/complete" element={<Complete />} />
      <Route path="*" element={<Navigate to="/account" replace />} />
    </Routes>
  );
}

function MainRouter() {

  // Verify subscription and redirect if we need to finish payment
  const { getOrgInfo, hasPerm } = useAuth();
  const org = getOrgInfo();
  if (org.type === "business" && !org.subActive && org.subscriptionId === "") {
    return <Navigate to="/account/organizations/payment" />;
  }

  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/agents" element={<Agents />} />
      <Route path="/agents/:agentId" element={<Agent />} />
      <Route path="/groups" element={<AgentGroups />} />
      <Route path="/support" element={<Support />} />
      {hasPerm('User') && (
        <>
          <Route path="/configure/checks" element={<CheckConfigsHome />} />
          <Route path="/configure/checks/new" element={<CheckConfigForm />} />
          <Route path="/configure/checks/edit/:configId" element={<CheckConfigForm />} />
          <Route path="/configure/instances" element={<Instances />} />
          <Route path="/configure/plugins" element={<Plugins />} />
          <Route path="/configure/secrets" element={<Secrets />} />
        </>
      )}
      {hasPerm('Manager') && (
        <>
          <Route path="/agents/pending" element={<AgentsPending />} />
        </>
      )}
      {hasPerm('Admin') && (
        <>
          <Route path="/admin/settings" element={<Settings />} />
          <Route path="/admin/auditlog" element={<AuditLog />} />
        </>
      )}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;

import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AddBoxIcon from '@mui/icons-material/AddBox';
import CloseIcon from '@mui/icons-material/Close';

import { useAuth } from '../../context/auth';
import { useOrganizationAPIKey } from '../../hooks/organizations';
import { PRODUCTION } from 'config';
import { CodeBox } from '../../components/layout';
import { IconButton } from '@mui/material';

const getManagerLocation = (data) => {
  switch (window.location.hostname) {
    case "manage.rechecked.io":
      return `manager:
  apikey: ${data ? data.apikey : "..."}`;
    case "localhost":
      return `manager:
  url: ${window.location.protocol + "//localhost:8080"}
  apikey: ${data ? data.apikey : "..."}`;
    default:
  }
  return `manager:
  url: ${window.location.protocol + "//" + window.location.host + "/api"}
  apikey: ${data ? data.apikey : "..."}`;
};

function AddAgentsButton({ mobile }) {

  const [open, setOpen] = useState(false);

  const { getOrgInfo, selectedOrgId } = useAuth();
  const { data } = useOrganizationAPIKey(open, selectedOrgId);

  const org = getOrgInfo();
  if (!org.subActive && org.type === "business") {
    return;
  }
  
  return (
    <>
      {mobile ? (
        <IconButton onClick={() => setOpen(true)} size="large" color="inherit">
          <AddBoxIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <Button color="secondary" variant="contained" onClick={() => setOpen(true)}>Add Agents</Button>
      )}
      <AddAgentsModal open={open} setOpen={setOpen} data={data} mobile={mobile} />
    </>
  );
}

function AddAgentsModal({ open, setOpen, data, mobile }) {

  const [dev, setDev] = useState(true);
  const [tab, setTab] = useState("linux");
  const [version, setVersion] = useState("");

  useEffect(() => {
    async function checkVersion() {
      const resp = await fetch("https://api.rechecked.io/versions?product=rcagent");
      const data = await resp.json();
      if (data?.latest) {
        setVersion(data.latest);
      }
    }
    checkVersion();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setTab("linux");
    }, 300)
  };

  return (
    <Dialog fullWidth fullScreen={mobile} maxWidth={"md"} open={open} onClose={handleClose}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <DialogTitle>Add Agents</DialogTitle>
        {mobile && (
          <IconButton
            onClick={handleClose}
            aria-label="close"
            sx={{ pr: 2 }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
      <DialogContent>
        <Stack spacing={1}>
          <DialogContentText>To add an agent, you'll need to download ReChecked Agent and install it on the machines you want to run checks, then follow the instructions below to set up your agents to sync with the manager.</DialogContentText>
          <Stack sx={{ width: '100%', mx: 1 }} spacing={2} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" sx={{ pt: 2 }}>Step 1: Install Agent</Typography>
            {!PRODUCTION && (
              <Typography variant="body2">Stable <Switch color="secondary" size="small" checked={dev} onChange={e => setDev(!dev)} /> Development</Typography>
            )}
          </Stack>
          {PRODUCTION ? (
            <ShowDownloads tab={tab} setTab={setTab} type="stable" version={version} />
          ) : (
            <ShowDownloads tab={tab} setTab={setTab} type={dev ? "dev" : "stable"} version={dev ? "dev" : version} />
          )}
          <DialogContentText sx={{ fontSize: 12 }} variant="body2">For more information about installing the agent, see the <Link href="https://rechecked.io/docs/rcagent/getting-started/installation/">rcagent install documentation</Link>.</DialogContentText>
          <Typography variant="h6" sx={{ pt: 2 }}>Step 2: Configure ReChecked Agent Sync</Typography>
          <DialogContentText>Once you have installed or deployed the agents, you will need to edit the <b><code>config.yml</code></b> config file. <u>Make sure you change the <b><code>token</code></b> value to something secure.</u> Then add the following below at the end:</DialogContentText>
          <DefaultConfigLocation tab={tab} />
          <CodeBox canCopy>
            {getManagerLocation(data)}
          </CodeBox>
          <DialogContentText sx={{ fontSize: 12 }} variant="body2">For more information about this section of the config, see the <Link href="https://rechecked.io/docs/rcagent/config/manager/">rcagent config documentation</Link>.</DialogContentText>
          <Typography variant="h6" sx={{ pt: 2 }}>Step 3: Start Agent</Typography>
          <StartAgent tab={tab} />
          <Typography variant="h6" sx={{ pt: 2 }}>Step 4: Verify Agent Syncing</Typography>
          <DialogContentText>Now that your agent is configured to work with the cloud version of the manager, you can verify the agent is connecting properly by looking for it in your <Link color="secondary" component={RouterLink} onClick={handleClose} to={`/agents`}>Agents</Link> page.</DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function ShowDownloads({ type, tab, setTab, version }) {

  const [osTab, setOsTab] = useState("rpm");
  const [osSelect, setOSSelect] = useState("el9.x86_64.rpm");

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={(e, t) => setTab(t)} aria-label="install system selection">
          <Tab label="Linux (Repo)" value="linux" />
          <Tab label="Linux (Manual)" value="linux-manual" />
          <Tab label="Windows" value="windows" />
          <Tab label="macOS" value="macos" />
          <Tab label="Other (Source)" value="source" />
        </TabList>
      </Box>
      <TabPanel sx={{ p: 0 }} value="linux">
        <Stack spacing={1}>
          <DialogContentText>We recommend using the package manager to install rcagent. You can do this on CentOS, RHEL, Ubunut, or Debian. For installation on other Linux systems, do manual install or install from source.</DialogContentText>
          <TabContext value={osTab}>
            <TabList onChange={(e, t) => setOsTab(t)} aria-label="install type selection">
              <Tab label="CentOS / RHEL" value="rpm" />
              <Tab label="Debian / Ubuntu" value="deb" />
            </TabList>
            <TabPanel sx={{ p: 0 }} value="rpm">
              <CodeBox canCopy>
                {`. /etc/os-release
sudo rpm -v --import https://repo.rechecked.io/rechecked-pub.gpg
sudo yum-config-manager --add-repo https://repo.rechecked.io/rpm/${type === "dev" ? "el$VERSION_ID-dev" : "el$VERSION_ID"}/rechecked.repo
sudo yum install rcagent`}
              </CodeBox>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="deb">
              <CodeBox canCopy>
                {`sudo wget -qO - https://repo.rechecked.io/rechecked-pub.gpg | gpg --dearmor | sudo tee /etc/apt/trusted.gpg.d/rechecked-pub.gpg > /dev/null
sudo echo "deb https://repo.rechecked.io/deb${type === "dev" ? "/dev" : ""} $(lsb_release -cs) main" > /etc/apt/sources.list.d/rechecked.list
sudo apt-get update
sudo apt-get install rcagent`}
              </CodeBox>
            </TabPanel>
          </TabContext>
        </Stack>
      </TabPanel>
      <TabPanel sx={{ p: 0 }} value="linux-manual">
        <Stack spacing={1}>
          <DialogContentText>Manual installaton of RPM and DEB packages are available for CentOS, RHEL, Ubuntu, and Debian. For other Linux systems, you will need to build from source.</DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="os-select">Operating System</InputLabel>
            <Select labelId="os-select"
              value={osSelect}
              label="Operating System"
              onChange={(e) => setOSSelect(e.target.value)}
              size="small"
            >
              <MenuItem value="el9.x86_64.rpm">CentOS / RHEL 9</MenuItem>
              <MenuItem value="el8.x86_64.rpm">CentOS / RHEL 8</MenuItem>
              <MenuItem value="ubuntu22.amd64.deb">Ubuntu 22</MenuItem>
              <MenuItem value="ubuntu20.amd64.deb">Ubuntu 20</MenuItem>
              <MenuItem value="ubuntu18.amd64.deb">Ubuntu 18</MenuItem>
              <MenuItem value="deb12.amd64.deb">Debian 12</MenuItem>
              <MenuItem value="deb11.amd64.deb">Debian 11</MenuItem>
              <MenuItem value="deb10.amd64.deb">Debian 10</MenuItem>
            </Select>
          </FormControl>
          <DialogContentText><Link color="secondary" href={type === "dev" ? `https://downloads.rechecked.io/dev/rcagent-${type === "dev" ? version : version+"-1"}.${osSelect}` : `https://github.com/rechecked/rcagent/releases/download/v${version}/rcagent-${type === "dev" ? version : version+"-1"}.${osSelect}`}>Download the Agent</Link> or use the wget command below.</DialogContentText>
          <CodeBox canCopy>
            {type === "dev" ? `wget https://downloads.rechecked.io/dev/rcagent-${type === "dev" ? version : version+"-1"}.${osSelect}\n` : `wget https://github.com/rechecked/rcagent/releases/download/v${version}/rcagent-${type === "dev" ? version : version+"-1"}.${osSelect}\n`}
            {osSelect.includes("rpm") ? `sudo rpm -ivh rcagent-${type === "dev" ? version : version+"-1"}.${osSelect}` : `sudo dpkg -i rcagent-${type === "dev" ? version : version+"-1"}.${osSelect}`}
          </CodeBox>
        </Stack>
      </TabPanel>
      <TabPanel sx={{ p: 0 }} value="windows">
        <Stack spacing={1}>
          <Link color="secondary" href={type === "dev" ? `https://downloads.rechecked.io/dev/rcagent-dev.msi` : `https://github.com/rechecked/rcagent/releases/download/v${version}/rcagent-${version}.msi`}>
            Download the latest {type === "dev" ? "Development" : "Stable"} version for Windows
          </Link>
          <DialogContentText>The msi can be installed manually or you can install the package using the command line:</DialogContentText>
          <CodeBox canCopy>
            {`msiexec /i rcagent-${version}.msi `}
          </CodeBox>
        </Stack>
      </TabPanel>
      <TabPanel sx={{ p: 0 }} value="macos">
        <Stack spacing={1}>
          <Link color="secondary" href={type === "dev" ? `https://downloads.rechecked.io/dev/rcagent-dev.dmg` : `https://github.com/rechecked/rcagent/releases/download/v${version}/rcagent-${version}.dmg`}>
            Download the latest {type === "dev" ? "Development" : "Stable"} version for macOS
          </Link>
          <DialogContentText>Mount the dmg file by double clicking it. Run the following command with root permissions or via sudo:</DialogContentText>
          <CodeBox canCopy>
            {`sudo zsh /Volumes/rcagent-${version}/install.sh`}
          </CodeBox>
        </Stack>
      </TabPanel>
      <TabPanel sx={{ p: 0 }} value="source">
        <Stack spacing={1}>
          <DialogContentText>If you'd like to run rcagent on a system we don't build for, you'll have to install from source. This is normally a fairly straightforward process, but note that some features may not work properly if they are not implemented on the system you are running it on.</DialogContentText>
          <Alert severity="info">You will need golang (from <Link href="https://go.dev">go.dev</Link> or install with our OS package manager) and you will need make as well.</Alert>
          <DialogContentText>Download the source files from GitHub:</DialogContentText>
          <CodeBox canCopy>
            {`wget https://github.com/rechecked/rcagent/archive/refs/heads/${type === "dev" ? "develop" : "main"}.zip`}
          </CodeBox>
          <DialogContentText>Unzip the files and <b><code>cd</code></b> into the directory and run the build and install:</DialogContentText>
          <CodeBox canCopy>
            {`make build
make install`}
          </CodeBox>
        </Stack>
      </TabPanel>
    </TabContext>
  );
}

function StartAgent({ tab }) {
  switch (tab) {
    case "linux":
    case "linux-manual":
      return (
        <Stack spacing={1}>
          <DialogContentText>Be sure to enable and <i>start (or restart) the agent</i> after the config changes.</DialogContentText>
          <CodeBox canCopy>
            {`systemctl enable rcagent
systemctl start rcagent`}
          </CodeBox>
        </Stack>
      );
    case "windows":
      return (
        <Stack spacing={1}>
          <DialogContentText>Using the command line or powershell, <i>start (or restart) the agent</i> after config changes.</DialogContentText>
          <CodeBox canCopy>
            {`sc.exe start rcagent`}
          </CodeBox>
        </Stack>
      );
    case "macos":
      return (
        <Stack spacing={1}>
          <DialogContentText>Using the command line or powershell, <i>start (or restart) the agent</i> after config changes.</DialogContentText>
          <CodeBox canCopy>
            {`sudo launchctl start io.rechecked.rcagent`}
          </CodeBox>
        </Stack>
      );
    case "source":
      return (
        <Stack spacing={1}>
          <DialogContentText>Make sure you <i>start the agent</i> from the directory you built from.</DialogContentText>
          <CodeBox canCopy>
            {`/usr/local/rcagent/rcagent`}
          </CodeBox>
          <DialogContentText>You can also run the agent as a service on most OS by running the service install. Once you do this you should be able to use your service manager for the operating system to start/stop/restart the service.</DialogContentText>
          <CodeBox canCopy>
            {`/usr/local/rcagent/rcagent -a install`}
          </CodeBox>
        </Stack>
      );
    default:
      return null;
  }
}

function DefaultConfigLocation({ tab }) {
  switch (tab) {
    case "linux":
    case "linux-manual":
    case "macos":
      return (
        <DialogContentText>Default config location: <b><code>/etc/rcagent/config.yml</code></b></DialogContentText>
      );
    case "windows":
      return (
        <DialogContentText>Default config location: <b><code>C:\Program Files\rcagent\config.yml</code></b></DialogContentText>
      );
    case "source":
      return (
        <DialogContentText>Default config location: <b><code>/usr/local/rcagent/config.yml</code></b></DialogContentText>
      );
    default:
      return null;
  }
}

export default AddAgentsButton;
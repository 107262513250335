import { useState } from 'react';

import Button from '@mui/material/Button';

import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

import GroupModal from 'components/modals/GroupModal';

function AddGroupButton() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)} startIcon={<LibraryAddIcon />}>Create Group</Button>
      <GroupModal open={open} setOpen={setOpen} />
    </>
  );
}

export default AddGroupButton;
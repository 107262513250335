import { forwardRef } from 'react';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';

import { useAuth } from 'context/auth';
import { usePlugins } from 'hooks/organizations';

const PluginSelect = forwardRef(function PluginSelect(props, ref) {

  const { selectedOrgId } = useAuth();
  const { data: plugins } = usePlugins(selectedOrgId);

  return (
    <TextField select fullWidth size="small" label="Plugin" {...props} ref={ref}>
      <MenuItem name="" value="manual">Manually Defined</MenuItem>
      <ListSubheader>Available Plugins</ListSubheader>
      {plugins && plugins.map(p => (
        <MenuItem key={p.id} name={p.name} value={p.id}>{p.name}</MenuItem>
      ))}
    </TextField>
  );
});

export default PluginSelect;
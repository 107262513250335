import { useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import { useCheckConfig } from 'context/checks';
import { CustomIconButton } from 'components/buttons';
import { EditHostCheckButton, EditServiceCheckButton } from 'components/buttons/config';
import ServiceChecksDrawer from './ServiceChecksDrawer';

function ChecksTable({ type, host }) {

  const { checks, setChecks } = useCheckConfig();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hostname, setHostname] = useState('');

  const handleDeleteHostCheck = (hostname) => {
    setChecks(prevState => prevState.filter(c => c.hostname !== hostname));
  };

  const handleDeleteServiceCheck = (hostname, servicename) => {
    setChecks(prevState => {
      const index = prevState.findIndex(c => c.hostname === hostname);
      let newChecks = prevState;
      newChecks[index].services = newChecks[index].services.filter(s => s.servicename !== servicename);
      return [...newChecks];
    });
  };

  const handleEditServicesClick = (hostname) => {
    setHostname(hostname);
    setDrawerOpen(true);
  };

  const handleCopy = (check) => {
    let newCheck = structuredClone(check);
    if (type === "hosts") {
      newCheck.hostname = addCopyText(checks, newCheck.hostname);
      newCheck.services.forEach(s => s.hostname = newCheck.hostname);
      setChecks(prevState => [...prevState, newCheck]);
    } else {
      const index = checks.findIndex(c => c.hostname === newCheck.hostname);
      newCheck.servicename = addCopyText(checks[index].services, newCheck.servicename);
      setChecks(prevState => {
        const index = prevState.findIndex(c => c.hostname === newCheck.hostname);
        let newChecks = prevState;
        newChecks[index].services.push(newCheck);
        return [...newChecks];
      });
    }
  };

  const addCopyText = (checks, name) => {
    name = name += " Copy";
    const index = checks.findIndex(c => type === "hosts" ? c.hostname === name : c.servicename === name);
    if (index !== -1) {
      return addCopyText(checks, name);
    }
    return name;
  }

  // Filter checks if type is service
  let filteredChecks = checks;
  if (type === 'services') {
    filteredChecks = checks.find(c => c.hostname === host).services;
  }

  return (
    <TableContainer variant="outlined" component={Card}>
      <Table>
        <TableBody>
          {filteredChecks.map(check => (
            <TableRow key={type === 'hosts' ? check.hostname : check.servicename}>
              <TableCell>
                <Typography sx={{ fontWeight: 'bold' }}>{type === 'hosts' ? check.hostname : check.servicename}</Typography>
              </TableCell>
              <TableCell>{check.interval}</TableCell>
              <TableCell>{check.endpoint}</TableCell>
              {type !== 'services' && (
                <TableCell>
                  <Chip variant="outlined" color="secondary" label={check.services?.length + " Service Checks"} onClick={() => handleEditServicesClick(check.hostname)} />
                </TableCell>
              )}
              <TableCell padding="checkbox">
                <CustomIconButton onClick={() => handleCopy(check)} type="copy" title="Make Copy" />
              </TableCell>
              <TableCell padding="checkbox">
                {type === "hosts" ?
                  <EditHostCheckButton hostname={check.hostname} />
                  : <EditServiceCheckButton hostname={check.hostname} servicename={check.servicename} />
                }
              </TableCell>
              <TableCell padding="checkbox">
                {type === "hosts" ?
                  <CustomIconButton onClick={() => handleDeleteHostCheck(check.hostname)} type="delete" title="Delete" />
                  : <CustomIconButton onClick={() => handleDeleteServiceCheck(check.hostname, check.servicename)} type="delete" title="Delete" />
                }
              </TableCell>
            </TableRow>
          ))}
          {filteredChecks.length === 0 && (
            <TableRow sx={{ height: 64 }}>
              <TableCell>No {type} added to the config.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {type === 'hosts' && <ServiceChecksDrawer hostname={hostname} open={drawerOpen} setOpen={setDrawerOpen} />}
    </TableContainer>
  );
}

export default ChecksTable;
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import QRCode from "qrcode";

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { useAuth } from 'context/auth';
import { use2FAInfo, useEnable2FA, useDisable2FA } from 'hooks/account';

function Setup2FAModal({ open, setOpen }) {

  const { otpEnabled, setOtpEnabled } = useAuth();
  const { data } = use2FAInfo({ enabled: open });

  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [tokenError, setTokenError] = useState('');

  const enableMutate = useEnable2FA();
  const disableMutate = useDisable2FA();

  const { control, formState: { errors }, handleSubmit, reset } = useForm({
    defaultValues: {
      otpToken: ''
    }
  });

  const handleClose = () => {
    setOpen(false);
    setTimeout(reset, 200);
    setTokenError("");
  };

  const onSubmit = (data) => {
    if (otpEnabled) {
      disableMutate.mutate(data, {
        onSuccess: () => {
          handleClose();
          setOtpEnabled(false);
        },
        onError: () => {
          setTokenError("Invalid authentication code");
        }
      })
    } else {
      enableMutate.mutate(data, {
        onSuccess: () => {
          handleClose();
          setOtpEnabled(true);
        },
        onError: () => {
          setTokenError("Invalid authentication code");
        }
      })
    }
  };

  useEffect(() => {
    if (data) {
      QRCode.toDataURL(data.otpauthUrl).then(setQrCodeUrl);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Two-Factor Authentication (2FA)</DialogTitle>
        <DialogContent>
          {!otpEnabled && (
            <DialogContentText sx={{ mb: 2 }}>
              You can use Google Authenticator or any 2FA authentication app on your phone or computer. Open your authenticator app and click "+" or "add" then scan the QR code or manually enter a code.
            </DialogContentText>
          )}
          {!otpEnabled && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "bold" }}>Scan QR Code</Typography>
                <Box sx={{ width: 228, height: 228 }}>
                  <img src={qrCodeUrl} alt="authenticator qrcode url" />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={1}>
                  <Typography sx={{ fontWeight: "bold" }}>Manually Enter Code</Typography>
                  <Typography>Secret Key:</Typography>
                  <Typography>{data && data.base32}</Typography>
                  <Typography variant="body2">(Base32 encoded)</Typography>
                </Stack>
              </Grid>
            </Grid>
          )}
          <Stack spacing={1}>
            <Typography sx={{ fontWeight: "bold" }}>Verify Code</Typography>
            <Typography sx={{ pb: 1 }}>To {otpEnabled ? 'disable' : 'enable'} 2FA please enter an auth code from your authenticator.</Typography>
            <Controller
              name="otpToken"
              control={control}
              render={({ field }) => <TextField fullWidth required size="small" label="Authentication Code" {...field}
                helperText={errors.otpToken?.message || tokenError} error={errors.otpToken !== undefined || tokenError !== ''} />
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color="secondary">Verify and {otpEnabled ? 'Disable' : 'Enable'}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default Setup2FAModal;
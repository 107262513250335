import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';
import { Helmet } from 'react-helmet-async';

import useMediaQuery from '@mui/material/useMediaQuery';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddTaskIcon from '@mui/icons-material/AddTask';

import { Page, LoadingCircle } from 'components/layout';
import { ApplyToChips } from 'components/layout/config';
import { CustomIconButton } from 'components/buttons';
import { DeleteConfigButton } from 'components/buttons/config';
import { SortTableHeader } from 'components/table';
import { useAuth } from 'context/auth';
import { useConfigs } from 'hooks/organizations';
import { formatLocalTime } from 'utils/common';

const headerCells = [
  {
    id: 'name',
    label: 'Config Name',
    sortable: true
  },
  {
    id: 'hosts',
    label: 'Hosts',
  },
  {
    id: 'services',
    label: 'Services',
  },
  {
    id: 'agents',
    label: 'Agents Applied To',
  },
  {
    id: 'updated',
    label: 'Last Updated',
  },
  {
    id: 'edit'
  },
  {
    id: 'delete'
  }
];

function ConfigsHome() {

  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [query, setQuery] = useQueryParams({
    search: StringParam
  });

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const { selectedOrgId } = useAuth();
  const { isLoading, data: configs } = useConfigs(selectedOrgId);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const clearFilters = () => {
    setQuery({
      search: undefined
    });
  };

  const getFilteredConfigs = () => {
    if (query.search && query.search !== '' && configs) {
      return configs.filter(c => {
        if (c.name.toLowerCase().indexOf(query.search.trim().toLowerCase()) > -1) {
          return c;
        }
        return null;
      });
    }
    return configs;
  };

  const filteredConfigs = getFilteredConfigs();

  return (
    <Page>
      <Helmet>
        <title>Check Configurations &middot; ReChecked Manager</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Check Configurations</Typography>
          <Typography>Create passive check configurations for agents or agent groups.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={mobileSize ? "column" : "row"} spacing={2} justifyContent="space-between" alignItems="center">
            <Button component={RouterLink} to="/configure/checks/new" variant="contained" color="secondary" startIcon={<AddTaskIcon />}>Create Config</Button>
            <Stack direction="row" spacing={2}>
              {query.search && (<Button onClick={clearFilters}>Clear</Button>)}
              <TextField size="small" label="Search" value={query.search ?? ''} onChange={(e) => setQuery({ search: e.target.value })} />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <LoadingCircle />
          ) : (
            <TableContainer variant="outlined" component={Card}>
              <Table aria-label="rcagent list table">
                <SortTableHeader columns={headerCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {configs && filteredConfigs.map((config) => (
                    <TableRow
                      key={config.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="config">
                        <Link component={RouterLink} to={`/configure/checks/edit/${config.id}`} underline="hover">
                          {config.name}
                        </Link>
                      </TableCell>
                      <TableCell>{config.hosts}</TableCell>
                      <TableCell>{config.services}</TableCell>
                      <TableCell>
                        <ApplyToChips config={config} />
                      </TableCell>
                      <TableCell>
                        {formatLocalTime(config.updated)}
                      </TableCell>
                      <TableCell padding="checkbox">
                        <CustomIconButton type="edit" component={RouterLink} to={`/configure/checks/edit/${config.id}`} />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <DeleteConfigButton configId={config.id} />
                      </TableCell>
                    </TableRow>
                  ))}
                  {configs && filteredConfigs.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        {query.search ?
                          "No check configs match your filters or search criteria." :
                          "You have no check configs added to your organization yet."}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default ConfigsHome;
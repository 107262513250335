import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

function TooltipIconButton({ icon, tooltip, placement, ...props }) {
  return (
    <Tooltip title={tooltip} placement={placement ? placement : "top"}>
      <span>
        <IconButton {...props}>
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default TooltipIconButton;
import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import moment from 'moment';
import { compareVersions } from 'compare-versions';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import Gauge from 'components/charts/Gauge';
import { MemoryTable, ServicesList, ProcessesList,
  UsersList, PluginsList, DiskList, NetworkList, APIViewer } from 'components/dashboard';
import { useAgent, useAgentOverview } from 'hooks/useAgent';
import { isEmpty, ucFirst, platformName } from 'utils/common';
import { buildApiURL } from 'utils/network';
import { DashboardSettingsButton, ExampleCheckButton } from 'components/buttons';
import { useAgentView } from 'context/viewer';

//import { useAuth } from 'context/auth';
//import { useAgents } from 'hooks/organizations';

function AgentViewer({ agent: dashboardAgent }) {

  //const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setAgent, view } = useAgentView();

  const [upgrade, setUpgrade] = useState(false);

  // Get data for the dashboard
  const { isInitialLoading, failureCount, data: systemInfo } = useAgent(dashboardAgent, view);
  const { isInitialLoading: overviewIsLoading, data: overview } = useAgentOverview(dashboardAgent, view);

  // Verify we have agent info or redirect
  /*
  useEffect(() => {
    if (isEmpty(dashboardAgent)) {
      navigate('/agents');
    }
  }, [dashboardAgent, navigate]);
  */

  useEffect(() => {
    setAgent(dashboardAgent);
  }, [setAgent, dashboardAgent]);

  useEffect(() => {
    async function checkVersion() {
      const resp = await fetch("https://api.rechecked.io/versions?product=rcagent");
      const data = await resp.json();
      if (systemInfo?.version && compareVersions(systemInfo.version, data.latest) === -1) {
        setUpgrade(true);
      }
    }
    if (systemInfo) {
      checkVersion();
    }
  }, [systemInfo]);

  const handleTabSelect = (e, tabVal) => {
    let params = { tab: tabVal };
    if (searchParams.get('section')) {
      params.section = searchParams.get('section');
    }
    setSearchParams(params);
  }

  const handleReApprove = () => {
    var top = (window.innerHeight - 600) / 2;
    var left = (window.innerWidth - 400) / 2;
    window.open(buildApiURL(dashboardAgent, view, 'system'),'_blank',`height=600,width=400,top=${top},left=${left},titlebar=Re-Approve Security Exception`);
  };

  const agentUrl = `${dashboardAgent.protocol}//${view === 'hostname' ? dashboardAgent.hostname : dashboardAgent.address}:${dashboardAgent.port}`;

  return (
    <>
      {isInitialLoading || isEmpty(systemInfo) || failureCount > 0 ? (
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Grid item>
            {failureCount > 0 ? (
              <Stack spacing={4} alignItems="center">
                <Alert severity="error">
                  <AlertTitle>Could not connect to <Link color="inherit" href={agentUrl} target="_blank" rel="noopener noreferrer">{dashboardAgent.hostname}</Link></AlertTitle>
                  There are a few reasons that this could happen:
                  <ul style={{ margin: 0, padding: '5px 25px' }}>
                    <li>You are using hostname instead of address and the hostname is invalid (change slider in upper right corner) </li>
                    <li>The agent cannot be reached if it is not on the same network as you</li>
                    <li>No rcagent running on the host</li>
                    <li>You are using a self-signed SSL certificate and need to re-approve the exception</li>
                    <li>If you are using an IP address the host IP may have changed</li>
                  </ul>
                </Alert>
                <Stack direction="row" spacing={2}>
                  <Button variant="outlined" color="secondary" onClick={handleReApprove}>Re-Approve Exception</Button>
                </Stack>
              </Stack>
            ) : <CircularProgress color="secondary" />}
          </Grid>
        </Grid>
      ) : systemInfo.status === "error" ? <Alert severity="error">{systemInfo.message}</Alert> : (
        <Grid container alignItems="start" justifyContent="space-between" spacing={4}>
          <Grid item>
            <Stack direction="row" spacing={4}>
              <Box>
                <Typography variant="overline">Hostname</Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{systemInfo.hostname}</Typography>
                <Typography variant="body1" sx={{ fontSize: 12 }}>{dashboardAgent.address}</Typography>
              </Box>
              <Box>
                <Typography variant="overline">Operating System</Typography>
                <Typography variant="body1">{systemInfo.os && ucFirst(systemInfo.os)}</Typography>
                <Typography variant="body1" sx={{ fontSize: 12 }}>{platformName(systemInfo.platform)} {systemInfo.os === "linux" ? systemInfo.platformVersion : null}</Typography>
              </Box>
              <Box>
                <Typography variant="overline">Uptime</Typography>
                <Tooltip title={moment(systemInfo.bootTime*1000).format('LLLL')}>
                  <Typography variant="body1">{moment.duration(systemInfo.uptime, 'seconds').humanize()}</Typography>
                </Tooltip>
              </Box>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={4}>
              <Box>
                <Typography variant="overline">Agent Version</Typography>
                <Typography variant="body1">{systemInfo.version}</Typography>
                {upgrade && <Typography variant="body1" sx={{ fontSize: 12, color: 'red' }}>Update available</Typography>}
              </Box>
              <Box>
                <DashboardSettingsButton agent={dashboardAgent} />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TabContext value={searchParams.get('tab') ?? 'overview'}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between', display: 'flex' }}>
                <Tabs
                  value={searchParams.get('tab') ?? 'overview'}
                  onChange={handleTabSelect}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="dashboard component tabs"
                >
                  <Tab label="Overview" value="overview" />
                  <Tab label="Disk" value="disk" />
                  <Tab label="Network" value="network" />
                  <Tab label={<div>Processes</div>} value="processes" />
                  <Tab label="Services" value="services" />
                  <Tab label="Users" value="users" />
                  <Tab label="Plugins" value="plugins" />
                  <Tab label="API Viewer" value="api" />
                </Tabs>
              </Box>
              <TabPanel sx={{ p: 0, pt: 3 }} value="overview">
                <Box sx={{ display: "flex"}}>
                  <Grid container spacing={4}>
                    <Grid item width={400}>
                      <Box sx={{ height: 150 }}>
                        <Gauge label="CPU" value={overviewIsLoading ? null : overview.cpu?.percent[0]} />
                      </Box>
                      <Stack direction="column" alignItems="center" spacing={2}> 
                        <ExampleCheckButton size="small" variant="outlined" type="cpu/percent" />
                      </Stack>
                    </Grid>
                    <Grid item width={400}>
                      <Box sx={{ height: 150 }}>
                        <Gauge label="Memory" value={overviewIsLoading ? null : overview.mem?.usedPercent} />
                      </Box>
                      <Stack direction="column" alignItems="center" spacing={2}>
                        <Box sx={{ width: '100%' }}>
                          <MemoryTable os={systemInfo.os} mem={overviewIsLoading ? null : overview.mem} />
                        </Box>
                        <ExampleCheckButton size="small" variant="outlined" type="memory/virtual" />
                      </Stack>
                    </Grid>
                    <Grid item width={400}>
                      <Box sx={{ height: 150 }}>
                        <Gauge label="Swap" value={overviewIsLoading ? null : overview.swap?.usedPercent} />
                      </Box>
                      <Stack direction="column" alignItems="center" spacing={2}>
                        <Box sx={{ width: '100%' }}>
                          <MemoryTable os={systemInfo.os} mem={overviewIsLoading ? null : overview.swap} />
                        </Box>
                        <ExampleCheckButton size="small" variant="outlined" type="memory/swap" />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel sx={{ p: 0, pt: 3 }} value="disk">
                <DiskList agent={dashboardAgent} />
              </TabPanel>
              <TabPanel sx={{ p: 0, pt: 3 }} value="network">
                <NetworkList agent={dashboardAgent} />
              </TabPanel>
              <TabPanel sx={{ p: 0, pt: 3 }} value="processes">
                <ProcessesList agent={dashboardAgent} />
              </TabPanel>
              <TabPanel sx={{ p: 0, pt: 3 }} value="services">
                <ServicesList agent={dashboardAgent} />
              </TabPanel>
              <TabPanel sx={{ p: 0, pt: 3 }} value="users">
                <UsersList agent={dashboardAgent} os={systemInfo.os} />
              </TabPanel>
              <TabPanel sx={{ p: 0, pt: 3 }} value="plugins">
                <PluginsList agent={dashboardAgent} />
              </TabPanel>
              <TabPanel sx={{ p: 0, pt: 3 }} value="api">
                <APIViewer agent={dashboardAgent} />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default AgentViewer;
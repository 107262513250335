import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useForm, Controller } from 'react-hook-form';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessIcon from '@mui/icons-material/Business';
import PortraitIcon from '@mui/icons-material/Portrait';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import Page from 'components/layout/Page';
import { ChangePasswordButton, Manage2FAButton } from 'components/buttons/account';
import { APIKeyViewer, ShowCACertButton } from 'components/secure';
import { BusinessTabs } from 'components/layout/organizations';
import { useAuth } from 'context/auth';
import { useOrganizations, useAccount, useEditAccount } from 'hooks/account';
import { SetDefaultOrgIconButton } from 'components/buttons';
import AdminTab from 'components/layout/organizations/AdminTab';

function Home() {

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const mutateEdit = useEditAccount();
  const { isLoading, data } = useAccount();

  const { control, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      email: ''
    },
    values: {
      name: data ? data.name : '',
      email: data ? data.email : ''
    }
  });

  const onSubmit = (data) => {
    mutateEdit.mutate(data,
      {
        onSuccess: () => {
          setAlert({ severity: 'success', message: 'Account info has been updated.' });
        },
        onError: () => {
          setAlert({ severity: 'error', message: "Could not save account info. Email may already be in use." });
        },
        onSettled: () => {
          setLoading(false);
        }
      });
  };

  return (
    <Page>
      <Helmet>
        <title>My Account &middot; ReChecked Manager</title>
      </Helmet>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Stack>
              <Typography variant="h4">My Account</Typography>
              <Typography>Manage your organizations and edit your personal account settings, password, and security preferences.</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
          {alert && (
            <Alert sx={{ mb: 2 }} severity={alert.severity} onClose={() => setAlert(null)}>{alert.message}</Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} alignItems="flex-start">
              <Controller
                name="name"
                control={control}
                rules={{ required: "Name is required" }}
                disabled={isLoading}
                render={({ field }) => <TextField
                  size="small" label="Name" fullWidth required
                  {...field}
                  helperText={errors.name?.message} error={errors.name !== undefined}
                />}
              />
              <Controller
                name="email"
                control={control}
                rules={{ required: "Email address is required" }}
                disabled={isLoading}
                render={({ field }) => <TextField
                  size="small" label="Email Address" fullWidth required
                  {...field}
                  helperText={errors.email?.message} error={errors.email !== undefined}
                />}
              />
              <Button disabled={loading} color="secondary" type="submit">Save Changes</Button>
            </Stack>
          </form>
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
          <Stack spacing={2} alignItems="flex-start">
            <ChangePasswordButton />
            <Manage2FAButton />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Organizations />
        </Grid>
      </Grid>
    </Page>
  );
}

function Organizations() {

  const { options, selectedOrgId } = useAuth();
  const { data: organizations } = useOrganizations();

  const [expanded, setExpanded] = useState(selectedOrgId);

  return (
    <Stack spacing={2}>
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>Organizations</Typography>
      <Box>
        {organizations && organizations.length > 0 && organizations.map(o => (
          <Accordion TransitionProps={{ unmountOnExit: true }} key={o.id} expanded={expanded === o.id} onChange={() => setExpanded(o.id)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={o.id+"p-content"}
              id={o.id+"p-header"}
              className="test"
            >
              <Stack direction="row" spacing={2} alignItems="center">
                {o.type === "business" ? <Tooltip placement="top" title="Business"><BusinessIcon /></Tooltip> : <Tooltip placement="top" title="Personal"><PortraitIcon /></Tooltip>}
                <Typography sx={{ fontWeight: 'bold' }}>{o.name}</Typography>
                {o.type === "business" && !o.subActive && o.subscriptionId === "" && <Tooltip placement="top" title="No active subscription"><WarningAmberIcon color="warning" /></Tooltip>}
                {o.id === options.defaultOrgId && <Tooltip placement="top" title="The default organization selected when you log in"><Chip label="Default" size="small" /></Tooltip>}
                {o.id === selectedOrgId && <Tooltip placement="top" title="The organization you are currently using"><Chip label="Selected" size="small" /></Tooltip>}
              </Stack>
            </AccordionSummary>
            <OrganizationAccordian org={o} />
          </Accordion>
        ))}
        {organizations && organizations.length === 0 && <Typography>You are not a part of any organizations yet.</Typography>}
      </Box>
    </Stack>
  );
}

function OrganizationAccordian({ org }) {

  const navigate = useNavigate();
  const { toggleSelectedOrgId, hasPerm } = useAuth();

  const handleSwitch = () => {
    toggleSelectedOrgId(org.id);
    navigate("/account/organizations/payment");
  };

  return (
    <AccordionDetails>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {org.type === "business" && !org.subActive && org.subscriptionId === "" ? (
          <Grid item xs={12}>
            <Alert severity="warning">
              <AlertTitle>No Active Subscription</AlertTitle>
              Organization does not have a subscription set up yet. <Link component="button" onClick={handleSwitch}>Switch to this organization</Link> and complete the subscription payment.
            </Alert>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} lg={3}>
              <Stack spacing={1}>
                <Typography>Subscription: {org.subName}</Typography>
                <Typography>Agent Usage: {org.agents} / {org.maxAgents}</Typography>
                {org.type === "business" && <Typography>Users: {org.users} / {org.maxUsers}</Typography>}
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <SetDefaultOrgIconButton orgId={org.id} />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack spacing={2}>
                {hasPerm('User') && <APIKeyViewer orgId={org.id} />}
                <ShowCACertButton orgId={org.id} />
              </Stack>
            </Grid>
            {org.type === "business" && hasPerm('Manager') && (
              <Grid item xs={12}>
                <BusinessTabs org={org} />
              </Grid>
            )}
            {org.type === "personal" && (
              <Grid item xs={12}>
                <Divider sx={{ mb: 2 }} />
                <AdminTab orgId={org.id} />
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Typography variant="body2">
      </Typography>
    </AccordionDetails>
  );
}

export default Home;
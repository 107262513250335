import { useState } from 'react';

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

function ConfirmModalPassword({ title, text, open, setOpen, okText, cancelText, onConfirm, warning }) {

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setError(""), 200);
    setIsLoading(false);
  };

  const handleConfirm = async () => {
    setError("");
    if (password.trim() === "") {
      setError("You must enter your password");
      return;
    }
    setIsLoading(true);
    const [error, message] = await onConfirm(password.trim());
    setIsLoading(false);
    if (error) {
      setError(message);
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>}
      <DialogContent>
        <Stack spacing={2}>
          {warning && <Alert severity="warning">{warning}</Alert>}
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
          <DialogContentText>Please enter your password to verify you want to continue with this action.</DialogContentText>
          <TextField value={password} onChange={(e) => setPassword(e.target.value)}
            name="confPassword" type="password" size="small" label="Confirm Password"
            error={error !== ""} helperText={error} autoFocus />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{cancelText ? cancelText : "Cancel"}</Button>
        <LoadingButton loading={isLoading} onClick={handleConfirm} color="error">
          {okText ? okText : "Ok"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModalPassword;
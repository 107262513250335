import { Link as RouterLink } from 'react-router-dom';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import CircleIcon from '@mui/icons-material/Circle';
import ListAltIcon from '@mui/icons-material/ListAlt';

import { GroupSettingsButton, TooltipIconButton } from 'components/buttons';

function AgentGroup({ group, readOnly }) {
  return (  
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Typography variant="h6">{group.name}</Typography>
            <Stack direction="row" spacing={1}>
              <TooltipIconButton component={RouterLink} to={`/agents?group=${group.id}`} icon={<ListAltIcon fontSize="small" />} tooltip="View group agents" />
              {!readOnly && <GroupSettingsButton groupId={group.id} />}
            </Stack>
          </Stack>
          {group.agents > 0 ? (
            <>
            <Stack direction="row" spacing={2}>
              <CircleIcon color="success" />
              <Link component={RouterLink} to={`/agents?group=${group.id}&status=1`} underline="hover">
                <Typography variant="body1">{group.checkingIn} Checking In</Typography>
              </Link>
            </Stack>
            <Stack direction="row" spacing={2}>
              <CircleIcon color="disabled" />
              <Link component={RouterLink} to={`/agents?group=${group.id}&status=2`} underline="hover">
                <Typography variant="body1">{group.unknown} Unknown</Typography>
              </Link>
            </Stack>
            </>
          ) : (
            <Typography variant="body2">No agents added to group.</Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default AgentGroup;
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Page from 'components/layout/Page';

function Complete() {
  return (
    <Page>
      <Helmet>
        <title>Setup Complete &middot; My Account &middot; ReChecked Manager</title>
      </Helmet>
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Stack spacing={4} alignItems="center" justifyContent="center">
            <Typography variant="h2">Setup Complete</Typography>
            <Stack spacing={2} alignItems="center" justifyContent="center">
              <Typography>
                Your subscription has been set up! You can now start adding agents and using ReChecked Manager!
              </Typography>
              <Typography>
                To get you started, here are some quick links we think will be helpful.
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2} maxWidth="sm" sx={{ margin: 'auto' }}>
      <Grid item xs={12}>
          <Card variant="outlined">
            <CardActionArea component={RouterLink} to="/dashboard" sx={{ p: 2 }}>
              <Typography variant="h6">My Dashboard</Typography>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardActionArea target="_blank" rel="noopener noreferrer" href="https://rechecked.io" sx={{ p: 2 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">Getting Started</Typography>
                <OpenInNewIcon />
              </Stack>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardActionArea target="_blank" rel="noopener noreferrer" href="https://rechecked.io/documentation/" sx={{ p: 2 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">Help / Documentation</Typography>
                <OpenInNewIcon />
              </Stack>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
}

export default Complete;
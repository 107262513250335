import { useState } from 'react';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDebouncedCallback } from 'use-debounce';

import useMediaQuery from '@mui/material/useMediaQuery';

import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';

import { useAuth } from 'context/auth';
import { useAgents, useDeleteAgent } from 'hooks/organizations';
import { Page, LoadingCircle } from 'components/layout';
import { EditAgentButton, ConfirmButton } from 'components/buttons';
import { GroupSelect, StatusSelect } from 'components/forms';
import { SortTableHeader, TableStatusIcon } from 'components/table';
import { ucFirst } from 'utils/common';
import PendingAgentsAlert from 'components/alerts/PendingAgentsAlert';
import AgentSyncIcon from 'components/AgentSyncIcon';

const headerCells = [
  {
    id: 'status'
  },
  {
    id: 'hostname',
    label: 'Name (Hostname)',
    sortable: true
  },
  {
    id: 'sync'
  },
  {
    id: 'os',
    label: 'Operating System',
    sortable: true
  },
  {
    id: 'version',
    label: 'Version'
  },
  {
    id: 'edit'
  },
  {
    id: 'delete'
  }
];

function Agents() {

  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [query, setQuery] = useQueryParams({
    status: NumberParam,
    group: NumberParam,
    search: StringParam
  });

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('hostname');
  const [search, setSearch] = useState(query.search ?? '');

  const { selectedOrgId, hasPerm } = useAuth();
  const { isLoading, data: agents } = useAgents(selectedOrgId, { status: query.status, group: query.group });
  const mutateDelete = useDeleteAgent(selectedOrgId, { status: query.status, group: query.group });

  const debounced = useDebouncedCallback(
    (search) => {
      setQuery({ search: search });
    },
    600
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const clearFilters = () => {
    setQuery({
      status: undefined,
      group: undefined,
      search: undefined
    });
    setSearch('');
  };

  const handleDeleteClick = (agentId) => {
    mutateDelete.mutate(agentId);
  };

  const getFilteredAgents = () => {
    if (search && search !== '' && agents) {
      return agents.filter(a => {
        if (a.hostname.toLowerCase().indexOf(search.trim().toLowerCase()) > -1) {
          return a;
        }
        return null;
      });
    }
    return agents;
  };

  const filteredAgents = getFilteredAgents();

  return (
    <Page>
      <Helmet>
        <title>Agents &middot; ReChecked Manager</title>
      </Helmet>
      <Grid container justifyContent="left" spacing={5}>
        <Grid item xs={12}>
          <Stack direction={mobileSize ? "column" : "row"} justifyContent="space-between" alignItems="center" spacing={2}>
            <Stack direction={mobileSize ? "column" : "row"} spacing={2} alignItems="center">
              <Typography variant="h4" sx={{ pr: 3 }}>Agents</Typography>
              <Box sx={{ width: 200 }}>
                <StatusSelect value={query.status} onChange={(value) => setQuery({ status: value })} />
              </Box>
              <Box sx={{ width: 200 }}>
                <GroupSelect value={query.group} onChange={(value) => setQuery({ group: value })} />
              </Box>
              {(query.group || query.status || search) && !mobileSize && (<Button onClick={clearFilters}>Clear</Button>)}
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField size="small" label="Search" value={search} onChange={(e) => { setSearch(e.target.value); debounced(e.target.value); }} />
              {(query.group || query.status || search) && mobileSize && (<Button onClick={clearFilters}>Clear</Button>)}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {hasPerm('Manager') && <PendingAgentsAlert sx={{ mb: 2 }} />}
          {isLoading ? (
            <LoadingCircle />
          ) : (
            <TableContainer variant="outlined" component={Card}>
              <Table aria-label="rcagent list table">
                <SortTableHeader columns={headerCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {agents && filteredAgents.length > 0 ? filteredAgents.map(a => (
                    <TableRow key={a.hostname}>
                      <TableCell padding="checkbox">
                        <TableStatusIcon status={a.status} />
                      </TableCell>
                      <TableCell>
                        <Link component={RouterLink} to={`/agents/${a.id}`} underline="hover" title={a.name !== "" ? a.hostname : undefined}>
                          {a.name ? a.name : a.hostname}
                        </Link>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Stack direction="row" justifyContent="center">
                          <AgentSyncIcon agent={a} />
                        </Stack>
                      </TableCell>
                      <TableCell>{ucFirst(a.os)}</TableCell>
                      <TableCell>{a.version}</TableCell>
                      <TableCell padding="checkbox">
                        {hasPerm('User') && <EditAgentButton agent={a} />}
                      </TableCell>
                      <TableCell padding="checkbox">
                        {hasPerm('User') && (<ConfirmButton
                          ButtonComponent={IconButton}
                          disabled={a.status === 1}
                          variant="outlined"
                          onConfirm={() => handleDeleteClick(a.id)}
                          title="Remove Agent"
                          description="Are you sure you want to remove this agent? Removing the agent will also remove it from all groups and configs it is applied to."
                        ><DeleteIcon /></ConfirmButton>)}
                      </TableCell>
                    </TableRow>
                  )) : (
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell colSpan={3}>
                        {query.group || query.status || query.search ?
                          "No agents match your filters or search criteria." :
                          "You have no agents added to your organization yet."}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default Agents;
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function CustomIconButton(props) {

  const { type, ...extra } = props;

  let icon = '';
  switch (type) {
    default:
    case 'delete':
      icon = <DeleteIcon />;
      break;
    case 'edit':
      icon = <EditIcon />;
      break;
    case 'download':
      icon = <DownloadIcon />;
      break;
    case 'clear':
      icon = <ClearIcon />; 
      break;
    case 'copy':
      icon = <ContentCopyIcon />
      break;
  }

  return (
    <IconButton {...extra} aria-label={type + ' button'}>
      {icon}
    </IconButton>
  );
}

export default CustomIconButton;
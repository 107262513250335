import { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useAuth } from 'context/auth';
import { useInstances } from 'hooks/organizations';

function InstancesSelect({ required, label, value, onChange, error, helperText }) {

  const [currValue, setCurrValue] = useState(value);

  const { selectedOrgId } = useAuth();
  const { data: instances } = useInstances(selectedOrgId);

  useEffect(() => {
    if (Array.isArray(value)) {
      setCurrValue(value);
    }
  }, [value]);

  return (
    <Autocomplete
      multiple
      size="small"
      options={instances ? instances : []}
      getOptionLabel={(option) => option.name}
      filterSelectedOptions
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      onChange={(_, values) => onChange(values)}
      value={currValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label + ' *'}
          placeholder="Search instances"
          onChange={onChange}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}

export default InstancesSelect;
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LoadingCircle } from 'components/layout';
import { AgentGroup } from 'components/layout/groups';
import { useAuth } from 'context/auth';
import { useGroups } from 'hooks/organizations';

function AgentGroupList() {

  const { selectedOrgId } = useAuth();
  const { isLoading, data: groups } = useGroups(selectedOrgId);

  return (
    <Stack spacing={2}>
      {isLoading ? (
        <LoadingCircle />
      ) : groups && groups.length > 0 ? groups.map(group => (
        <AgentGroup key={group.id} group={group} readOnly />
      )) : (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography variant="body2">No <Link component={RouterLink} to={`/groups`}>agent groups</Link> created.</Typography>
        </Box>
      )}
    </Stack>
  );
}

export default AgentGroupList;
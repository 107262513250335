import { Helmet } from 'react-helmet-async';

import Container from '@mui/material/Container';

import ResetPasswordRequestForm from 'components/forms/ResetPasswordRequestForm';

function ResetPassword() {
  return (
    <Container maxWidth="xs" sx={{ p: 4 }}>
      <Helmet>
        <title>Reset Password &middot; ReChecked Manager</title>
      </Helmet>
      <ResetPasswordRequestForm />
    </Container>
  );
}

export default ResetPassword;
import { Fragment } from 'react';
import TimeAgo from 'react-timeago';
import { Link as RouterLink } from 'react-router-dom';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';

import { CustomIconButton } from './buttons';
import { useAuth } from 'context/auth';
import { useRecentAgents, useClearRecentAgents, useClearRecentAgent } from 'hooks/organizations';
import { LoadingCircle } from './layout';

function RecentAgents() {

  const { selectedOrgId } = useAuth();
  const { isLoading, data: agents } = useRecentAgents(selectedOrgId);
  const clearAll = useClearRecentAgents(selectedOrgId);
  const clear = useClearRecentAgent(selectedOrgId);

  const clearRecentAgents = () => {
    clearAll.mutate();
  };

  const clearRecentAgent = (agentId) => {
    clear.mutate(agentId);
  };

  return (
    <Fragment>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 1 }}>
        <Stack direction="row" spacing={2}>
          <Typography variant="h6">Recently Viewed Agents</Typography>
        </Stack>
        <Button size="small" color="secondary"
          onClick={() => clearRecentAgents()}
          disabled={agents?.length === 0}>
          Clear All
        </Button>
      </Stack>
      {isLoading ? <LoadingCircle /> : (
        <TableContainer variant="outlined" component={Card}>
          <Table aria-label="recently viewed agents table">
            <TableBody>
              {agents && agents.length > 0 ? agents.map((a) => (
                <TableRow key={a.agentId}>
                  <TableCell component="th" scope="row">
                    <Link title={a.name !== "" ? a.hostname : undefined} component={RouterLink} to={`/agents/${a.agentId}`} underline="hover">
                      {a.name !== "" ? a.name : a.hostname}
                    </Link>
                  </TableCell>
                  <TableCell>
                    Viewed <TimeAgo date={a.accessed} />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <CustomIconButton type="clear" onClick={() => clearRecentAgent(a.agentId)} />
                  </TableCell>
                </TableRow>
              )) : (
                <TableRow>
                  <TableCell>
                    No recently viewed agents.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
}

export default RecentAgents;

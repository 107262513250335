import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { useAuth } from 'context/auth';
import { usePendingAgentsAlert } from 'hooks/organizations';

function PendingAgentsAlert({ withGrid, ...props }) {

  const navigate = useNavigate();
  const { selectedOrgId } = useAuth();
  const { data } = usePendingAgentsAlert(selectedOrgId);

  if (!data || data.pending !== true) {
    return;
  }

  const alert = (
    <Alert {...props} severity="info" action={<Button onClick={() => navigate("/agents/pending")} color="inherit" size="small">Manage Requests</Button>}>
      There are pending requests from agents requesting to be added to your organization.
    </Alert>
  );

  if (withGrid) {
    return (
      <Grid item xs={12}>{alert}</Grid>
    );
  }
  return alert;
}

export default PendingAgentsAlert;
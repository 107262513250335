import { createContext, useContext, useState } from 'react';

const CheckConfigContext = createContext();

export function useCheckConfig() {
  return useContext(CheckConfigContext);
}

export function CheckConfigProvider(props) {

  const [checks, setChecks] = useState([]);

  return (
    <CheckConfigContext.Provider value={{ checks, setChecks }}>
      {props.children}
    </CheckConfigContext.Provider>
  );
}
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import CustomIconButton from './CustomIconButton';

import { useAuth } from 'context/auth';
import { useEditAgent } from 'hooks/organizations';

function EditAgentButton({ agent }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <CustomIconButton type="edit" onClick={() => setOpen(true)} />
      <EditAgentModal agent={agent} open={open} setOpen={setOpen} />
    </>
  );
}

function EditAgentModal({ agent, open, setOpen }) {

  const [error, setError] = useState("");

  const { selectedOrgId } = useAuth();
  const editMutation = useEditAgent(selectedOrgId);

  const { control, formState: { errors }, handleSubmit, reset } = useForm({
    defaultValues: {
      name: ''
    },
    values: {
      name: agent.name
    }
  });

  const handleClose = () => {
    setOpen(false);
    setTimeout(reset, 200);
  };

  const onSubmit = (data) => {
    editMutation.mutate({
      agentId: agent.id,
      data: data
    }, {
      onSuccess: () => {
        handleClose();
      },
      onError: () => {
        setError("Could not update agent.");
      }
    });
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit Agent</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: 3 }}>
            Make changes to this agent for displaying inside the manager.
          </DialogContentText>
          {error && <Alert severity="error" sx={{ marginBottom: 4 }}>{error}</Alert>}
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <Controller
                name="name"
                control={control}
                render={({ field }) => <TextField
                  size="small" label="Name" fullWidth autoFocus
                  {...field}
                  helperText={errors.name?.message} error={errors.name !== undefined}
                />}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Hostname"
                type="text"
                value={agent.hostname}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="secondary" type="submit">Save Changes</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditAgentButton;
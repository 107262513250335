import { useState } from 'react';

import { useAuth } from 'context/auth';
import { useDeleteSecrets } from 'hooks/organizations';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CustomIconButton } from 'components/buttons';

function DeleteSecretButton({ secret }) {

  const { selectedOrgId } = useAuth();
  const mutateDelete = useDeleteSecrets(selectedOrgId);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleDelete = () => {
    mutateDelete.mutate(secret);
  };

  return (
    <>
      <CustomIconButton onClick={() => setConfirmOpen(true)} type="delete" />
      <ConfirmModal
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title="Confirm Delete Secret"
        text={`Are you sure you want to remove ${secret}? Any agents relying on the variable will no longer set this variable in configs.`}
        okText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => handleDelete()}
      />
    </>
  );
}

export default DeleteSecretButton;
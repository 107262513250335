import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';

import { useConfirmResetPassword } from 'hooks/auth';
import { ucFirst } from 'utils/common';

function ResetPasswordForm({ token }) {

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const confirm = useConfirmResetPassword();
  const { control, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      password: "",
      confPassword: "",
      token: token
    },
  });

  const onSubmit = (data) => {
    confirm.mutate(data, {
      onSuccess: () => {
        setSuccess(true);
      },
      onError: (err) => {
        if (err.response.status === 422) {
          setError(ucFirst(err.response.data.message));
        } else {
          setError("Could not reset password");
        }
      }
    });
  };

  if (success) {
    return (
      <Stack spacing={3}>
        <Typography variant="h4">Reset Password</Typography>
        <Alert severity="success">Password reset successful. You can now log in using the new password.</Alert>
        <Button type="link" component={RouterLink} to="/login">Back</Button>
      </Stack>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Typography variant="h4">Reset Password</Typography>
        <Typography variant="body1">New passwords must be 8+ characters and have at least 1 number and special character.</Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {!token && (
          <>
            <Typography variant="body1"></Typography>
            <Controller
              name="token"
              control={control}
              rules={{ required: "A reset request token is required" }}
              render={({ field }) => <TextField {...field} label="Token" variant="outlined" id="email"
                autoFocus helperText={errors.email?.message} error={errors.email !== undefined} />}
            />
          </>
        )}
        <Controller
          name="password"
          control={control}
          rules={{ required: "Password is required (requirements: must be 8+ characters and have at least 1 number and special character)" }}
          render={({ field }) => <TextField {...field} label="Password" variant="outlined" id="password"
            type="password" helperText={errors.password?.message} error={errors.password !== undefined} />}
        />
        <Controller
          name="confPassword"
          control={control}
          rules={{ required: "Confirm password is required" }}
          render={({ field }) => <TextField {...field} label="Confirm Password" variant="outlined" id="confPassword"
            type="password" helperText={errors.confPassword?.message} error={errors.confPassword !== undefined} />}
        />
        <LoadingButton
          loading={confirm.isLoading}
          variant="contained"
          size="large"
          color="secondary"
          type="submit"
        >
          Reset Password
        </LoadingButton>
        <Button type="link" component={RouterLink} to="/login">Back</Button>
      </Stack>
    </form>
  );
}

export default ResetPasswordForm;